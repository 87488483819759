/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AS': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Sesamzaad" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#E1AF64" cx="16" cy="16" r="15"></circle>
              <g id="Group-2" transform="translate(7.000000, 7.000000)" fill="#DAB06F">
                  <path d="M9.14541282,3.58823529 C7.61600105,3.58823529 7.36390021,2.05882353 8.38911029,0.428571429 C8.64121113,-0.142857143 9.61600105,-0.142857143 9.86810189,0.428571429 C10.9269254,2.04201681 10.6412111,3.58823529 9.14541282,3.58823529 Z" id="Path"></path>
                  <path d="M10.6916313,4.29411765 C10.9437321,3.72268908 11.9353288,3.72268908 12.1874296,4.29411765 C13.2462532,5.94117647 12.9605389,7.50420168 11.4311271,7.50420168 C10.0193624,7.50420168 9.63280777,5.95798319 10.6916313,4.29411765 Z" id="Path"></path>
                  <path d="M10.6916313,12.3445378 C10.9437321,11.7226891 11.9353288,11.7226891 12.1874296,12.3445378 C13.2462532,13.9579832 12.9605389,15.5378151 11.4311271,15.5378151 C10.0193624,15.5378151 9.63280777,13.9747899 10.6916313,12.3445378 Z" id="Path"></path>
                  <path d="M9.14541282,11.5210084 C7.61600105,11.5210084 7.36390021,9.95798319 8.38911029,8.32773109 C8.64121113,7.7394958 9.61600105,7.7394958 9.86810189,8.32773109 C10.9269254,9.95798319 10.6412111,11.5210084 9.14541282,11.5210084 Z" id="Path"></path>
                  <path d="M12.9941523,8.32773109 C13.2462532,7.7394958 14.2378498,7.7394958 14.4899506,8.32773109 C15.5319674,9.95798319 15.2462532,11.5210084 13.7336481,11.5210084 C12.3218834,11.5210084 11.9353288,9.97478992 12.9941523,8.32773109 Z" id="Path"></path>
                  <path d="M15.2966733,12.3445378 C15.5487742,11.7226891 16.5571775,11.7226891 16.8092784,12.3445378 C17.8512952,13.9579832 17.5655809,15.5378151 16.0529758,15.5378151 C14.6412111,15.5378151 14.2546565,13.9747899 15.2966733,12.3445378 Z" id="Path"></path>
                  <path d="M6.08658929,4.29411765 C6.33869013,3.72268908 7.34709349,3.72268908 7.58238761,4.29411765 C8.64121113,5.97478992 8.23784979,7.50420168 6.82608508,7.50420168 C5.31348004,7.50420168 5.04457248,5.94117647 6.08658929,4.29411765 Z" id="Path"></path>
                  <path d="M6.08658929,12.3445378 C6.33869013,11.7226891 7.34709349,11.7226891 7.58238761,12.3445378 C8.64121113,13.9747899 8.23784979,15.5378151 6.82608508,15.5378151 C5.31348004,15.5378151 5.04457248,13.9579832 6.08658929,12.3445378 Z" id="Path"></path>
                  <path d="M3.800875,8.32773109 C4.03616912,7.7394958 5.04457248,7.7394958 5.2798666,8.32773109 C6.33869013,9.97478992 5.93532878,11.5210084 4.52356408,11.5210084 C3.01095903,11.5210084 2.74205147,9.95798319 3.800875,8.32773109 Z" id="Path"></path>
                  <path d="M1.48154727,12.3445378 C1.73364811,11.7226891 2.72524475,11.7226891 2.96053887,12.3445378 C4.01936239,13.9747899 3.63280777,15.5378151 2.20423634,15.5378151 C0.691631303,15.5378151 0.42272374,13.9579832 1.48154727,12.3445378 Z" id="Path"></path>
              </g>
          </g>`
  }
})
