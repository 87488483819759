<template>
  <div v-if="product">
    <AHeading class="" size="h1">{{
      product.productFamily.displayName
    }}</AHeading>

    <!-- has cost + recurring-->
    <div
      v-if="product.recurringPriceExclVat.amount && product.priceExclVat.amount"
    >
      <AHeading class="" size="h3">
        <span v-if="estimatedPrice">{{$t('common.from')}} </span>
        € {{ product.recurringPriceExclVat.amount | toCurrency }} {{$t('common.currencies.recurringCosts').toLowerCase()}}<span
          v-if="estimatedPrice"
          >*</span
        >
      </AHeading>
      <p>
        + €
        {{ product.priceExclVat.amount | toCurrency }} {{$t('common.currencies.oneoffCosts').toLowerCase()}}
      </p>
    </div>

    <!-- has cost and NO recurring-->
    <div
      v-if="
        !product.recurringPriceExclVat.amount && product.priceExclVat.amount
      "
    >
      <AHeading class="" size="h3">
        <span v-if="estimatedPrice">{{$t('common.from')}} </span>
        € {{ product.priceExclVat.amount | toCurrency }}
        <span v-if="estimatedPrice">*</span>
      </AHeading>
    </div>
  </div>
</template>
<script>
import AHeading from '@/components/Atoms/AHeading'

export default {
  components: {
    AHeading
  },
  props: {
    product: {
      default: null,
      type: Object,
      require: false
    },
    estimatedPrice: {
      default: true,
      type: Boolean,
      require: false
    }
  },
  computed: {},
  data () {
    return {}
  },
  watch: {},
  mounted () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
</style>
