<template>
  <div class="container">
    <AHeading size="h3" class="gs1-color-blue mt-4">
      Actions
    </AHeading>
    <div>
      <div>
        <div class="card p-2 mt-4">
          <div class="row d-flex flex-row">
            <div class="col-12 ml-2">
              <h6 class="mt-3 gs1-color-blue mt-4 medium-text font-weight-bold">
                The results of logical validations provide a picture of errors
                in your article data in GDSN
              </h6>
            </div>
          </div>

          <div class="row">
            <div class="col-12 ml-2 medium-text mt-1">
              There is one type of notification:
              <li>
                <small
                  >Signals: check the fields, the system cannot be 100% certain
                  whether the information is correct or not.</small
                >
              </li>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="card p-2 mt-4  ml-1">
          <div class="row d-flex flex-row">
            <div class="col-4">
              <h5 class="card-title mt-3">
                Correct article data
              </h5>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-6">
              <input
                type="checkbox"
                id="checkbox"
                v-model="inScope"
                @change="onCheck($event)"
              />

              <small class="text-muted ml-2 gs1-color-link">
                Show only all items in scope
              </small>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-6">
              <div @click="showFilters = !showFilters">
                <div v-if="!showFilters">
                  <AIcon icon="filter" />

                  <small class="text-muted ml-2 gs1-color-link"
                    >Show filters</small
                  >
                </div>

                <div v-else>
                  <AIcon icon="cross" viewBox="0 0 24 24" size="sm" />
                  <small class="text-muted ml-2">Close</small>
                </div>
              </div>

              <div v-if="showFilters && validations !== null">
                <div>
                  <h5 class="card-title mt-1">
                    Rule ID
                  </h5>
                </div>

                <div class="row mt-3">
                  <div
                    class="col-6"
                    v-for="validation in filteredValidations"
                    :key="`${validation.id}`"
                  >
                    <input
                      type="checkbox"
                      v-model="selectedValidation"
                      :value="validation.id"
                    />

                    <small class="text-muted ml-2">
                      {{ validation.id }} ({{ validation.count }})
                    </small>
                  </div>
                </div>

                <div class="mt-3">
                  <AButton
                    hasPadding
                    :disabled="isDisabled"
                    size="sm"
                    @click="showResults()"
                  >
                    Show results
                  </AButton>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isLoading" class="loader">
            <span class="spinner-border"></span>
          </div>

          <div class="row mt-5 mb-5" v-if="!isLoading && items.length <= 0">
            <div class="col-12">
              <div class="text-center">
                Choose a Rule ID and select Show Results
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="!isLoading && items.length > 0">
            <div class="col-12">
              <b-table
                id="actions-table"
                :items="items"
                :fields="fields"
                :per-page="0"
                :current-page="currentPage"
                small
              ></b-table>

              <b-pagination
                id="actions-pagination"
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="searchObject.paging.pageSize"
                aria-controls="system-control-table"
                size="sm"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AHeading from '@/components/Atoms/AHeading'
import AButton from '@/components/Atoms/AButton'
import AIcon from '@/components/Atoms/AIcon'
import types from '@/common/store/types'

import { Rights } from '../../auth/Rights'
import { Actions } from '../Actions'
import SupplierService from '../../../services/SupplierService'

export default {
  components: {
    AHeading,
    AIcon,
    AButton
  },

  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    },

    welcomeMessage () {
      function capitalize (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }

      const ndate = new Date()
      const hours = ndate.getHours()
      const greet =
        hours < 12
          ? this.$t('home.greeting.morning')
          : hours < 18
            ? this.$t('home.greeting.afternoon')
            : this.$t('home.greeting.evening')

      const name = ''

      return `${greet} ${capitalize(name)}`
    },

    isSupplier () {
      return this.user.isSupplier
    },

    isDisabled () {
      return this.selectedValidation.length <= 0
    }
  },

  data () {
    return {
      Rights: Rights,
      Actions: Actions,
      AllowedActions: [],
      validations: [],
      filteredValidations: [],
      isLoading: false,
      scrollerPadLeft: 0,
      showFilters: true,
      showAccountConfirmed: false,
      currentPage: 1,
      searchObject: {
        sorting: {
          sortBy: '',
          sortDesc: ''
        },
        filters: {
          article_code: '',
          article_description: ''
        },
        paging: {
          pageSize: 10
        }
      },
      totalCount: 0,
      selectedValidation: [],
      inScope: false,
      items: [],
      fields: [
        {
          key: 'gtin',
          label: 'GTIN',
          thStyle: { width: '15%' }
        },
        {
          key: 'error_description',
          label: 'Description',
          thStyle: { width: '30%' }
        },
        {
          key: 'signalering',
          label: 'Signal',
          thStyle: { width: '55%' }
        }
      ]
    }
  },

  mounted () {
    this.fetchFilters()
  },

  destroyed () {
    window.removeEventListener('resize', this.setScrollerPad)
  },

  watch: {
    currentPage: {
      handler: function (value) {
        this.currentPage = value

        this.fetchData()
      }
    }
  },
  methods: {
    setScrollerPad () {
      this.scrollerPadLeft = this.$refs.container.getBoundingClientRect().left
    },

    fetchFilters () {
      this.isLoading = true

      SupplierService.getValidations(this.user.gln)
        .then(response => {
          this.validations = response.data.payload
          this.filteredValidations = [...this.validations]

          this.filterValidation()

          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    },

    fetchData () {
      this.isLoading = true

      SupplierService.getActions(
        this.user.gln,
        this.currentPage,
        this.searchObject.paging.pageSize,
        this.selectedValidation === null
          ? null
          : this.selectedValidation.join(','),
        this.inScope
      )
        .then(response => {
          if (response.error && response.error.response.status === 401) {
            this.$router.push({ name: 'auth.login' })
          } else {
            this.items = response.data.payload.gtins.map(item => {
              return {
                error_description: item.description,
                gtin: item.gtin,
                signalering: item.errorMessage
              }
            })

            // get selected RULE ID's
            var filtered = this.validations.filter(validation =>
              this.selectedValidation.some(
                selected => validation.id === selected
              )
            )

            // SUM the RULE ID's and add to total count
            this.totalCount = filtered.reduce(
              (previous, current) =>
                previous +
                parseInt(this.inScope ? current.inScope : current.total),
              0
            )

            this.isLoading = false
          }
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    },

    onCheck () {
      this.selectedValidation = []
      this.items = []

      this.filterValidation()
    },

    filterValidation () {
      this.filteredValidations = this.validations
        .map(validation => {
          return {
            id: validation.id,
            count: this.inScope ? validation.inScope : validation.total
          }
        })
        .filter(validation => validation.count > 0)
    },

    onPageChange () {
      this.fetchData()
    },

    showResults () {
      this.currentPage = 1

      this.fetchData()
    }
  }
}
</script>

<style>
#actions-table th {
  color: var(--gs1-color-blue);
}

#actions-table td {
  font-size: 12px;
}

#actions-pagination.pagination {
  justify-content: center;
}

#actions-pagination .page-item.active .page-link {
  background-color: black;
  border-color: black;
}

.small-text {
  font-size: 12px;
}

.medium-text {
  font-size: 15px;
}

.fourteen-text {
  font-size: 14px;
}

.loader {
  padding: 125px;
  text-align: center;
}
</style>
