<template>
  <LoaderWrapper :active="isLoading" ref="loader">
    <h1>StyleGuide Atom Design</h1>

    <h2>Alink</h2>
    <ALink class="text-decoration-none"
      ><AIcon icon="arrow-left" /> naar winkelmand (we onthouden je
      gegevens)</ALink
    >

    <h2 @click="isVisible = true">BottomSheet</h2>

    <MOverlay :isVisible="isVisible" @close="isVisible = false">
      <MBottomSheet>
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-sm-12 col-md-6 mb-5">
              <div>
                <h2>Welkom bij Mijn GS1</h2>
                <p>
                  Om te kunnen starten hebben we nog een aantal gegevens van jou
                  nodig. Check je huidige gegevens en vul aan.
                </p>
              </div>
              <div class="form-group mt-4">
                <Button
                  ref="submitButton"
                  class="btn btn-primary is-size-md text-only"
                >
                  Check en vul aan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </MBottomSheet>
    </MOverlay>
  </LoaderWrapper>
</template>

<script>
import ALink from '@/components/Atoms/ALink'
import AIcon from '@/components/Atoms/AIcon'
import MBottomSheet from '@/components/Molecules/MBottomSheet/MBottomSheet'
import MOverlay from '@/components/Molecules/MOverlay/MOverlay'
import LoaderWrapper from '@/common/components/LoaderWrapper'

export default {
  components: {
    ALink,
    AIcon,
    LoaderWrapper,
    MOverlay,
    MBottomSheet
  },

  computed: {},

  data () {
    return { isLoading: false, isVisible: false }
  },

  created () {},

  mounted () {},

  methods: {},

  watch: {}
}
</script>
