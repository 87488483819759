<template>
  <button
    :type="isSubmit ? 'submit' : 'button'"
    :class="[
      'btn',
      'btn-' + type,
      { ['is-size-' + size]: size },
      { 'icon-only': isLoading || isEmpty },
      { 'is-icon-left': !isLoading && iconLeft },
      { 'is-icon-right': !isLoading && iconRight },
      { 'text-only': !iconLeft && !iconRight },
      { 'is-full-width': isFullWidth },
    ]"
    :disabled="isLoading"
    @click.prevent="click"
  >
    <span
      v-if="isLoading && iconLeft"
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
    <Icon v-if="!isLoading && iconLeft" :name="iconLeft" />
    <span v-if="!isEmpty"><slot /></span>
    <Icon v-if="!isLoading && iconRight" :name="iconRight" />
    <span
      v-if="isLoading && !iconLeft"
      class="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
  </button>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'
import types from '@/common/store/types'

export default {
  props: {
    iconLeft: {
      default: null,
      type: String
    },
    iconRight: {
      default: null,
      type: String
    },
    type: {
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'primary',
            'secondary',
            'info',
            'success',
            'warning',
            'danger',
            'link'
          ].indexOf(value) !== -1
        )
      }
    },
    size: {
      default: 'md',
      validator: function (value) {
        // The value must match one of these strings
        return ['sm', 'md', 'lg'].indexOf(value) !== -1
      }
    },
    to: {
      default: null,
      type: Object
    },
    href: {
      default: null,
      type: String
    },
    isFullWidth: {
      default: false,
      type: Boolean
    },
    isSubmit: {
      default: true,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    }
  },

  components: {
    Icon
  },

  computed: {
    isLoading () {
      return this.loading || this?.loader?.isActive || false
    },
    loader () {
      return this.$store.getters[types.getters.loaders](this._uid)
    },
    isEmpty () {
      return !this.hasProperty(this.$slots, 'default')
    }
  },

  methods: {
    click () {
      if (this.to) {
        this.$router.push(this.to)
      }

      if (this.href) {
        window.location.href = this.href
        // window.open(this.href)
      }

      this.$emit('click', true)
    }
  }
}
</script>
