<template>
    <MContentGroup isBordered isFullwidth hasShadow>
      <template #body-content>
        <div>
          <AHeading class="mb-4" size="h5">{{
            $t("common.contentGroupFeedback.title")
          }}</AHeading>
          <div class="pb-4">
            <p>{{ $t("common.contentGroupFeedback.subtitle") }}</p>
          </div>
          <AButton
            hasPadding
            size="md"
            type="outline"
            :isFullWidth="true"
            href=""
            >{{ $t("common.contentGroupFeedback.button") }}</AButton
          >
        </div>
      </template>
    </MContentGroup>
  </template>
<script>
import AHeading from '@/components/Atoms/AHeading'
import AButton from '@/components/Atoms/AButton'
import MContentGroup from '@/components/Molecules/MContentGroup'

export default {
  components: {
    AButton,
    AHeading,
    MContentGroup
  },
  data () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>
