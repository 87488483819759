export const initialState = () => {
  return {
    cartItems: []
  }
}

const state = () => initialState()

const getters = {
  getCartItems: state => state.cartItems
}

const actions = {
  setCartItems ({ commit }, payload) {
    commit('SET_CART_ITEMS', payload)
  }
}

const mutations = {
  SET_CART_ITEMS (state, payload) {
    state.cartItems = payload
  }
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
