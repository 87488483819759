export default {
  name: 'MDropdownItem',
  props: {
    linkMenuItemToChild: {
      default: false,
      type: Boolean,
      require: false
    }
  },
  methods: {
    click () {
      this.$parent.toggleDropdown()
      this.$emit('click', true)
      if (this.linkMenuItemToChild) {
        this.$children[0].$el.click()
        this.$parent.hideMenu()
      }
    }
  }
}
