import * as msal from '@azure/msal-browser'
// import policies from './policies'
import config from './config'
import ContactService from '@/services/ContactService'
import { STORAGE_KEY_LOCALE } from '@/i18n'

// how often to refresh login token, in milliseconds
const tokenRefreshInterval = 5 * 60 * 1000

const initialState = {
  isLoggedIn: false,
  accountId: null,
  accessToken: null
}

class VueB2c {
  msalInstance = new msal.PublicClientApplication(config);
  state = null;
  refreshIntervalId = null;

  constructor (Vue) {
    this.state = new Vue({
      data () {
        const b2cState = localStorage.getItem('b2c-state')
        return b2cState
          ? JSON.parse(b2cState)
          : Object.assign({}, initialState) // clone, rather than refer to
      },
      watch: {
        $data: {
          handler (state) {
            if (state.isLoggedIn) {
              localStorage.setItem('b2c-state', JSON.stringify(state))
            } else {
              localStorage.removeItem('b2c-state')
            }
            this.$b2c.setRefreshInterval()
          },
          deep: true
        }
      }
    })

    this.msalInstance
      .handleRedirectPromise()
      .then(response => {
        if (response !== null) {
          this.state.accessToken = response.accessToken
          var permissions = String(
            response.idTokenClaims.myGS1RelationPermissions
          )
          console.log('permissions ', permissions)
          // if (response.idTokenClaims.myGS1GlobalReader) {
          //   localStorage.setItem(
          //     'globalReader',
          //     true
          //   )
          //   localStorage.setItem('accountsToken', 'Admin')
          // } else {
          //   localStorage.setItem('accountsToken', permissions.split('@')[0])
          // }
          // localStorage.setItem(
          //   'idTokenClaims',
          //   response.idTokenClaims.myGS1RelationPermissions
          // )
        }
      })
      .catch(error => {
        this.handleErrorResponse(error)
      })
    // this.selectAccount()

    if (this.isLoggedIn()) {
      this.getToken()
    }

    this.setRefreshInterval()
  }

  setRefreshInterval () {
    if (this.state.isLoggedIn) {
      if (!this.refreshIntervalId) {
        this.refreshIntervalId = setInterval(
          () => this.getToken(),
          tokenRefreshInterval
        )
      }
    } else {
      this.refreshIntervalId = clearInterval(this.refreshIntervalId)
    }
  }

  login (gs1user = '') {
    // gs1user = email!
    // Only give email for Signup process?!
    var newUser = localStorage.getItem('newUser')
    if (newUser && newUser.email) {
      gs1user = newUser.email
    }

    // Create msal request
    var request = {
      scopes: ['openid', ...config.scopes],
      extraQueryParameters: {
        gs1user: gs1user,
        ui_locales: localStorage.getItem(STORAGE_KEY_LOCALE)
      }
    }

    this.msalInstance.loginRedirect(request)
  }

  logout () {
    this.msalInstance.logout()
    this.resetState()
  }

  resetState () {
    // remove all b2c related localstorage items
    this.removeLocalStorageByKey(process.env.VUE_APP_B2C_B2C_DOMAIN)
    this.state = Object.assign({}, initialState)
  }

  removeLocalStorageByKey (query) {
    let i
    for (i in localStorage) {
      if (Object.prototype.hasOwnProperty.call(localStorage, i)) {
        if (i.match(query) || (!query && typeof i === 'string')) {
          localStorage.removeItem(i)
        }
      }
    }
  }

  isLoggedIn () {
    // Move to createLogin method oid?
    const b2cState = localStorage.getItem('b2c-state')
    if (b2cState) {
      var state = JSON.parse(b2cState)
      return state.isLoggedIn
    }
    // Move to createLogin method oid?
    return this.state.isLoggedIn
  }

  getAccount () {
    // Also get Contact
    ContactService.getAndStoreContact()
    return this.msalInstance.getAccountByHomeId(this.state.accountId)
  }

  //   async getToken () {
  //     if (this.isLoggedIn()) {
  //       const request = {
  //         account: this.getAccount(),
  //         forceRefresh: false,
  //         scopes: ['openid', ...config.scopes],
  //         loginHint: ''
  //       }

  //       this.msalInstance
  //         .acquireTokenSilent(request)
  //         .then(response => {
  //           // Note: resp.accessToken possible wrong, but nog sure!
  //           if (response.accessToken) {
  //             this.state.accessToken = response.accessToken
  //           }
  //           if (response.access_token) {
  //             this.state.accessToken = response.access_token
  //           }
  //           localStorage.setItem('b2c-state', JSON.stringify(this.state))
  //         })
  //         .catch(error => {
  //           if (error instanceof msal.InteractionRequiredAuthError) {
  //             console.info('Session expired: redirect to login page', error)
  //             this.logout()
  //             this.login()
  //           }
  //         })
  //     }
  //   }

  //   // ToDo: remove!!!!
  //   async getRefreshToken () {
  //     this.logout()
  //     this.login()
  //   }

  //   passwordReset () {
  //     this.msalInstance.loginRedirect(policies.authorities.passwordReset)
  //   }

//   handleErrorResponse (error) {
//     // TODO needs documentation
//     if (error.errorMessage.indexOf('AADB2C90118') > -1) {
//       try {
//         this.passwordReset()
//       } catch (err) {}
//     }
//   }
}

export default {
  install (Vue, options) {
    Vue.prototype.$b2c = new VueB2c(Vue)
  }
}
