<template>
  <td>
    <div class="data-table-deadline-expired">
      <span
        class="d-inline-flex"
        v-b-tooltip.hover.bottom
        :title="$t('common.table.filter.deadlineExpired3')"
      >
        <Icon name="exclamation-triangle" />
      </span>
    </div>
  </td>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  components: {
    Icon
  }
}
</script>
