import axios from '@/common/utils/axios'

/**
 * @class SupplierService
 */
const SupplierService = {
  getMetrics: async supplier => {
    const url = '/Supplier/metric'

    try {
      const { data } = await axios.get(url, { params: { supplier: supplier } })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getProgress: async supplier => {
    const url = '/Supplier/progress'

    try {
      const { data } = await axios.get(url, { params: { supplier: supplier } })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getBenchmark: async supplier => {
    const url = '/Supplier/benchmark'

    try {
      const { data } = await axios.get(url, { params: { supplier: supplier } })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getHospitalData: async supplier => {
    const url = '/Supplier/hospital'

    try {
      const { data } = await axios.get(url, { params: { supplier: supplier } })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getCompleteness: async supplier => {
    const url = '/Supplier/completenessScore'

    try {
      const { data } = await axios.get(url, { params: { supplier: supplier } })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getActions: async (supplier, page, size, validation, inScope) => {
    const url = '/Supplier/actions'

    try {
      const { data } = await axios.get(url, {
        params: {
          supplier: supplier,
          page: page,
          size: size,
          validation: validation,
          inScope: inScope
        }
      })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getValidations: async supplier => {
    const url = '/Supplier/validations'

    try {
      const { data } = await axios.get(url, { params: { supplier: supplier } })

      return { data }
    } catch (error) {
      return { error }
    }
  },

  getSystemInformation: async (supplier, inScope) => {
    const url = '/Supplier/systeminformation'

    try {
      const { data } = await axios.get(url, {
        params: { supplier: supplier, inScope: inScope }
      })

      return { data }
    } catch (error) {
      return { error }
    }
  }
}

export default SupplierService
