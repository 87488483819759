<template>
  <div :class="['error-message', 'is-'+type]">
    <Icon v-if="hasIcon" :name="icon[type]" size="5" />
    <span v-if="!isEmpty"><slot /></span>
    <div v-html="html"></div>
  </div>
</template>

<script>
import Icon from '../Icon/Icon'
import SlotsMixin from '../../utils/mixins/slots.js'

export default {
  props: {
    hasIcon: {
      default: false,
      type: Boolean
    },
    type: {
      default: 'danger',
      validator: function (value) {
        return ['warning', 'danger'].indexOf(value) !== -1
      }
    },
    html: {
      default: null
    }
  },

  mixins: [SlotsMixin],

  components: {
    Icon
  },

  data () {
    return {
      icon: {
        danger: 'exclamation-circle',
        warning: 'bell'
      }
    }
  }
}
</script>
