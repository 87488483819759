/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AN': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Noten" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#A48066" cx="16" cy="16" r="15"></circle>
              <path d="M17.0435547,8.96642969 L17.0435547,8.76087109 C17.0435898,8.43722266 17.306875,8.17 17.6305586,8.17 L18.8044961,8.17 C19.1285313,8.17 19.3914648,7.91117969 19.3914648,7.58693359 C19.3914648,7.26272266 19.1285313,7 18.8044961,7 L17.6305586,7 C16.6595781,7 15.8696523,7.78989062 15.8696523,8.76090625 L15.8696523,8.96646484 C12.6161875,9.07101953 10,11.7434219 10,15.0218477 C10,15.3460937 10.2629336,15.6049141 10.5869688,15.6049141 L11.1739375,15.6049141 L11.1739375,18.5436602 C11.1739375,20.9079531 12.7685898,22.9984492 15.0514961,23.6274297 C15.1902227,23.6656445 15.3105977,23.7521641 15.3900508,23.8715898 L15.9681953,24.7388594 C16.200332,25.0870469 16.7128047,25.0870469 16.9449414,24.7388594 L17.5230859,23.8715898 C17.6025742,23.7521641 17.7229141,23.6653984 17.8616406,23.6274297 C20.1445469,22.9984492 21.7391992,20.9079531 21.7391992,18.5436602 L21.7391992,15.6049141 L22.326168,15.6049141 C22.6502031,15.6049141 22.9131367,15.3460937 22.9131367,15.0218477 C22.9131367,11.7434219 20.2970195,9.07101953 17.0435547,8.96642969 Z M16.3973828,20.9537969 C16.3125859,21.2612031 15.9935781,21.451082 15.6755195,21.364 C14.4076094,21.0148984 13.5217773,19.8550938 13.5217773,18.543625 L13.5217773,17.3696875 C13.5217773,17.0454414 13.7847109,16.7827188 14.1087461,16.7827188 C14.4327813,16.7827188 14.6957148,17.0454414 14.6957148,17.3696875 L14.6957148,18.543625 C14.6957148,19.3285586 15.2268906,20.0228945 15.9873555,20.2320742 C16.2999648,20.3181016 16.4834102,20.6411875 16.3973828,20.9537969 Z" id="Shape" fill="#835230" fill-rule="nonzero"></path>
          </g>`
  }
})
