/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AU': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Zwaveldioxide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M16,9 C19.1688266,9 21.7639007,11.4565267 21.9847666,14.5691246 C23.7272401,15.0805518 25,16.6916414 25,18.6 C25,20.9195959 23.1195959,22.8 20.8,22.8 C19.3150545,22.8 18.0101,22.0293672 17.2632501,20.8662152 C16.8562301,20.9540305 16.4334834,21 16,21 C15.2592399,21 14.5498331,20.8657607 13.8947791,20.6202817 C13.1665239,21.2319312 12.2262455,21.6 11.2,21.6 C8.88040405,21.6 7,19.7195959 7,17.4 C7,15.4102027 8.38370704,13.7435876 10.241381,13.3098947 C10.9707223,10.8194093 13.2728882,9 16,9 Z M21.9430287,15.8254354 L21.9349446,15.886636 C21.6309858,17.9387828 20.2879797,19.6522526 18.4578791,20.4750926 C19.0072765,21.1605844 19.8523227,21.6 20.8,21.6 C22.4568542,21.6 23.8,20.2568542 23.8,18.6 C23.8,17.3478764 23.0329054,16.2749173 21.9430287,15.8254354 Z M11.2,14.4 C9.54314575,14.4 8.2,15.7431458 8.2,17.4 C8.2,19.0568542 9.54314575,20.4 11.2,20.4 C12.8568542,20.4 14.2,19.0568542 14.2,17.4 C14.2,15.7431458 12.8568542,14.4 11.2,14.4 Z" id="Combined-Shape" fill="#8464A5" fill-rule="nonzero"></path>
              <circle id="Oval-Copy-3" stroke="#8A61A9" cx="16" cy="16" r="15"></circle>
          </g>`
  }
})
