import axios from '@/common/utils/axios'

/**
 * @class ActivationService
 */
const ActionService = {

  getAvailableActions: async (accountNumber) => {
    const url = `/accounts/Accounts/${accountNumber}/GetActionsForAccount`
    try {
      const { data } = await axios.get(url)
      return { data }
    } catch (error) {
      return { error }
    }
  }
}

export default ActionService
