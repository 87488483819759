class Model {
  id

  // Create the model-specific properties, and optionally
  // fill them with the provided data.
  constructor (data, fields) {
    const modelFields = ['id', ...fields]
    for (const i in modelFields) {
      this[modelFields[i]] = Object.prototype.hasOwnProperty.call(
        data,
        modelFields[i]
      )
        ? data[modelFields[i]]
        : undefined
    }
  }

  getObject () {
    let data = {}
    for (const i in this) {
      if (this[i]) {
        data = Object.assign({}, data, { [i]: this[i] })
      }
    }
    return data
  }
}

export default Model
