import { render, staticRenderFns } from "./MDropdownItem.pug?vue&type=template&id=76b68c83&scoped=true&lang=pug&"
import script from "./MDropdownItem.js?vue&type=script&lang=js&"
export * from "./MDropdownItem.js?vue&type=script&lang=js&"
import style0 from "./MDropdownItem.sass?vue&type=style&index=0&id=76b68c83&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b68c83",
  null
  
)

export default component.exports