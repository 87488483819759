<template>
  <b-collapse id="account-menu-mobile-mobile" :visible="isAccountNavActive">
    <div class="account-menu-mobile">
      <div class="container">
        <ul class="account-menu-mobile__list list-unstyled">
          <li>
            <!--@click="logout()-->
            <div class="account-menu-mobile__item">
              <AIcon icon="logout" /><span class="ml-3">{{
                $t("common.header.button.logout")
              }}</span>
            </div>
          </li>
          <!-- <li v-if="!blockedAccount && accountsCount > 1">
            <div class="account-menu-mobile__item" @click="changeCompany">
              <AIcon icon="home" /><span class="ml-3">{{
                $t("common.header.button.switchCompany")
              }}</span>
            </div>
          </li>
          <li v-if="activeGLN != null">
            <div
              class="account-menu-mobile__item"
              @click="$router.push({ name: 'dataquality.gln-select' })"
            >
              <AIcon icon="filter" />
              <span class="ml-3">{{
                $t("common.header.button.switchGln")
              }}</span>
            </div>
          </li>
          <li>
            <div class="account-menu-mobile__item" @click="logout">
              <AIcon icon="logout" /><span class="ml-3">{{
                $t("common.header.button.logout")
              }}</span>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import AIcon from '@/components/Atoms/AIcon'

export default {
  components: { AIcon },
  watch: {},
  data () {
    return {
      isAccountNavActive: true
    }
  },
  mounted () {},
  methods: {},
  computed: {}
}
</script>
