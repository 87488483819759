/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav-icon-references': {
    width: 40.316,
    height: 47.217,
    viewBox: '0 0 10.667 12.493',
    data: '<path pid="0" d="M4.537 0c-.734 0-1.326.68-1.326 1.524V2.55c0 .844.592 1.525 1.326 1.525h1.634c.735 0 1.326-.68 1.326-1.525V1.524C7.497.68 6.906 0 6.17 0zM1.92 5.233a.335.335 0 00-.336.336v1.39c0 .186.15.336.336.336h.262c.186 0 .336-.15.336-.336v-.792h5.52v.792c0 .186.15.336.336.336h.261c.186 0 .337-.15.337-.336v-1.39a.335.335 0 00-.337-.336zM1.326 8.42C.59 8.42 0 9.1 0 9.944v1.024c0 .845.591 1.525 1.326 1.525H2.96c.734 0 1.326-.68 1.326-1.525V9.944c0-.845-.592-1.524-1.326-1.524zm6.381 0c-.734 0-1.325.68-1.325 1.524v1.024c0 .845.59 1.525 1.325 1.525h1.634c.734 0 1.326-.68 1.326-1.525V9.944c0-.845-.592-1.524-1.326-1.524z" _fill="currentColor"/>'
  }
})
