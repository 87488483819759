/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paperclip': {
    width: 8,
    height: 16,
    viewBox: '0 0 8 16',
    data: '<defs><filter id="svgicon_paperclip_a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.803922 0 0 0 0 0.235294 0 0 0 0 0.050980 0 0 0 1.000000 0"/></filter></defs><g _fill="none" fill-rule="evenodd" filter="url(#svgicon_paperclip_a)" transform="translate(-16 -11)"><path pid="0" _fill="#000" fill-rule="nonzero" d="M16.5 14a2.5 2.5 0 015 0v9a1.5 1.5 0 01-3 0v-7a.5.5 0 011 0v7a.5.5 0 101 0v-9a1.5 1.5 0 10-3 0v9a2.5 2.5 0 105 0v-7a.5.5 0 011 0v7a3.5 3.5 0 01-7 0v-9z"/></g>'
  }
})
