/** Deprecated, use /store/modules instead! */

export const initialState = {
  hiddenAlerts: localStorage.getItem('hiddenAlerts')
    ? JSON.parse(localStorage.getItem('hiddenAlerts'))
    : [],
  loaders: [],
  modules: [],
  notifications: [],
  has403error: false,
  locale: localStorage.getItem('i18n.locale') || 'nl',
  accountScope: JSON.parse(localStorage.getItem('accountScope')) || null,
  supplierScope: localStorage.getItem('supplierScope') || null,
  contact: [],
  translations: {},
  supplier: localStorage.getItem('supplier')
    ? JSON.parse(localStorage.getItem('supplier'))
    : {
      GLNs: [],
      accountName: null,
      signature: null
    },
  selectedSupplier: localStorage.getItem('selectedSupplier'),
  selectedSupplierName: localStorage.getItem('selectedSupplierName'),
  loggedInUser: localStorage.getItem('loggedInUser')
}

export default Object.assign({}, initialState)
