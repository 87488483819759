<template>
  <div :class="className">
    <Icon
      v-if="type == 'info'"
      name="info-circle"
    />
    <span v-if="title" class="alert__title">{{ title }}</span>
    <div class="alert__content"><slot></slot></div>
  </div>
</template>

<script>
import Icon from '@/common/components/Icon/Icon.vue'

const alertTypes = [
  'info',
  'success',
  'warning',
  'danger'
]

export default {
  name: 'Alert',

  components: {
    Icon
  },

  props: {
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: alertTypes[0],
      validator: (v) => alertTypes.includes(v)
    }
  },

  computed: {
    className () {
      return {
        alert: true,
        ...alertTypes.reduce((acc, type) => ({
          ...acc,
          [`alert-${type}`]: type === this.type
        }), {})
      }
    }
  }

}
</script>

<style lang="sass">
.alert
  display: flex
  flex-direction: column
</style>
