import axios from '@/common/utils/axios'

/**
 * @class AccountService
 */
const AccountService = {

  getAllAccounts: async (searchTerm) => {
    const url = '/accounts/Accounts'
    try {
      const { data } = await axios.get(url, { params: { searchTerm: searchTerm } })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getMyCompany: async (accountNumber) => {
    const url = `/accounts/Accounts/${accountNumber}`
    try {
      const { data } = await axios.get(url)
      return { data }
    } catch (error) {
      return { error }
    }
  }
}

export default AccountService
