import namespace from '@/common/utils/namespace'

export default namespace('auth', {
  getters: [
    'translations'
  ],
  actions: [
    'test',
    'translations'
  ],
  mutations: [
    'storeTranslations'
  ]
})
