import axios from '@/common/utils/axios'

/**
 * @class HospitalService
 */
const HospitalService = {
  getMetrics: async (recipient, supplier) => {
    const url = '/Hospital/metric'

    try {
      const { data } = await axios.get(url, {
        params: { recipient: recipient, supplier: supplier }
      })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getImports: async (recipient, supplier) => {
    const url = '/Hospital/import'

    try {
      const { data } = await axios.get(url, {
        params: { recipient: recipient, supplier: supplier }
      })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getProgress: async (recipient, supplier) => {
    const url = '/Hospital/progress'

    try {
      const { data } = await axios.get(url, {
        params: { recipient: recipient, supplier: supplier }
      })
      return { data }
    } catch (error) {
      return { error }
    }
  },
  getBenchmark: async (recipient, supplier) => {
    const url = '/Hospital/benchmark'

    try {
      const { data } = await axios.get(url, { params: { recipient: recipient, supplier: supplier } })
      return { data }
    } catch (error) {
      return { error }
    }
  },
  getCompleteness: async (recipient, supplier) => {
    const url = '/Hospital/completenessScore'

    try {
      const { data } = await axios.get(url, {
        params: { recipient: recipient, supplier: supplier }
      })
      return { data }
    } catch (error) {
      return { error }
    }
  },

  getSystemInformation: async (recipient, supplier, inScope) => {
    const url = '/Hospital/systeminformation'

    try {
      const { data } = await axios.get(url, {
        params: { recipient: recipient, supplier: supplier, inScope: inScope }
      })

      return { data }
    } catch (error) {
      return { error }
    }
  },

  getActions: async (recipient, supplier, page, size, validation, inScope) => {
    const url = '/Hospital/actions'

    try {
      const { data } = await axios.get(url, {
        params: {
          recipient: recipient,
          supplier: supplier,
          page: page,
          size: size,
          validation: validation,
          inScope: inScope
        }
      })

      return { data }
    } catch (error) {
      return { error }
    }
  },

  getValidations: async (supplier, recipient) => {
    const url = '/Hospital/validations'

    try {
      const { data } = await axios.get(url, {
        params: {
          supplier: supplier,
          recipient: recipient
        }
      })

      return { data }
    } catch (error) {
      return { error }
    }
  }
}

export default HospitalService
