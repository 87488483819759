export var Rights = {
  BuyCodes: 'postcodes',
  GetCodes: 'getcodes',
  UpdateCodes: 'updatecodes',
  UpdateAccount: 'updateaccount',
  GetAccount: 'getaccount',
  GetContacts: 'getcontacts',
  UpdateContacts: 'updatecontacts',
  PersonalInfo: 'personalinfo',
  ProductInfo: 'productinfo',
  PayInvoice: 'payinvoice',
  DataQuality: 'dataQuality'
}
