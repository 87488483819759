<template>
  <div
    class="subnavigation"
    :class="{ 'is-open': isSubNavActive }"
    v-if="getAllSubnavItems.length > 0"
  >
    <!-- mobile -->
    <SubNavigationMobile
      @setLocale="setLocale"
      class="d-block d-lg-none d-xl-none"
    />

    <!-- desktop -->
    <SubNavigationDesktop
      @setLocale="setLocale"
      class="d-none d-lg-block d-xl-block"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import i18n, { STORAGE_KEY_LOCALE } from '@/i18n'

import SubNavigationDesktop from '@/common/components/SubNavigation/SubNavigationDesktop'
import SubNavigationMobile from '@/common/components/SubNavigation/SubNavigationMobile'

export default {
  components: {
    SubNavigationDesktop,
    SubNavigationMobile
  },

  data: () => {
    return {
      showSubNav: true,
      showDataQuality: false,
      supplierOptions: []
    }
  },
  methods: {
    ...mapActions('subnavigation', ['setSubNavActive', 'setSubnavItems']),

    setLocale (locale) {
      localStorage.setItem(STORAGE_KEY_LOCALE, locale)
      i18n.locale = locale
    },

    handleRouteChange (route) {
      this.setSubNavActive(false)
    }
  },
  watch: {},
  mounted () {},
  created () {
    // set subnav
    const subnavItems = [
      {
        label: 'Home',
        route: {
          name: 'home.index'
        }

      }
    ]

    this.setSubnavItems(subnavItems).then(() => {})
  },
  computed: {
    ...mapGetters('subnavigation', ['isSubNavActive', 'getAllSubnavItems'])
  }
}
</script>

<style src="./SubNavigation.sass" lang="sass" scoped />
