<template>
    <div class="widget">
      <div class="metric">
        <h3 class="mb-2">{{metric}}</h3>
        <p v-if="label">{{label}}</p>
      </div>
    </div>
</template>

<script>

export default {
  name: 'Metric',
  props: {
    label: {
      type: String
    },
    metric: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
