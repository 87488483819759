<template>
  <div class="card p-2 h-100">
    <div class="row">
      <div class="col-12 align-left">
        <h5 class="card-title m-0">Vulgraad kwaliteitsrapportage</h5>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6">
        <input
          type="checkbox"
          id="checkbox"
          v-model="inScope"
          @change="onChange"
        />

        <small class="text-muted ml-2">
          Toon alleen items in scope
        </small>
      </div>

      <div class="col-6">
        <input
          type="checkbox"
          id="checkbox"
          @change="onClick"
          checked
        />

        <small class="text-muted ml-2">
          Toon validaties in scope
        </small>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading">
      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :width="width"
        :height="height"
      />

      <b-pagination
        class="mt-3"
        id="bar-pagination"
        @input="onPageChange()"
        v-model="searchObject.paging.currentPage"
        :total-rows="totalCount"
        :per-page="searchObject.paging.pageSize"
        size="sm"
        align="center"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import types from '@/common/store/types'
import { Bar } from 'vue-chartjs/legacy'

import HospitalService from '../../../services/HospitalService'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      isLoading: false,
      inScope: false,
      numberOfCodes: 12,
      totalCount: 0,
      filter: false,
      validations: [],
      sortedvalidations: [],

      chartData: {},

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: false,
          datalabels: {
            font: {
              size: 12,
              weight: 'bold'
            },
            color: '#fff'
          }
        }
      },

      searchObject: {
        sorting: {
          sortBy: '',
          sortDesc: ''
        },
        filters: {
          article_code: '',
          article_description: ''
        },
        paging: {
          currentPage: 1,
          pageSize: 6
        }
      }
    }
  },

  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    },

    selectedSupplier () {
      return this.$store.getters[types.getters.selectedSupplier]
    }
  },

  watch: {
    selectedSupplier: function () {
      this.fetchData()
    }
  },

  mounted () {
    this.fetchData()
  },

  methods: {
    fetchData () {
      this.isLoading = true

      HospitalService.getCompleteness(
        this.user.gln,
        this.selectedSupplier === 'Alle Leveranciers'
          ? null
          : this.selectedSupplier
      )
        .then(response => {
          this.loading = false

          if (response.error && response.error.response.status === 401) {
            this.$router.push({ name: 'auth.login' })
          }

          var supplier = response.data.payload.supplier.find(
            item => item.gln === localStorage.getItem('selectedSupplier')
          )

          this.validations = supplier.validation
          this.sortedvalidations = [...this.validations]

          this.sortedvalidations.sort(function (first, second) {
            return first.validationId - second.validationId
          })

          this.totalCount = supplier.validation.length
          this.filterData()
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    },

    filterData () {
      var skip =
        this.searchObject.paging.pageSize *
        (this.searchObject.paging.currentPage - 1)
      var take = this.searchObject.paging.pageSize

      var sorted = this.filter ? this.sortedvalidations : this.sortedvalidations.filter(x => (x.dqScorePenalty > 0))
      var filtered = sorted.slice(skip, skip + take)

      this.totalCount = sorted.length

      var labels = filtered.map(
        validation =>
          validation.attributeName + ' (' + validation.validationId + ')'
      )

      var scores = filtered.map(validation =>
        this.inScope
          ? validation.completenessOnInScope
          : validation.completenessOnTotal
      )

      // set chart data
      this.chartData = {
        clip: true,
        labels: labels,
        datasets: [
          {
            barThickness: 18,
            label: 'Score',
            backgroundColor: '#F26334',
            data: scores
          }
        ]
      }
    },

    onPageChange () {
      this.filterData()
    },

    onChange () {
      this.filterData()
    },

    onClick () {
      this.filter = !this.filter
      this.searchObject.paging.currentPage = 1

      this.filterData()
    }
  }
}
</script>

<style>
#bar-pagination .page-item.active .page-link {
  background-color: black;
  border-color: black;
}
</style>
