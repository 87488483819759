<template>
  <div class="d-flex flex-column justify-content-between h-100">
    <MAlert class="mt-3" type="info" v-if="showAccountConfirmed">
      <AHeading size="h6" class="gs1-color-orange">\gs1nl
        {{ $t("home.welcome.accept") }}
      </AHeading>
    </MAlert>

    <div class="text-center" v-if="isLoading">
      <div class="spinner-border gs1-color-orange" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-6" v-if="!isSupplier">
        <DashboardPieCard />
      </div>

      <div class="col-6" v-else>
        <DashboardPieCardSupplier />
      </div>

      <div class="col-6" v-if="!isSupplier">
        <LinechartCard />
      </div>

      <div class="col-6" v-else>
        <LinechartCardSupplier />
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-6" v-if="!isSupplier">
        <BarchartCard />
      </div>

      <div class="col-6" v-else>
        <BarchartCardSupplier />
      </div>

      <div class="col-6" v-if="!isSupplier">
        <SystemControlCard />
      </div>

      <div class="col-6" v-else>
        <SystemControlCardSupplier />
      </div>

    </div>
  </div>
</template>

<script>
import AHeading from '@/components/Atoms/AHeading'
import MAlert from '@/components/Molecules/MAlert'
import { Rights } from '../../auth/Rights'
import { Actions } from '../../home/Actions'
import DashboardPieCard from './DashboardPieCard.vue'
import DashboardPieCardSupplier from './DashboardPieCardSupplier.vue'
import BarchartCard from './BarchartCard.vue'
import BarchartCardSupplier from './BarchartCardSupplier.vue'
import LinechartCard from './linechartCard.vue'
import LinechartCardSupplier from './LinechartCardSupplier.vue'
import SystemControlCard from './SystemControlCard.vue'
import SystemControlCardSupplier from './SystemControlCardSupplier.vue'

export default {
  components: {
    AHeading,
    MAlert,
    DashboardPieCard,
    DashboardPieCardSupplier,
    BarchartCard,
    BarchartCardSupplier,
    SystemControlCard,
    LinechartCard,
    LinechartCardSupplier,
    SystemControlCardSupplier
  },

  computed: {
    welcomeMessage () {
      function capitalize (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }

      const ndate = new Date()
      const hours = ndate.getHours()
      const greet =
        hours < 12
          ? this.$t('home.greeting.morning')
          : hours < 18
            ? this.$t('home.greeting.afternoon')
            : this.$t('home.greeting.evening')

      const name = ''

      return `${greet} ${capitalize(name)}`
    },
    isSupplier () {
      var user = JSON.parse(localStorage.getItem('loggedInUser'))
      return user.data.payload.isSupplier
    }
  },

  data () {
    return {
      Rights: Rights,
      Actions: Actions,
      AllowedActions: [],
      isLoading: false,
      scrollerPadLeft: 0,

      showAccountConfirmed: false
    }
  },

  mounted () {
    if (JSON.parse(localStorage.getItem('loggedInUser')) === null) {
      this.$router.push({ name: 'auth.login' })
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.setScrollerPad)
  },

  methods: {
    setScrollerPad () {
      this.scrollerPadLeft = this.$refs.container.getBoundingClientRect().left
    }
  }
}
</script>

<style scoped>
.scroller {
  display: flex;
  flex-grow: 1;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.scroller-inner {
  display: flex;
  overflow-x: auto;
}
</style>
