<template>
  <div class="collapsible-group">
    <div class="collapsible-group-header">
      <div class="collapsible-group-header-title" @click.prevent="isCollapible ? toggleVisibility() : false">
        {{ title }}
      </div>

      <div class="collapsible-group-header-badges">
        <!-- Add badges here -->
          <div
            class="collapsible-group-header-badge"
            v-for="(callback,index) in badges"
            :key="index"
          >
            <div v-if="callback(count, dataSet, requestLimit, hasHitRequestLimit)">
              {{ callback(count, dataSet, requestLimit, hasHitRequestLimit) }}
            </div>
          </div>
      </div>
      <div class="collapsible-group-header-count">
        {{ groupCount }}
      </div>
      <div class="collapsible-group-header-actions">
        <slot name="header"></slot>
        <!-- Table related actions -->
        <div class="collapse-icon" @click="toggleVisibility" v-if="isCollapible">
          <Icon :name="isVisible ? 'chevron-up' : 'chevron-down'"  />
        </div>
      </div>
    </div>
    <div class="collapsible-group-body" v-show="isVisible">
      <div class="collapsible-group-body-description" v-if="description">
        <div class="collapsible-group-body-description-icon">
          <Icon name="info-circle" />
        </div>
        <div
          class="collapsible-group-body-description-text"
          v-html="description"
        ></div>
      </div>
      <div class="collapsible-group-content-wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    badges: {
      default: () => [],
      type: Array
    },
    count: {
      type: [Number, String]
    },
    description: {
      default: null,
      type: String
    },
    dataSet: {
      type: Array
    },
    groupCount: {
      default: null,
      type: String
    },
    isCollapible: {
      default: false
    },
    value: {
      default: false
    },
    title: {
      default: null,
      type: String
    },
    requestLimit: {
      default: null
    },
    hasHitRequestLimit: {
      default: false
    }
  },
  data () {
    return {
      isVisible: true
    }
  },
  created () {
    this.isVisible = !this.value
  },
  components: {
    Icon
  },
  methods: {
    toggleVisibility () {
      this.isVisible = !this.isVisible
      this.$emit('input', !this.isVisible)
    }
  }
}
</script>
