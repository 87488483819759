<template>
  <td>{{ data[field] }}</td>
</template>

<script>
export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  }
}
</script>
