<template>
  <b-form-radio-group
    v-model="selected"
    :options="options"
    value-field="value"
    text-field="name"
    :disabled="disabled"
  />
</template>

<script>
/* import i18n, { STORAGE_KEY_LOCALE } from '@/i18n' */

export default {
  name: 'LanguageSwitcherRadios',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: 'nl',
      options: [
        { value: 'nl', name: 'Nederlands' },
        { value: 'en', name: 'English' }
      ]
    }
  },

  mounted () {
    this.selected = this.$i18n.locale
  },

  watch: {
    selected (newVal, oldVal) {
      /* this.setLocale(newVal) */
      this.$emit('changed', newVal)
    }
  },

  methods: {
    setLocale (locale) {
      /*  i18n.locale = locale
      localStorage.setItem(STORAGE_KEY_LOCALE, locale) */
    }
  }
}
</script>
