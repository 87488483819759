import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import axios from '@/common/utils/axios'
// import { logTranslationFindings } from './helpers'

import en from './en.json'
import nl from './nl.json'

const devMode = (
  typeof process === 'object' && process?.env?.VUE_APP_MODE !== 'production'
)

const messages = { en, nl }
export const STORAGE_KEY_LOCALE = 'i18n.locale'

// install VueI18n plugin
Vue.use(VueI18n)

// all translation keys should be formatted like:
// camelCase.camelCase.somethingWithMoreWords
// agument $t, $tc, and $te to adjust keys before processing
const adjustKey = s => s.replace(/(\.[A-Z])/g, (m) => m.toLowerCase())
;['$t', '$tc', '$te'].forEach(fn => {
  const f = Vue.prototype[fn]
  Vue.prototype[fn] = function (key, ...args) {
    return f.apply(
      this,
      [key ? adjustKey(key) : key, ...args]
    )
  }
})

// check for stored locale and set if valid
let initialLocale = 'nl'

// check language in url

if (window.location.search && (window.location.search.includes('?language=') || window.location.search.includes('?lang='))) {
  const languageFromUrl = window.location.search.substring(10)

  localStorage.setItem(STORAGE_KEY_LOCALE, languageFromUrl)
}
const storedLocale = localStorage.getItem(STORAGE_KEY_LOCALE)

if (storedLocale && storedLocale != null) {
  if (Object.prototype.hasOwnProperty.call(messages, storedLocale)) {
    initialLocale = storedLocale
  }
} else {
  localStorage.setItem(STORAGE_KEY_LOCALE, initialLocale)
}

// create and export our central VueI18n instance
export const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: initialLocale,
  messages
})
if (devMode) { // link to window in dev for debugging
  window.i18n = i18n
}

// maintain translation loading status per module and locale
const localesPerModule = {
  auth: {

    locales: {}
  },
  common: {

    locales: {}
  },
  dataquality: {

    locales: {}
  },
  shoppingcart: {

    locales: {}
  },
  product: {

    locales: {}
  }
}
if (devMode) { // link to window in dev for debugging
  window.localesPerModule = localesPerModule
}

/**
 * Load translation messages fir given module and locale
 * @param {string} module The app module for which to fetch amessages
 * @param {string} locale The locale to fetch those messages for
 */
export const loadModuleTranslations = (module, locale) => {
  if (!['en', 'nl'].includes(locale)) {
    throw new Error(`[i18n] Locale '${locale}' not found`)
  }
  if (typeof localesPerModule[module] !== 'object') {
    throw new Error(`[i18n] Module '${module}' not found`)
  }

  if (!localesPerModule[module]?.locales?.[locale]) {
    localesPerModule[module].locales[locale] = {
      fulfilled: true
    }

    /*     localesPerModule[module].locales[locale] = {
      fulfilled: false,
      promise: axios.get(
        localesPerModule[module].endpoint
      ).then(({ data: { payload: messages } }) => {
        if (devMode) {
          localesPerModule[module].locales[locale].messages = messages
        }
        logTranslationFindings(module, locale, messages)
        i18n.mergeLocaleMessage(locale, { [module]: messages })
      }).catch(error => {
        // console.log(error)
        if (devMode) {
          localesPerModule[module].locales[locale].error = error
        }
      }).finally(() => {
        localesPerModule[module].locales[locale].fulfilled = true
      })
    } */

    return new Promise((resolve, reject) => { resolve(777) })
  }
}

export const moduleTranslationsLoaded = (module, locale) => {
  return !!localesPerModule[module]?.locales?.[locale]?.fulfilled
}

export default i18n
