<template>
  <div @mouseover="onOver" @mouseleave="onLeave" v-if="fullName">
    <b-dropdown
      right
      size="md"
      toggle-class="text-decoration-none text-left p-0"
      ref="dropdown"
      variant="clean"
      no-caret
      block
      menu-class="mt-n1 account-dropdown"
    >
      <template #button-content>
        <div class="account-btn d-flex align-items-center">
          <div class="account-btn__info">
            <h5 class="text-truncate">{{ fullName }}</h5>
            <!--  <p class="text-truncate">ToDo bedrijfsnaam</p> -->
          </div>
          <AIcon icon="chevron-down" size="lg" class="ml-3" />
        </div>
      </template>
      <ul class="account-menu list-unstyled">
        <!-- <li>
          <div
            class="account-menu__item"
            @click="$router.push({ path: '/account/details' })"
          >
            <AIcon icon="user-fill" />
            <span class="ml-3">{{
              $t("common.header.button.personalDetails")
            }}</span>
          </div>
        </li> -->

        <li>
          <!-- @click logout-->
          <div class="account-menu__item" @click="logout()">
            <AIcon icon="logout" />
            <span class="ml-3">{{ $t("common.header.button.logout") }}</span>
          </div>
        </li>
      </ul>
    </b-dropdown>
  </div>
</template>

<script>
import AIcon from '@/components/Atoms/AIcon'

import TranslatorMixin, {
  translationModules
} from '@/common/utils/mixins/translator'

export default {
  mixins: [TranslatorMixin(translationModules.DATAQUALITY)],

  components: {
    AIcon
  },
  watch: {
    blockedAccount: {
      immediate: true,
      handler: function (blocked) {
        this.show = !blocked
      }
    }
  },
  data () {
    return {
      dqOnly: false,
      newUser: null,
      show: true
    }
  },
  computed: {
    fullName () {
      var user = JSON.parse(localStorage.getItem('loggedInUser'))

      return user?.data?.payload?.username
    }
  },
  mounted () {},
  methods: {
    logout () {
      localStorage.clear()
      this.$router.push({ name: 'auth.login' })
      this.$b2c.isLoggedIn = false
    },
    onOver () {
      this.$refs.dropdown.visible = true
    },
    onLeave () {
      this.$refs.dropdown.visible = false
    }
  }
}
</script>

<style lang="scss">
.dropdown-toggle {
  &.dropdown-toggle-no-caret {
    &::after {
      display: none;
    }
  }
}
.account-dropdown {
  outline: 0;
}
</style>

<style lang="sass" scoped>
@use '../../../assets/sass/colors' as colors
@use '../../../assets/sass/mixins' as mixins
@use '../../../assets/sass/variables' as vars
@use '../../../assets/sass/typography' as typo

.account-btn
  padding: vars.$spacer-2 vars.$spacer-3
  border-radius: vars.$border-radius-default
  color: colors.$blue

  &__info
    width: 100%
    max-width: 170px

    h5,p
      margin: auto

      p
        font-size: typo.$font-size-tiny
        color: colors.$ui-6

  &:hover
    background: colors.$ui-1

.account-menu
  li
    border-bottom: solid 1px colors.$ui-4
    margin: auto
    &:last-child
      border-bottom: none

  &__item
    display: flex
    align-items: center
    padding: vars.$spacer-2 vars.$spacer-3
    color: colors.$blue
    font-size: typo.$font-size-little
    cursor: pointer

    span
      white-space: nowrap

    &:hover
      background-color: colors.$ui-2
</style>
