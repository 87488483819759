<template>
  <div :class="['dropdown', {'is-searchable': this.action}]">
    <Icon v-if="this.action" name="search" class="dropdown-search-icon" />
    <v-select :options="values" :reduce="sort => sort.value" :placeholder="placeholder" @input="event => $emit('input', event)" @option:selected="event => $emit('selected', event)" @search="search">
      <template #open-indicator="{ attributes }">
        <Icon v-bind="attributes" name="chevron-down" />
      </template>
      <div slot="no-options">{{ currentInput || !this.action ? $t('common.searchSuggest.noResultsFound') : $t('common.searchSuggest.startTypingToDisplaySuggestions') + currentInput }}</div>
    </v-select>
  </div>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'
import vSelect from 'vue-select'
import debounce from 'debounce'

export default {
  props: {
    action: {
      required: true,
      type: String
    },
    placeholder: {
      default: () => `- - ${this.$t('common.searchSuggest.placeholder')} - -`,
      type: String
    },
    label: {
      default: null
    }
  },
  data () {
    return {
      attributes: {
        ref: 'openIndicator',
        role: 'presentation',
        class: 'vs__open-indicator'
      },
      currentInput: '',
      loading: false,
      values: []
    }
  },
  components: {
    Icon,
    vSelect
  },
  methods: {
    search (search, loading) {
      this.currentInput = search
      this.suggest(search, loading)
    },

    suggest: debounce(function (search, loading) {
      if (search) {
        loading(true)
        this.loading = true
        this.$store.dispatch(this.action, [search])
          .then(response => {
            loading(false)
            this.loading = false
            const values = []
            response.payload.forEach(item => {
              values.push(
                this.label
                  ? Object.assign({}, item, { label: this.label(item) })
                  : item
              )
            })

            this.values = values
          })
          .catch(() => {
            loading(false)
            this.loading = false
          })
      } else {
        this.values = []
      }
    }, 500)
  }
}
</script>
