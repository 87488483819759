<template>
  <td>{{ measurements }}</td>
</template>

<script>
export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },

  computed: {
    measurements () {
      return (Math.round(this.data[this.field] * 100) / 100).toFixed(2) + 'cm'
    }
  }
}
</script>
