import { mapActions } from 'vuex'

const ResizeMixin = {

  data () {
    return {
      timeout: null,
      delay: 250
    }
  },

  methods: {
    ...mapActions('app', [
      'setScreenWidth',
      'setScreenHeight',
      'setSmallScreenSize'
    ]),

    onResize (e) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.setDimensions, this.delay)
    },

    setDimensions () {
      this.setScreenWidth(window.innerWidth)
      this.setScreenHeight(window.innerHeight)
    }
  },

  created () {
    window.addEventListener('resize', this.onResize)
    this.onResize()

    // set small screen size
    let breakpointSize = getComputedStyle(document.documentElement).getPropertyValue('--gs1-breakpoint-md')
    breakpointSize = breakpointSize.substring(0, breakpointSize.length - 2)
    this.setSmallScreenSize(parseFloat(breakpointSize))
  },

  destroyed () {
    window.removeEventListener('resize', this.onResize)
  }
}
export default ResizeMixin
