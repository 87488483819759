import { render, staticRenderFns } from "./ODataTable.pug?vue&type=template&id=a01b821c&scoped=true&lang=pug&"
import script from "./ODataTable.js?vue&type=script&lang=js&"
export * from "./ODataTable.js?vue&type=script&lang=js&"
import style0 from "./ODataTable.sass?vue&type=style&index=0&id=a01b821c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a01b821c",
  null
  
)

export default component.exports