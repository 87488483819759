import Vue from 'vue'
import Router from 'vue-router'
import CommonRoutes from '@/common/router'
import modules from './common/utils/modules'

Vue.use(Router)

// const b2c = Vue.prototype.$b2c
let routes = CommonRoutes

// If a module has routes, add them to the existing routes.
modules.routes().forEach(moduleRoutes => {
  routes = routes.concat(require('@/' + moduleRoutes.path).default)
})

// Setup the Vue Router
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
