import i18n from '@/i18n'

function flattenObjectKeys (obj, parent, res = []) {
  for (const key in obj) {
    const propName = parent ? parent + '.' + key : key
    if (typeof obj[key] === 'object') {
      flattenObjectKeys(obj[key], propName, res)
    } else {
      res.push(propName)
    }
  }
  return res
}

function listMissingKeys (src, des) {
  return src.reduce((acc, key) => des.includes(key) ? acc : [...acc, key], [])
}

export function logTranslationFindings (module, locale, payload) {
  const flatKeysLocal = flattenObjectKeys(i18n.messages[locale][module])
  const flatKeysRemote = flattenObjectKeys(payload)
  const missingKeys = listMissingKeys(flatKeysLocal, flatKeysRemote).reduce(
    ({ missingInApp, missingInCms }, key) => {
      const fullKey = `${module}.${key}`
      const nl = i18n.te(fullKey, 'nl') ? i18n.t(fullKey, 'nl') : undefined
      const en = i18n.te(fullKey, 'en') ? i18n.t(fullKey, 'en') : undefined
      const appWorkIsDone = en && fullKey.match(/(\.[A-Z])/) === null

      if (appWorkIsDone) {
        missingInCms[key] = { en, nl }
      } else {
        missingInApp[key] = { en, nl }
      }

      return { missingInApp, missingInCms }
    },
    { missingInApp: {}, missingInCms: {} }
  )
  if (Object.keys(missingKeys.missingInCms).length) {
    // console.warn(`[i18n] Missing the following keys from CMS (${module})`, missingKeys.missingInCms)
  }
  if (Object.keys(missingKeys.missingInApp).length) {
    // console.warn(`[i18n] Missing or malformed keys in FE app (${module})`, missingKeys.missingInApp)
  }
  const redundantKeys = listMissingKeys(flatKeysRemote, flatKeysLocal)
  if (redundantKeys.length) {
    // console.warn(`[i18n] Redundant keys received from CMS (${module})`, redundantKeys)
  }
}

export default logTranslationFindings
