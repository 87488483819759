<template>
  <DefaultLayout extraClass="gs1-background-color-ui-1 flex-grow-1">
    <div class="gs1-background-color-ui-1 flex-grow-1">
      <div class="container h-100">
        <router-view />
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'

export default {
  name: 'ModuleHome',

  components: {
    DefaultLayout
  }
}
</script>
<style scoped>
.fill-height {
  height: 100%;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1400px;
    overflow-x: hidden;
  }
}
</style>
