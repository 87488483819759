/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AP': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Pinda" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#D78C6B" cx="16" cy="16" r="15"></circle>
              <path d="M23.8829487,20.3385011 C23.7817902,18.910441 23.0000202,17.696339 21.8800517,16.9800008 L21.8840659,16.9767894 C18.8332559,15.0274824 18.5436297,14.6463318 17.5495071,11.1890816 C17.5468979,11.1800496 17.5442887,11.1710176 17.5416794,11.1621863 C17.5406758,11.1589749 17.539873,11.1555629 17.5388694,11.1523515 L17.5382673,11.1525522 C16.9843044,9.21588993 15.1361558,7.86329724 13.0465516,8.01102068 C10.648053,8.18082234 8.84125084,10.2630002 9.01105251,12.6614989 C9.11201024,14.089559 9.89398102,15.303661 11.0139495,16.0199992 L11.0099352,16.0232106 C14.0607453,17.9725176 14.3501708,18.3536682 15.344494,21.8109184 C15.3471033,21.8199504 15.3497125,21.8289824 15.3523218,21.8378137 C15.3533253,21.8410251 15.3541282,21.8444371 15.3551317,21.8476485 L15.3555332,21.8474478 C15.909496,23.7841101 17.7578454,25.1367028 19.8472488,24.9889793 C22.2459482,24.8191777 24.0527503,22.7369998 23.8829487,20.3385011 Z M11.641774,12.8397304 C11.3734232,13.1080813 10.9382813,13.1080813 10.6699305,12.8397304 C10.4015796,12.5713796 10.4015796,12.1362377 10.6699305,11.8678869 C10.9382813,11.599536 11.3734232,11.599536 11.641774,11.8678869 C11.9101249,12.1362377 11.9101249,12.5711789 11.641774,12.8397304 Z M12.3655386,10.6479643 C12.0971877,10.3796134 12.0971877,9.94447155 12.3655386,9.67612069 C12.6338894,9.40776983 13.0690313,9.40776983 13.3373822,9.67612069 C13.605733,9.94447155 13.605733,10.3796134 13.3373822,10.6479643 C13.0690313,10.9163151 12.6338894,10.9163151 12.3655386,10.6479643 Z M14.603669,12.8714428 C14.3353182,13.1397937 13.9001763,13.1397937 13.6318255,12.8714428 C13.3634746,12.6030919 13.3634746,12.1679501 13.6318255,11.8995992 C13.9001763,11.6312484 14.3353182,11.6312484 14.603669,11.8995992 C14.8720199,12.1681508 14.8720199,12.6032926 14.603669,12.8714428 Z" id="Shape" fill="#CC6B3F" fill-rule="nonzero"></path>
          </g>`
  }
})
