/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AF': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Vis" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <circle id="Oval-Copy-3" stroke="#0086CC" cx="16" cy="16" r="15"></circle>
            <path d="M25,14.9078151 C23.5257174,12.0042057 18.7411464,8.37356054 13.0771057,14.4380469 C13.0771057,14.4380469 9.15500413,10.442845 8.23889004,11.0659915 C7.32239818,11.689138 9.33841583,14.8046814 9.33841583,16.41742 C9.33841583,18.0301585 7.32239818,21.145702 8.23889004,21.7688485 C9.15519302,22.3919949 13.0771057,18.3967931 13.0771057,18.3967931 C18.7411464,24.4610905 23.5257174,20.8304454 25,17.9270249 L22.9273155,16.41742 L25,14.9078151 Z" id="Path" fill="#2385C6" fill-rule="nonzero"></path>
          </g>`
  }
})
