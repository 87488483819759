/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AM': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Melk" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#B1DFF7" cx="16" cy="16" r="15"></circle>
              <path d="M13.0384986,8.2499872 C12.4649754,8.2499872 12.000043,7.74631041 12.000043,7.1249936 C12.000043,6.50367679 12.4649754,6 13.0384986,6 L13.0384986,6 L19.9615362,6 C20.5350594,6 20.9999918,6.50367679 20.9999918,7.1249936 C20.9999918,7.74631041 20.5350594,8.2499872 19.9615362,8.2499872 L19.9615362,8.2499872 L19.6153841,8.2499872 L19.6153841,10.6611514 C19.6176346,10.9048986 19.7220776,11.1380317 19.9061499,11.3101861 L19.9061499,11.3101861 L20.5049892,11.868356 C20.823107,12.1644401 21.0013938,12.5676917 20.9999918,12.987941 L20.9999918,12.987941 L20.9999918,24.151339 C20.9999918,24.689017 20.5350538,25.1248912 19.9615339,25.1248912 L19.9615339,25.1248912 L13.0385368,25.1248912 C12.4650169,25.1248912 12.000043,24.689017 12.000043,24.151339 L12.000043,24.151339 L12.000043,12.987941 C11.9968166,12.5654449 12.1752465,12.1595301 12.4950815,11.8618657 L12.4950815,11.8618657 L13.0939207,11.3004506 C13.2779931,11.1282962 13.3824361,10.8951631 13.3846866,10.6514158 L13.3846866,10.6514158 L13.3846866,8.2499872 Z" id="Combined-Shape" fill="#71C7EF" fill-rule="nonzero"></path>
          </g>`
  }
})
