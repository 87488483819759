import namespace from '@/common/utils/namespace'

/** Deprecated, use /store/modules instead! */
export default namespace('', {
  getters: [
    'loaders',
    'notifications',
    'supplier',
    'supplierScope',
    'accountScope',
    'accountNumber',
    'translations',
    'has403error',
    'scaleCategoryId',
    'selectedSupplier',
    'loggedInUser'
  ],
  actions: [
    'translations'
  ],
  mutations: [
    'hideAlert',
    'loaderStart',
    'loaderStop',
    'storeTranslations',
    'addNotification',
    'updateNotification',
    'removeNotification',
    'storeAccountScope',
    'storeAccountScopeAccountName',
    'storeSupplierScope',
    'storeSupplierData',
    'storeContact',
    'updateHas403error',
    'storeScaleCategoryId',
    'setSelectedSupplier',
    'setLoggedInUser',
    'setSelectedSupplierName',
    'removeSelectedSupplier',
    'removeSelectedSupplierName'
  ]
})
