<template>
  <div id="api" class="center"></div>
</template>
<script>
export default {
  components: {},

  computed: {},

  data () {
    return {}
  },

  created () {},

  mounted () {},

  methods: {},

  watch: {}
}
</script>
<style scoped>
.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
</style>
