<template>
  <td>
    <Icon :name="data[field].allergenType" size="lg" />
    <span class="ml-2">
      {{ data[field].validationDescription }}
    </span>
  </td>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  components: {
    Icon
  }
}
</script>
