const LocalStorageService = {

  setItem: (key, value) => {
    var saveValue = value
    if (Array.isArray(value)) {
      saveValue = JSON.stringify(this.cats)
    }

    localStorage.setItem(key, saveValue)
  },

  getItem: (key) => {
    localStorage.getItem(key)
  },

  deleteItem: (key) => {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key)
    }
  }

}

export default LocalStorageService
