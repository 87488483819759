<template>
  <MContentGroup isBordered isFullwidth hasShadow>
    <template #body-content>
      <div>
        <slot name="top" />
        <div v-if="displayOrderOverview">
          <slot name="orderoverview" />
          <hr class="mt-4" />
        </div>
        <div class="mt-4" v-if="displayUspList">
          <!-- ToDo: Create component! Because it's duplicate on multieple pages!-->
          <AHeading class="mb-4" size="h5">{{
            $t("common.uniqueSellingPointList.uniqueSellingPointTitle")
          }}</AHeading>
          <MList
            type="icon"
            iconName="check-circle-fill"
            :listData="uspList"
            class="mb-4"
          />
        </div>
        <slot name="bottom" />
      </div>
    </template>
  </MContentGroup>
</template>
<script>
import AHeading from '@/components/Atoms/AHeading'
import MList from '@/components/Molecules/MList/MList.vue'
import MContentGroup from '@/components/Molecules/MContentGroup'

export default {
  components: {
    MContentGroup,
    MList,
    AHeading
  },
  props: {
    overviewCollapsed: {
      default: null,
      type: Boolean,
      require: false
    },
    showOrderOverview: {
      default: false,
      type: Boolean,
      require: true
    },
    firstCartItemFamilyName: {
      default: null,
      type: String,
      require: false
    }
  },
  data () {
    return {}
  },
  methods: {},
  computed: {
    uspList () {
      return []
    },

    displayOrderOverview () {
      return true
    },
    displayUspList () {
      return this.$route.name !== 'account.verify' && this.uspList.length > 0
    }
  }
}
</script>
<style scoped>
.border-3 {
  border-width: 3px !important;
}
</style>
