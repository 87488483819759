/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav-icon-exclamationmark': {
    width: 48.199,
    height: 48.199,
    viewBox: '0 0 12.753 12.753',
    data: '<path pid="0" d="M6.376 0A6.376 6.376 0 000 6.376a6.376 6.376 0 006.376 6.377 6.376 6.376 0 006.377-6.377A6.376 6.376 0 006.376 0zm.012 3.172c.451.002.797.215.724.832-.13 1.096.095 3.262-.758 3.264-.735.011-.606-2.28-.759-3.183-.108-.643.282-.916.793-.913zm-.039 4.83a.744.744 0 01.012 0 .744.744 0 01.743.744.744.744 0 01-.743.744.744.744 0 01-.744-.744.744.744 0 01.732-.743z" _fill="currentColor"/>'
  }
})
