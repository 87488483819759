<template>
  <td>
    <a v-if="detailsCanBeFetched" :href="data[field]" target="_blank">{{
      data[field]
    }}</a>
    <template v-else>{{ $t("dataquality.noUrl") }}</template>
  </td>
</template>

<script>
import { bus } from '../../../../eventBus'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  computed: {
    detailsCanBeFetched () {
      return !!this.data.url
    }
  },
  methods: {
    showArticleDetail () {
      bus.$emit('showArticleDetail', this.data)
    }
  }
}
</script>
