<template>
  <td>
    {{ moment(this.data[field]).format('L') }}
  </td>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      moment: moment
    }
  },
  created () {
    this.moment.locale(this.$i18n.locale)
  }
}
</script>
