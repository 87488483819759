<template>
  <div
    :class="[
      'svg-icon-wrapper',
      {[constrain+'-'+size]: size},
      'icon-'+name,
      {['animation-'+animation]: animation},
      {'original-color': original}
    ]"
  >
    <div v-if="isLoading" class="is-loading spinner-border text-info spinner-border-sm" role="status"></div>
    <SvgIcon
      v-if="!isLoading"
      :class="[
        {[constrain+'-'+size]: size},
        'icon-'+name,
        {['animation-'+animation]: animation},
        {'original-color': original}
      ]"
      :name="name"
      :original="original">
    </SvgIcon>
  </div>
</template>

<script>
import SvgIcon from 'vue-svgicon'

export default {

  name: 'Icon',

  components: {
    SvgIcon
  },

  props: {
    original: {
      default: false
    },
    name: {
      type: String,
      required: true
    },
    size: {
      default: null
    },
    animation: {
      default: null
    },
    constrain: {
      default: 'size',
      validator: function (value) {
        // The value must match one of these strings
        return ['size', 'height', 'width'].indexOf(value) !== -1
      }
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },

  created () {
    this.getIcon()
  },

  methods: {
    getIcon () {
      if (this.name) {
        require(`./icons/${this.name}`)
      }
    }
  },

  watch: {
    name: function () {
      this.getIcon()
    }
  }
}
</script>
