<template>
  <div>
    <div class="loader-wrapper" v-if="isActive">
      <div class="loader-wrapper-icon">
        <div
          v-if="isLoading"
          class="spinner-border text-info"
          role="status"
        ></div>
        <div class="loader-wrapper-label" v-if="label">{{ label }}</div>
      </div>
    </div>
    <slot v-else />
  </div>
</template>

<script>
import types from '@/common/store/types'

export default {
  props: {
    label: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    isActive () {
      if (this.active === false) return false
      return this.loader ? this.loader.isActive : true
    },
    isLoading () {
      if (this.active === true) return true
      return this.loader ? this.loader.isLoading : false
    },
    loader () {
      return this.$store.getters[types.getters.loaders](this._uid)
    }
  }
}
</script>
