<template>
  <div class="container py-4">
    <div class="row">
      <div class="col-md-7 col-lg-7">
        <AHeading class="mb-4" size="h2">Inloggen bestaande klant</AHeading>

        <div class="panel-body">
          <vue-form-g
            :schema="loginSchema"
            :model="loginModel"
            :options="formOptions"
            @validated="onValidated"
            @model-updated="onFormChanged"
          />

          <div v-if="errorMsg" class="col-12 mb-2">
            <p class="text-danger">{{ errorMsg }}</p>
          </div>

          <div class="col-12">
            <AButton @click="submitLoginForm" :disabled="!isFormValid"
              >Aanmelden</AButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from '@/common/store/types'
import AHeading from '@/components/Atoms/AHeading'
import AButton from '@/components/Atoms/AButton'
import VueFormGenerator from 'vue-form-generator'
import Vue from 'vue'
import customValidators from '@/components/Organisms/OFormGenerator/validators/'
import AuthenticationService from '../../../services/AuthenticationService'

Vue.use(VueFormGenerator, {
  validators: customValidators
})

export default {
  components: {
    'vue-form-g': VueFormGenerator.component,
    AButton,
    AHeading
  },

  data () {
    return {
      errorMsg: null,
      isFormValid: false,
      isFormChanged: false,
      loginModel: {
        username: '',
        password: ''
      },
      loginSchema: {
        groups: [
          {
            styleClasses: ['row'],
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'E-mailadres',
                model: 'username',
                styleClasses: ['col-12'],
                required: true,
                min: 3,
                max: 100,
                validator: ['emailMinMax']
              },
              {
                type: 'input',
                inputType: 'password',
                label: 'Wachtwoord',
                model: 'password',
                styleClasses: ['col-12'],
                min: 3,
                max: 100,
                required: true,
                validator: ['requiredField']
              }
            ]
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  },
  methods: {
    submitLoginForm () {
      const data = {
        username: this.loginModel.username,
        password: this.loginModel.password
      }

      AuthenticationService.login(data)
        .then(response => {
          if (!response.error) {
            // Set logged in user
            this.$store.commit(
              types.mutations.setLoggedInUser,
              JSON.stringify(response)
            )

            localStorage.setItem(
              'token',
              JSON.stringify(response.data.payload.token)
            )

            this.$router.push({ name: 'home.index' })
          } else {
            this.errorMsg =
              'De gebruikersnaam in combinatie met het wachtwoord is niet bekend bij ons.'
          }
        })
        .catch(response => {
          this.isFormValid = false
          this.errorMsg = response.error.message
        })
    },

    onValidated (isValid, errors) {
      this.$emit('validated-form', isValid, errors, this.model)
      this.isFormValid = isValid
    },

    onFormChanged (newValue, schema) {
      this.isFormChanged = true
      this.$emit('changed-form', newValue, schema, this.model)
    }
  }
}
</script>
<style lang="sass">
@use '../../../assets/sass/colors' as colors

.errors.help-block
  color: colors.$danger
  font-size:  1rem
</style>
