import { render, staticRenderFns } from "./MFormGroup.pug?vue&type=template&id=e74c5942&lang=pug&"
import script from "./MFormGroup.js?vue&type=script&lang=js&"
export * from "./MFormGroup.js?vue&type=script&lang=js&"
import style0 from "./MFormGroup.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports