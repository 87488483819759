<template>
  <div :class="'tooltip bs-tooltip-' + position" role="tooltip">
    <div class="arrow"></div>
    <div class="tooltip-inner">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['top', 'bottom'].indexOf(value) !== -1
      }
    }
  }
}
</script>
