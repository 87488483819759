import { Role } from '../modules/auth/Role'
import axios from '@/common/utils/axios'
/**
 * @class AuthenticationService
 */
const AuthenticationService = {
  can: (roleObject) => {
    try {
      var isAuthorized = false
      var role = localStorage.getItem('accountsToken')
      Role.forEach(element => {
        if (element.Name === role) { isAuthorized = element.RoleRights.includes(roleObject) }
      })
      return { isAuthorized }
    } catch (error) {
      return { error }
    }
  },
  login: async (loginModel) => {
    const url = '/Authentication/signin'
    try {
      const { data } = await axios.post(url, loginModel)
      return { data }
    } catch (error) {
      return { error }
    }
  }
}
export default AuthenticationService
