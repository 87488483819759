<template>
  <div id="app">
    <LoaderWrapper :active="translationsLoading">
      <div class="app-wrapper">
        <div :class="mainClass">
          <portal-target class="headerMessage" name="header-message" multiple />
          <component :is="header" />
          <main class="pt-4">
            <Notifications />
            <slot></slot>
          </main>
          <DefaultFooter />
        </div>
      </div>
    </LoaderWrapper>
  </div>
</template>

<script>
import DefaultFooter from '@/common/views/Partials/DefaultFooter'
import DefaultHeader from '@/common/views/Partials/DefaultHeader'
import HeaderOnly from '@/common/views/Partials/HeaderOnly'

import LoaderWrapper from '@/common/components/LoaderWrapper'
import Notifications from '@/common/components/Alert/Notifications'
import TranslatorMixin, {
  translationModules
} from '@/common/utils/mixins/translator'

export default {
  components: {
    DefaultHeader,
    DefaultFooter,
    LoaderWrapper,
    Notifications
  },

  mixins: [TranslatorMixin(translationModules.COMMON)],
  props: {
    extraClass: {
      type: String,
      require: false,
      default: ''
    }
  },
  computed: {
    header () {
      if (this.$route.meta.header && this.$route.meta.header === 'HeaderOnly') {
        return HeaderOnly
      }

      return DefaultHeader
    },
    mainClass () {
      return 'app-main ' + this.extraClass
    }
  }
}
</script>

<style lang="sass">
@import '@/common/assets/sass/bootstrap'
@import '@/common/assets/sass/app'
</style>
