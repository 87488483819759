/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 1,
    height: 1,
    viewBox: '0 0 24 24',
    data: '<path d="M18.72 6.7L17.3 5.28l-5.3 5.3-5.3-5.3L5.28 6.7l5.3 5.3-5.3 5.3 1.42 1.42 5.3-5.3 5.3 5.3 1.42-1.42-5.3-5.3z" />'
  }
})
