<template>
  <CollapsibleGroup class="data-table" :title="title">
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <!-- Pagination -->
    <div class="data-table-pagination-top" v-if="hasTopPagination">
      <PaginationView v-model="pagination" />
    </div>

    <!-- Table -->
    <LoaderWrapper ref="loader" class="data-table-overflow">
      <table class="data-table-content" v-if="dataSet.length">
        <thead>
          <tr>
            <DataTableSortingHeading
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :field="column.field"
              :label="column.label"
              v-model="sorting"
            />
            <td></td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, rowIndex) in getData">
            <tr
              :key="`row-${rowIndex}`"
              :class="{
                'collapser-group': row.referentsCount > 0,
                'tr-white': rowIndex % 2 === 0,
              }"
            >
              <component
                v-bind:is="mapColumnComponent(column)"
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :data="row"
                :field="hasProperty(column, 'field') ? column.field : null"
                :column="column"
              ></component>
              <td>
                <button
                  v-if="row.referentsCount > 0"
                  class="row-collapser"
                  @click="toggleCollapsed(rowIndex)"
                >
                  <Icon :name="collapserIcon(!row._expanded)" />
                </button>
              </td>
            </tr>
            <tr class="collapsible-offset-row" :key="`row-${rowIndex}-offset`">
              <td :colspan="columns.length + 1"></td>
            </tr>
            <template v-if="row._expanded">
              <tr
                v-for="(subrow, subrowIndex) in row.referents"
                :key="`row-${rowIndex}-${subrowIndex}`"
                :class="subrowIndex % 2 === 0 ? 'tr-even' : 'tr-odd'"
              >
                <component
                  v-bind:is="mapColumnComponent(column)"
                  v-for="(column, columnIndex) in columns"
                  :key="columnIndex"
                  :data="row"
                  :field="hasProperty(column, 'field') ? column.field : null"
                  :column="column"
                ></component>
                <td></td>
              </tr>
              <tr
                class="collapsible-offset-row-expanded"
                :key="`row-${rowIndex}-offset-expanded`"
              >
                <td :colspan="columns.length + 1"></td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <div v-if="!dataSet.length" class="data-table-no-results">
        {{ $t("common.table.noResults", [title]) }}
      </div>
    </LoaderWrapper>

    <!-- Pagination -->
    <div class="data-table-pagination-bottom" v-if="hasBottomPagination">
      <PaginationView v-model="pagination" />
    </div>
  </CollapsibleGroup>
</template>

<script>
import CollapsibleGroup from '@/common/components/CollapsibleGroup'
import LoaderWrapper from '@/common/components/LoaderWrapper'
import DataTableSortingHeading from '@/common/components/DataTable/Partials/TableHeading'
import Sorting from '@/common/components/DataTable/lib/Sorting'
import DataTableFilterArticleCode from '@/common/components/DataTable/Filter/ArticleCode'
import DataTableFilterText from '@/common/components/DataTable/Filter/Text'
import DataTableFilterMeasurements from '@/common/components/DataTable/Filter/Measurements'
import Icon from '@/common/components/Icon/Icon'
import TableMixin from '@/common/utils/mixins/table'
import PaginationView from '@/common/components/DataTable/Partials/Pagination'
import Pagination from '@/common/components/DataTable/lib/Pagination'

export default {
  name: 'CollapsibleTable',
  mixins: [TableMixin],
  components: {
    CollapsibleGroup,
    DataTableSortingHeading,
    DataTableFilterArticleCode,
    DataTableFilterMeasurements,
    DataTableFilterText,
    Icon,
    LoaderWrapper,
    PaginationView
  },
  data () {
    return {
      dataSet: [],
      columnTypeMapping: {
        ArticleCode: 'DataTableFilterArticleCode',
        Text: 'DataTableFilterText',
        Measurements: 'DataTableFilterMeasurements'
      },
      sorting: Sorting.createInstance(this.sortType, this.sort),
      pagination: new Pagination(this.limit),
      expandedParents: []
    }
  },
  computed: {
    getData () {
      return this.dataSet.map((item, itemIndex) => ({
        ...item,
        _expanded: this.expandedParents[itemIndex]
      }))
    }
  },
  methods: {
    collapserIcon (collapsed) {
      return collapsed ? 'chevron-down' : 'chevron-up'
    },
    toggleCollapsed (toggleIndex) {
      this.expandedParents = this.expandedParents.map((value, index) =>
        index === toggleIndex ? !value : value
      )
    },
    getDatasetFromAPI () {
      if (this.isCollapsedGroup ?? false) {
        return
      }

      this.$store
        .dispatch(this.action, {
          page: this.pagination.currentPage,
          limit: this.pagination.pageLimit,
          sort: this.sorting.getSortingQueryString() || undefined,
          search: this.searchQuery || undefined,
          loader: {
            id: this.$refs.loader._uid,
            timeout: 0
          }
        })
        .then((response) => {
          this.dataSet = response.payload
          this.totalCount = response.paging.totalCount
          this.pagination.update(
            response.paging.totalPages,
            response.paging.currentPage
          )
        })
        .catch(() => {
          this.dataSet = []
          this.totalCount = 0
          this.pagination.update(0, 0)
        })
    }
  },
  watch: {
    dataSet: {
      immediate: true,
      handler (newValue) {
        this.expandedParents = new Array(newValue.length).fill(false)
      }
    }
  }
}
</script>
