var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value.totalPages > 1)?_c('nav',[_c('ul',{staticClass:"pagination"},[_c('li',{staticClass:"page-item"},[_c('a',{class:[
          'page-link',
          {'disabled': !_vm.value.hasPrevious() }
        ],on:{"click":function($event){return _vm.value.selectPrevious()}}},[_c('Icon',{attrs:{"name":"arrow-left-short"}})],1)]),_vm._l((_vm.value.pageCount()),function(pageNumber){return _c('li',{key:pageNumber,staticClass:"page-item"},[_c('a',{class:[
          'page-link',
          {'active': pageNumber === _vm.value.currentPage}
        ],on:{"click":function($event){return _vm.value.selectPage(pageNumber);}}},[_vm._v(_vm._s(pageNumber))])])}),_c('li',{staticClass:"page-item"},[_c('a',{class:[
          'page-link',
          {'disabled': !_vm.value.hasNext()}
        ],on:{"click":function($event){return _vm.value.selectNext()}}},[_c('Icon',{attrs:{"name":"arrow-right-short"}})],1)])],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }