<template>
    <router-link
      :class="[
        'text-link',
        {'is-icon-left': iconLeft},
        {'is-icon-right': iconRight},
      ]"
      :to="to"
    >
      <Icon v-if="iconLeft" :name="iconLeft" />
      <span><slot /></span>
      <Icon v-if="iconRight" :name="iconRight" />
    </router-link>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    iconLeft: {
      default: null,
      type: String
    },
    iconRight: {
      default: null,
      type: String
    },
    to: {
      default: function () {
        return {}
      },
      type: Object
    }
  },

  components: {
    Icon
  },

  computed: {
  }
}
</script>
