<template>
  <div v-if="isSubNavActive">
    <b-collapse id="mobile-collapse-menu" :visible="isSubNavActive">
      <div class="mobile">
        <div class="container">
          <ul class="list-unstyled">
            <li
              class="mobile-submenu-list"
              :class="{ active: isParentActive(item) }"
              v-for="(item, index) in this.getAllSubnavItems"
              :key="`MOBILE_SUBNAV_${index}`"
            >
              <!-- has no dropdown -->
              <div v-if="item.children && item.children.length === 0">
                <router-link
                  :to="item.route"
                  @click.native="handleRouteChange(item.route)"
                  class="subnavigation-child"
                >
                  <h4 class="mobile-submenu-header">
                    <Icon :name="item.icon" v-if="item.icon" class="mr-3" />
                    <span>{{
                      $t(item.i18nKey ? item.i18nKey : item.label)
                    }}</span>
                    <span class="sr-only"
                      >({{
                        $t(item.i18nKey ? item.i18nKey : item.label)
                      }})</span
                    >
                  </h4>
                </router-link>
              </div>
              <div v-else>
                <!-- with dropdown -->
                <h4
                  class="mobile-submenu-header"
                  v-b-toggle="`MOBILE_SUBNAV_${index}`"
                >
                  <span>
                    {{ $t(item.i18nKey ? item.i18nKey : item.label) }}
                    <span class="sr-only"
                      >({{
                        $t(item.i18nKey ? item.i18nKey : item.label)
                      }})</span
                    >
                  </span>
                  <AIcon
                    icon="chevron-down"
                    viewBox="0 0 16 16"
                    size="md"
                    v-if="item.children && item.children.length > 0"
                  />
                </h4>
                <b-collapse :id="`MOBILE_SUBNAV_${index}`">
                  <ul
                    class="mobile-submenu-items list-unstyled ml-3"
                    v-if="item.children && item.children.length > 0"
                  >
                    <li
                      v-for="(child, childIndex) in item.children"
                      :key="`MOBILE_SUBNAV_${index}_${childIndex}`"
                    >
                      <router-link
                        :to="child.route"
                        @click.native="handleRouteChange(child.route)"
                        class="subnavigation-child"
                        :class="{ 'border-0': index === 0 }"
                      >
                        <Icon
                          :name="child.icon"
                          v-if="child.icon"
                          class="mr-3"
                        />
                        <span>{{
                          $t(child.i18nKey ? child.i18nKey : child.label)
                        }}</span>
                        <span class="sr-only"
                          >({{
                            $t(child.i18nKey ? child.i18nKey : child.label)
                          }})</span
                        >
                      </router-link>
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </li>
          </ul>

          <!-- other menu items -->
          <ul class="list-unstyled">
            <!--           <li v-if="isDataquality">
            <a
              @click.prevent="$emit('setLocale', 'nl')"
              href="?"
              class="subnavigation-child"
            >
              <h4
                class="d-inline m-auto"
                :class="{ 'gs1-color-orange': this.$i18n.locale === 'nl' }"
              >
                NL
              </h4>
            </a>
            |
            <a
              href="?"
              @click.prevent="$emit('setLocale', 'en')"
              class="subnavigation-child"
            >
              <h4
                class="d-inline m-auto"
                :class="{ 'gs1-color-orange': this.$i18n.locale === 'en' }"
              >
                EN
              </h4>
            </a>
          </li> -->
            <li>
              <a
                class="gs1-color-orange"
                href="https://www.gs1.nl/support/"
                target="_blank"
              >
                <h4 class="gs1-color-orange">Support</h4>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import i18n, { STORAGE_KEY_LOCALE } from '@/i18n'

import Icon from '@/common/components/Icon/Icon'

export default {
  components: {
    Icon
  },

  data: () => {
    return {
      showSubNav: true,
      showDataQuality: false,
      supplierOptions: []
    }
  },
  methods: {
    ...mapActions('subnavigation', ['setSubNavActive', 'setSubnavItems']),

    setLocale (locale) {
      localStorage.setItem(STORAGE_KEY_LOCALE, locale)
      i18n.locale = locale
    },

    handleRouteChange (route) {
      this.setSubNavActive(false)
    },
    isParentActive (item) {
      const isCurrentRoute = item.route.name === this.$route.name
      let isChildActive = false

      if (item.children) {
        isChildActive = item.children.some(
          (child) => child.route.name === this.$route.name
        )
      }

      return isCurrentRoute || isChildActive
    }
  },
  watch: {},
  mounted () {},
  created () {},
  computed: {
    ...mapGetters('subnavigation', ['isSubNavActive', 'getAllSubnavItems']),

    selectableLocales () {
      return Object.keys(i18n.messages).filter(
        (locale) => locale !== this.$i18n.locale
      )
    }
  }
}
</script>

<style src="./SubNavigation.sass" lang="sass" scoped />
