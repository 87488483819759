var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'svg-icon-wrapper',
    ( _obj = {}, _obj[_vm.constrain+'-'+_vm.size] = _vm.size, _obj ),
    'icon-'+_vm.name,
    ( _obj$1 = {}, _obj$1['animation-'+_vm.animation] = _vm.animation, _obj$1 ),
    {'original-color': _vm.original}
  ]},[(_vm.isLoading)?_c('div',{staticClass:"is-loading spinner-border text-info spinner-border-sm",attrs:{"role":"status"}}):_vm._e(),(!_vm.isLoading)?_c('SvgIcon',{class:[
      ( _obj$2 = {}, _obj$2[_vm.constrain+'-'+_vm.size] = _vm.size, _obj$2 ),
      'icon-'+_vm.name,
      ( _obj$3 = {}, _obj$3['animation-'+_vm.animation] = _vm.animation, _obj$3 ),
      {'original-color': _vm.original}
    ],attrs:{"name":_vm.name,"original":_vm.original}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }