<template>
 <div class="card p-2 h-100">
    <div class="row">
      <div class="col-12">
        <h5 class="card-title m-0">
          Supplier: {{ this.user.name }} [{{ this.user.gln }}]
        </h5>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading">
      <div class="row mt-5 pb-5 mb-3">
        <div class="col-8">
          <div>
            <table class="table-bordered">
              <thead class="table-info">
                <th colspan="4">Healthcare institutions</th>
                <th colspan="1">Number</th>
              </thead>

              <tbody>
                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Number of connected healthcare providers</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.hospitalTotalMatched
                    }}</small>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Number of unconnected healthcare providers</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.hospitalTotalNotMatched
                    }}</small>
                  </td>
                </tr>
              </tbody>

              <tfoot class="table-info">
                <tr>
                  <td colSpan="4">Total</td>
                  <td colspan="1" class="text-right">
                    {{ this.hospitalDataTotal }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="col-4">
          <Pie
            id="Analyse-Pie-Chart"
            :chartOptions="chartOptions"
            :chartData="importChartData"
          >
          </Pie>
        </div>
      </div>

      <div class="row pt-5 mt-3">
        <div class="col-8">
          <div>
            <table class="table-bordered">
              <thead class="table-info">
                <th colspan="4">Articles</th>
                <th colspan="1">Number</th>
              </thead>

              <tbody>
                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Number of articles in scope data quality</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.totalArticlesInScope
                    }}</small>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">
                    <small class="text-muted">Other articles</small>
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.totalArticlesOutOfScope
                    }}</small>
                  </td>
                </tr>
              </tbody>

              <tfoot class="table-info">
                <tr>
                  <td colSpan="4">Total</td>
                  <td colspan="1" class="text-right">
                    {{ this.totalArticlesCombined }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="col-4">
          <Pie
            id="Artikel-Pie-Chart"
            :chartOptions="chartOptions"
            :chartData="chartData"
          >
          </Pie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import SupplierService from '../../../services/SupplierService'
import types from '@/common/store/types'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  CategoryScale
)

export default {
  name: 'Action',
  props: {},
  components: {
    Pie
  },
  data () {
    return {
      isLoading: false,
      metrics: [],
      imports: [],
      totalArticles: null,
      hospitalData: null,
      query: null,
      suppliers: null,
      selectedSupplier: 'Alle Leveranciers',
      chartData: {},
      importChartData: {},
      chartOptions: {
        plugins: {
          legend: false,

          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0
              const dataArr = ctx.chart.data.datasets[0].data

              dataArr.map(data => {
                sum += data
              })

              const percentage = ((value * 100) / sum).toFixed(0) + '%'

              return percentage
            },
            font: {
              size: 16,
              weight: 'bold'
            },
            color: '#fff'
          }
        }
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    },
    totalArticlesInScope () {
      return this.totalArticles?.aantalInScope
    },
    totalArticlesOutOfScope () {
      return this.totalArticles?.aantalOutScope
    },
    totalArticlesCombined () {
      return this.totalArticles?.aantalTotaal
    },
    hospitalTotalMatched () {
      return this.hospitalData?.aantalGekoppeld
    },
    hospitalTotalNotMatched () {
      return this.hospitalData?.aantalOntkoppeld
    },
    hospitalDataTotal () {
      return this.hospitalData?.total
    },
    $_labelClasses () {
      const baseClass = 'a-selectfield__container'

      return {
        [baseClass]: true
      }
    },
    $_selectFieldClasses () {
      const baseClass = 'a-selectfield'
      const inputStates = {
        error: 'error',
        correct: 'correct'
      }
      const { color, size, isActive, isFullWidth } = this

      return {
        [baseClass]: true,
        [`${baseClass}--${size}`]: true,
        [`${baseClass}--${color}`]: true,
        [`${baseClass}--${inputStates[this.state]}`]: this.state,
        [`${baseClass}--active`]: isActive,
        [`${baseClass}--is-full-width`]: isFullWidth
      }
    }
  },
  methods: {
    fetchData () {
      this.isLoading = true

      Promise.all([
        SupplierService.getMetrics(this.user.gln),
        SupplierService.getHospitalData(this.user.gln)
      ])
        .then(values => {
          this.isLoading = false

          const metrics = values[0]
          const imports = values[1]

          // set data
          this.metrics = metrics.data.payload
          this.imports = imports.data.payload
          this.totalArticles = metrics.data.payload[0]
          this.hospitalData = imports.data.payload[0]

          // set chart data
          this.chartData = {
            clip: true,
            labels: ['In scope articles', 'Other articles'],
            datasets: [
              {
                backgroundColor: ['#002C6C', '#F26334'],
                data: [
                  this.totalArticles?.aantalInScope,
                  this.totalArticles?.aantalOutScope
                ]
              }
            ]
          }
          this.importChartData = {
            clip: true,
            labels: ['Connected providers', 'Unconnected providers'],
            datasets: [
              {
                backgroundColor: ['#F26334', '#00799E'],
                data: [
                  this.hospitalData?.aantalGekoppeld,
                  this.hospitalData?.aantalOntkoppeld
                ]
              }
            ]
          }
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="sass" scoped>
@use '../../../assets/sass/colors' as colors
@use '../../../assets/sass/mixins' as mixins
@use '../../../assets/sass/variables' as vars

.a-selectfield
  $self: &

  @include mixins.base-font
  @include mixins.font-size-little
  display: block
  margin-bottom: vars.$spacer-2

  &__iconState
    color: colors.$blue

  &__iconLeft
    color: colors.$blue
    transform: translateX(-#{vars.$spacer-1})
    margin-right: vars.$spacer-1
  &__iconRight
    color: colors.$blue
    transform: translateX(vars.$spacer-1)
    margin-left: vars.$spacer-1

  &__container
    display: block
    max-width: 100%
    margin-bottom: vars.$spacer-1

  &__label
    display: block

  &__field
    @include mixins.base-font
    display: flex
    align-items: center
    width: 100%
    border-radius: vars.$border-radius-default
    border: 1px solid colors.$ui-3
    background-color: colors.$white
    padding: 0 vars.$spacer-3
    color: colors.$ui-8

    select
      flex: 1
      padding: vars.$spacer-2 0
      border: none
      outline: 0
      color: colors.$ui-8
      &::placeholder
        color: colors.$ui-5

      &::disabled
        cursor: not-allowed

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none
      &[type="number"]
        -moz-appearance: textfield

      option
        line-height: 1.5

  &__msg
    @include mixins.font-size-tiny
    margin-top: vars.$spacer-1

  // states
  &--error
    #{$self}__field,
    #{$self}__msg,
    #{$self}__input
      border-color: colors.$danger
    #{$self}__msg,
    #{$self}__iconState
      color: colors.$danger

  &--correct
    #{$self}__field,
    #{$self}__msg,
    #{$self}__input
      border-color: colors.$forest-accessible
    #{$self}__msg,
    #{$self}__iconState
      color: colors.$forest-accessible

  &--active
    #{$self}__field,
    #{$self}__msg,
    #{$self}__input
      border-color: colors.$blue
    #{$self}__iconState
      color: colors.$blue

  &--disabled
    opacity: .5

  &--vertical
    #{$self}__label
      margin-bottom: vars.$spacer-1

  &--horizontal
    #{$self}__container
      display: flex
      justify-content: flex-start
      align-items: center
      width: 100%
    #{$self}__label
      margin-right: vars.$spacer-2
</style>
