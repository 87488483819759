<template>
  <CollapsibleGroup
    class="data-table"
    :badges="badges"
    :count="totalCount"
    :dataSet="dataSet"
    :title="title"
    :description="description"
    :requestLimit="requestLimit"
    :hasHitRequestLimit="hasHitRequestLimit"
    :isCollapible="isCollapible"
  >
    <LoaderWrapper ref="loader">
      <DataTable
        v-for="group in dataSet"
        :key="group.id"
        :action="dataAction"
        :columns="columns"
        :description="group.description"
        :title="
          $te(`dataquality.groupNames.${group.name}`)
            ? $t(`dataquality.groupNames.${group.name}`)
            : group.name
        "
        :isCollapible="true"
        :groupBy="group.id"
        :hasBottomPagination="hasBottomPagination"
        :hasTopPagination="hasTopPagination"
        :isCollapsed="true"
        :groupCount="$_groupCountStr(group.count)"
        :searchQuery="searchQuery"
        :limit="3"
      />
      <div v-if="!dataSet.length" class="data-table-no-results mt-2 mb-3">
        {{
          title
            ? $t("common.table.noResults", [title])
            : $t("common.searchSuggest.noResultsFound")
        }}
      </div>
    </LoaderWrapper>
  </CollapsibleGroup>
</template>

<script>
import CollapsibleGroup from '@/common/components/CollapsibleGroup'
import DataTable from '@/common/components/DataTable/DataTable'
import LoaderWrapper from '@/common/components/LoaderWrapper'
import TableMixin from '@/common/utils/mixins/table'

export default {
  mixins: [TableMixin],
  props: {
    badges: {
      default: () => [],
      type: Array
    },
    columns: {
      default: () => [],
      type: Array
    },
    dataAction: {
      required: true,
      type: String
    },
    groupAction: {
      required: true,
      type: String
    },
    hasBottomPagination: {
      default: false
    },
    hasTopPagination: {
      default: false
    },
    description: {
      default: null,
      type: String
    },
    isCollapible: {
      default: false
    },
    searchQuery: {
      default: '',
      type: String
    },
    title: {
      default: null,
      type: String
    }
  },

  components: {
    CollapsibleGroup,
    DataTable,
    LoaderWrapper
  },

  data () {
    return {
      dataSet: [],
      totalCount: 0
    }
  },

  mounted () {
    if (this.groupAction) {
      this.getDatasetFromAPI()
    }
  },

  methods: {
    getDatasetFromAPI () {
      const loader = {
        id: this.$refs.loader._uid,
        timeout: 0
      }

      this.$store
        .dispatch(this.groupAction, [this.searchQuery, loader])
        .then((response) => {
          this.dataSet = response.payload
          this.totalCount = response.payload.reduce(
            (total, item) => item.count + total,
            0
          )
          if (response.paging == null) {
            response.paging = {
              currentPage: 1,
              totalPages: 1,
              pageSize: 9999,
              totalCount: this.totalCount,
              startPage: 1,
              endPage: 1,
              startIndex: 0,
              endIndex: 0,
              requestLimit: 0,
              hasHitRequestLimit: false
            }
          }
          this.hasHitRequestLimit = response.paging.hasHitRequestLimit
          this.requestLimit = response.paging.requestLimit
          this.totalCount = response.payload.reduce(
            (total, item) => item.count + total,
            0
          )
        })
        .catch(() => {
          this.dataSet = []
          this.hasHitRequestLimit = false
          this.requestLimit = null
          this.totalCount = 0
        })
    },
    $_groupCountStr (count) {
      return count === 1
        ? this.$t('dataquality.oneArticle')
        : this.$t('dataquality.nArticles', [this.getCount(count)])
    }
  },

  watch: {
    dataSet: {
      handler: function () {
        this.$emit('change', {
          dataSet: this.dataSet,
          totalCount: this.totalCount
        })
      },
      deep: true
    },
    searchQuery: function () {
      this.getDatasetFromAPI()
    }
  }
}
</script>
