import axios from '@/common/utils/axios'

/**
 * @class LanguageService
 */
const LanguageService = {

  getAllLanguages: async () => {
    const url = '/Languages'

    try {
      const { data } = await axios.get(url)
      return { data }
    } catch (error) {
      return { error }
    }
  }
}

export default LanguageService
