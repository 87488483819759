<template>
    <div class="widget">
      <p v-if="label">{{label}}
         <span class="tooltip positon-relative" v-if="tooltip">
          <Icon name="info-circle"/>
            <span class="tooltiptext tooltip-bottom">
            {{ tooltip }}
            </span>
          </span>
      </p>

      <svg viewBox="0 0 36 36" class="circular-chart">
        <path class="circular-chart__outer"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path class="circular-chart__background"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path class="circular-chart__inner"
          :stroke-dasharray="`${percentage} 100`"
          :style="{'--color-iteration': colorIteration}"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="circular-chart__percentage">{{percentage}}%</text>
      </svg>
    </div>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  name: 'CircularChart',
  components: {
    Icon
  },
  props: {
    label: {
      type: String
    },
    tooltip: {
      type: String
    },
    percentage: {
      type: Number,
      validator: function (value) {
        return value >= 0 && value <= 100
      },
      default: 0
    }
  },
  computed: {
    colorIteration () {
      return this.percentage / 100
    }
  }
}
</script>
