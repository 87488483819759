import Module from '../Module'
import Verify from '../views/Verify'
import Login from '../views/Login'
import SessionExpired from '../views/SessionExpired'
import B2ctemplate from '../views/B2ctemplate'
import store from '@/store'
import types from '@/common/store/types'
import Vue from 'vue'
import { qs } from '@/common/utils/helpers'
import { STORAGE_KEY_LOCALE } from '@/i18n'

export default [
  {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'auth.login' },
    component: Module,
    children: [
      {
        path: '/auth/login',
        name: 'auth.login',
        component: Login,
        meta: {
          public: true
        }
      },
      {
        path: '/auth/gln-login',
        name: 'auth.gln-login',
        meta: {
          public: true
        },
        beforeEnter (to, from, next) {
          // RS: not neccecery?!
          // Vue.prototype.$b2c.resetState() // reset state as well
          // store.reset({ self: true, nested: true })
          // localStorage.clear() // MKR: important to clear all previous localstorage otherwise AB2C is nagging us
          localStorage.setItem('dataqualityOnly', true)
          if (
            Object.prototype.hasOwnProperty.call(qs, 'account_name') &&
            Object.prototype.hasOwnProperty.call(qs, 'glns') &&
            Object.prototype.hasOwnProperty.call(qs, 'signature')
          ) {
            const GLNs = qs.glns.split(',')
            store.commit(types.mutations.storeSupplierData, {
              accountName: qs.account_name,
              GLNs: GLNs,
              signature: qs.signature
            })

            var x = { account: { accountNumber: '0000', name: qs.account_name, cofcNumber: '0000', address: { street: '', city: '', postalCode: '', country: '', number: '', numberAddition: 'I' }, sbi: '', vatNumber: '', relationNumber: '', sectors: { sector: '', subSector: '' } }, isDraft: false }

            store.commit(
              types.mutations.storeAccountScope,
              x
            )
            store.commit(types.mutations.storeSupplierScope, GLNs[0])
          }

          if (Vue.prototype.$b2c.isLoggedIn()) {
            next({ name: 'dataquality.score' })
          } else {
            next('auth.login')
          }
        }
      },
      {
        path: '/auth/logout',
        name: 'auth.logout',
        beforeEnter (to, from, next) {
          Vue.prototype.$b2c.resetState()
          store.reset({ self: true, nested: true })
          localStorage.clear()
          Vue.prototype.$b2c.logout()
        }
      },
      {
        path: '/auth/verify',
        name: 'auth.verify',
        component: Verify,
        meta: {
          public: true
        }
      },
      {
        path: '/auth/b2ctemplate.html',
        name: 'auth.b2ctemplate',
        component: B2ctemplate,
        meta: {
          public: true,
          showSubNav: false
        }
      },
      {
        path: '/auth/forgotpassword',
        name: 'auth.forgotpassword',
        beforeEnter (to, from, next) {
          const forgotPasswordUrl = process.env.VUE_APP_FORGOT_PASSWORD_URL + '&ui_locales=' + localStorage.getItem(STORAGE_KEY_LOCALE)
          window.location.href = forgotPasswordUrl
        },
        meta: {
          public: true
        }
      },
      {
        path: '/auth/sessionExpired',
        name: 'auth.sessionExpired',
        component: SessionExpired,
        meta: {
          public: true,
          showSubNav: false
        }
      },
      {
        path: '/auth/azureb2csignup',
        name: 'auth.azureb2csignup',
        beforeEnter (to, from, next) {
          const signUpUrl = process.env.VUE_APP_AZUREB2C_SIGNUP_URL + '&ui_locales=' + localStorage.getItem(STORAGE_KEY_LOCALE)
          window.location.href = signUpUrl
        },
        meta: {
          public: true
        }
      }
    ]
  }
]
