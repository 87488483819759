<template>
  <MDropdown :label="$i18n.locale.toUpperCase()">
    <MDropdownItem
      v-for="locale in selectableLocales"
      :key="`localeselect-${locale}`"
      @click="setLocale(locale)"
      >{{ locale.toUpperCase() }}</MDropdownItem
    >
  </MDropdown>
</template>

<script>
import i18n, { STORAGE_KEY_LOCALE } from '@/i18n'
import { MDropdown, MDropdownItem } from '../../components/Molecules/MDropdown'

export default {
  name: 'LanguageSwitcher',
  components: {
    MDropdown,
    MDropdownItem
  },

  computed: {
    selectableLocales () {
      return Object.keys(i18n.messages).filter(
        (locale) => locale !== this.$i18n.locale
      )
    }
  },
  mounted () {
    if (this.$route.query.lang && this.$i18n.locale === 'nl') {
      this.setLocale('en')
    }
  },
  methods: {
    setLocale (locale) {
      i18n.locale = locale
      localStorage.setItem(STORAGE_KEY_LOCALE, locale)
    }
  }
}
</script>
