<template>
  <header class="navheader">
    <MGlobalNotification v-bind="globalWarning" />
    <div class="navheader-top">
      <div class="container">
        <div class="row">
          <div class="col">
            <portal-target name="header-top" multiple />
            <div>
              <nav class="navbar navbar-expand-lg navbar-light bg-white">
                <a
                  class="navbar-brand gs1-color-blue"
                  href="#"
                  @click.prevent="$router.push({ name: 'home.index' })"
                >
                  <h1>LOGO</h1>
                </a>
                <portal-target
                  class="portal-header"
                  name="header-center"
                  multiple
                />
              </nav>
            </div>
            <portal-target name="header-bottom" multiple />
          </div>
        </div>
      </div>
    </div>
    <MGlobalNotification v-bind="globalNotice" />
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MGlobalNotification from '@/components/Molecules/MGlobalNotification'

export default {
  name: 'DefaultHeader',
  components: {
    MGlobalNotification
  },

  computed: {
    ...mapGetters('subnavigation', ['isSubNavActive', 'isAccountNavActive']),

    globalWarning () {
      return {
        isClosable: false,
        message: this.$t('common.globalnotifications.global_message_emergency'),
        type: 'danger'
      }
    },

    globalNotice () {
      return {
        isClosable: true,
        message: this.$t('common.globalnotifications.global_message_notice'),
        type: 'info'
      }
    }
  },

  methods: {
    ...mapActions('subnavigation', ['setSubNavActive', 'setAccountNavActive']),

    toggleAccount () {
      const delay = this.isSubNavActive ? 350 : 0
      if (this.isSubNavActive) {
        this.setSubNavActive(false)
      }
      setTimeout(() => {
        this.setAccountNavActive(!this.isAccountNavActive)
      }, delay)
    },

    toggleSubnav () {
      const delay = this.isAccountNavActive ? 350 : 0
      if (this.isAccountNavActive) {
        this.setAccountNavActive(false)
      }
      setTimeout(() => {
        this.setSubNavActive(!this.isSubNavActive)
      }, delay)
    }
  }
}
</script>
<style lang="sass" scoped>
.portal-header
  display: flex
  align-items: center
  flex: 1

.account-btn-mob
  width: 40px
  height: 40px
</style>
