export function getFilteredSubObject (object, keys, joinArrayProperties = false) {
  return [...keys].sort().reduce((acc, key) => {
    if (Array.isArray(object[key]) && object[key].length) {
      const arr = joinArrayProperties
        ? [...object[key]].sort().join(',')
        : [...object[key]].sort()
      return { ...acc, [key]: arr }
    }
    if (object[key] || object[key] === 0) {
      return { ...acc, [key]: object[key] }
    }
    return acc
  }, {})
}

export function propertyNotEmpty (object, property) {
  if (Array.isArray(object[property])) {
    const out = object[property].length
    if (!out) {
      console.info('propertyNotEmpty', property, 'empty in', object)
    }
  }
  if (object[property] === 0) {
    console.info('propertyNotEmpty', property, '0', object)
    return true
  }
  return !!object[property]
}

export function updateObject (object, params) {
  const updateObj = {}
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      updateObj[key] = params[key]
    }
  }
  Object.assign(object, updateObj)
}
