import { render, staticRenderFns } from "./AIcon.pug?vue&type=template&id=13822db2&scoped=true&lang=pug&"
import script from "./AIcon.js?vue&type=script&lang=js&"
export * from "./AIcon.js?vue&type=script&lang=js&"
import style0 from "./AIcon.sass?vue&type=style&index=0&id=13822db2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13822db2",
  null
  
)

export default component.exports