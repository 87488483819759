/**
 * These classes handle the sorting for the DataTble component. It's
 * separated from the component to allow viewing/manipulating of data from
 * outside the DataTable component.
 */
import { firstBy } from 'thenby'

/**
 * Abstract-ish class that can be extended by others.
 */
class Sorting {
  options = [
    null,
    'asc',
    'desc'
  ]

  isSortable (columnLabel) {
    return !!columnLabel
  }
}

/**
 * Allows the sorting of a single column.
 */
class SortingSingle extends Sorting {
  activeSorting = { column: null, order: null }

  constructor (sort) {
    super()
    this.replaceSorting(sort)
  }

  /**
   * Check whether the given column is being sorted.
   *
   * @param string column
   */
  isActive (column) {
    return this.activeSorting.column === column && this.activeSorting.order
  }

  /**
   * Change the sorting order of the given column.
   *
   * @param string column
   */
  changeOrder (column) {
    if (this.activeSorting.column === column) {
      const index = this.options.findIndex(val => {
        return val === this.activeSorting.order
      })
      this.activeSorting.order = this.options[
        index < this.options.length - 1 ? index + 1 : 0
      ]
    } else {
      this.activeSorting.column = column
      this.activeSorting.order = this.options[1]
    }
  }

  /**
   * Get the sorting order of the given column.
   *
   * @param string column
   */
  getOrder (column) {
    return this.activeSorting.column === column
      ? this.activeSorting.order
      : null
  }

  /**
   * Sort a dataset based on the active sorting parameters.
   *
   * @param array data
   */
  sortData (data) {
    return this.activeSorting.order
      ? [...data].sort(firstBy(this.activeSorting.column, {
        ignoreCase: true,
        direction: this.activeSorting.order
      }))
      : data
  }

  /**
   * Replace any and all current sorting with the given data.
   *
   * @param object sort
   */
  replaceSorting (sort) {
    this.activeSorting = { column: sort.column, order: sort.order }
  }

  /**
   * Return a querystring containing column and order
   */
  getSortingQueryString () {
    return this.activeSorting.order ? this.activeSorting.column + ':' + this.activeSorting.order : ''
  }
}

/**
 * Allows the sorting of multiple columns.
 */
class SortingMultiple extends Sorting {
  activeSorting = []

  constructor (sort) {
    super()
    this.replaceSorting(sort)
  }

  /**
   * Return the index of the given column. If not found -1 is returned.
   *
   * @param string column
   */
  getColumnIndex (column) {
    return this.activeSorting.findIndex(item => {
      return item.column === column
    })
  }

  /**
   * Check whether the given column is being sorted.
   *
   * @param string column
   */
  isActive (column) {
    const sortIndex = this.getColumnIndex(column)
    return sortIndex > -1 && this.activeSorting[sortIndex].order
  }

  /**
   * Change the sorting order of the given column.
   *
   * @param string column
   */
  changeOrder (column) {
    const sortIndex = this.getColumnIndex(column)

    if (sortIndex > -1) {
      const index = this.options.findIndex(val => {
        return val === this.activeSorting[sortIndex].order
      })
      this.activeSorting[sortIndex].order = this.options[
        index < this.options.length - 1 ? index + 1 : 0
      ]
    } else {
      this.activeSorting.push({
        column: column,
        order: this.options[1]
      })
    }
  }

  /**
   * Get the sorting order of the given column.
   *
   * @param string column
   */
  getOrder (column) {
    const sortIndex = this.getColumnIndex(column)
    return sortIndex > -1 ? this.activeSorting[sortIndex].order : null
  }

  /**
   * Sort a dataset based on the active sorting parameters.
   *
   * @param array data
   */
  sortData (data) {
    let sortStack = null

    this.activeSorting.forEach((item, index) => {
      if (index === 0) {
        sortStack = firstBy(item.column, {
          ignoreCase: true,
          direction: item.order
        })
      } else {
        sortStack = sortStack.thenBy(item.column, {
          ignoreCase: true,
          direction: item.order
        })
      }
    })

    return (this.activeSorting.length > 0)
      ? [...data].sort(sortStack)
      : data
  }

  /**
   * Replace any and all current sorting with the given data.
   *
   * @param array sort
   */
  replaceSorting (sort) {
    this.activeSorting.splice(0, this.activeSorting.length)

    sort.forEach(sort => {
      this.activeSorting.push({ column: sort.column, order: sort.order })
    })
  }

  /**
   * Return a querystring containing columns and orders
   */
  getSortingQueryString () {
    const queryStringParts = []
    this.activeSorting.forEach(item => {
      if (item.order) {
        queryStringParts.push(item.column + ':' + item.order)
      }
    })

    return queryStringParts.join(',')
  }
}

export default {
  createInstance (type, sort) {
    if (type === 'single') {
      return new SortingSingle(sort)
    } else if (type === 'multiple') {
      return new SortingMultiple(sort)
    } else {
      return null
    }
  }
}
