<template>
  <CollapsibleGroup
    class="data-table"
    :badges="badges"
    :count="totalCount"
    :dataSet="dataSet"
    :title="title"
    :description="description"
    :requestLimit="requestLimit"
    :hasHitRequestLimit="hasHitRequestLimit"
    :isCollapible="isCollapible"
    :groupCount="groupCount"
    v-model="isCollapsedGroup"
  >
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <!-- Pagination -->
    <div class="data-table-pagination-top" v-if="hasTopPagination">
      <PaginationView v-model="pagination" />
    </div>

    <!-- Table -->
    <LoaderWrapper
      :active="!dataLoadedLocally"
      ref="loader"
      class="data-table-overflow"
    >
      <table class="data-table-content" v-if="dataSet.length">
        <thead>
          <tr>
            <template v-if="sortable">
              <DataTableSortingHeading
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :field="column.field"
                :label="column.label"
                v-model="sorting"
              />
            </template>
            <template v-else>
              <th
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                v-html="column.label"
              ></th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in getData" :key="rowIndex">
            <component
              v-bind:is="mapColumnComponent(column)"
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              :data="row"
              :field="hasProperty(column, 'field') ? column.field : null"
              :column="column"
            ></component>
          </tr>
        </tbody>
      </table>
      <div v-if="!dataSet.length" class="data-table-no-results">
        {{ $t("common.table.noResults", [title]) }}
      </div>
    </LoaderWrapper>

    <!-- Pagination -->
    <div class="data-table-pagination-bottom" v-if="hasBottomPagination">
      <PaginationView v-model="pagination" />
    </div>
  </CollapsibleGroup>
</template>

<script>
import CollapsibleGroup from '@/common/components/CollapsibleGroup'
import DataTableFilterAction from '@/common/components/DataTable/Filter/Action'
import DataTableFilterAllergen from '@/common/components/DataTable/Filter/Allergen'
import DataTableFilterArticleCode from '@/common/components/DataTable/Filter/ArticleCode'
import DataTableFilterDeadline from '@/common/components/DataTable/Filter/Deadline'
import DataTableFilterDate from '@/common/components/DataTable/Filter/Date'
import DataTableFilterMeasurements from '@/common/components/DataTable/Filter/Measurements'
import DataTableFilterText from '@/common/components/DataTable/Filter/Text'
import DataTableFilterUnitType from '@/common/components/DataTable/Filter/UnitType'
import DataTableFilterUrl from '@/common/components/DataTable/Filter/Url'
import DataTableFilterValidation from '@/common/components/DataTable/Filter/Validation'
import DataTableFilterInfo from '@/common/components/DataTable/Filter/Info'
import DataTableSortingHeading from '@/common/components/DataTable/Partials/TableHeading'
import LoaderWrapper from '@/common/components/LoaderWrapper'
import PaginationView from '@/common/components/DataTable/Partials/Pagination'
import Pagination from '@/common/components/DataTable/lib/Pagination'
import Sorting from '@/common/components/DataTable/lib/Sorting'
import TableMixin from '@/common/utils/mixins/table'

export default {
  mixins: [TableMixin],
  props: {
    badges: {
      default: () => [],
      type: Array
    },
    description: {
      default: null,
      type: String
    },
    groupBy: {
      default: null,
      type: String
    },
    groupCount: {
      default: null,
      type: String
    },
    isCollapible: {
      default: false
    },
    isCollapsed: {
      default: false
    },
    sortable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CollapsibleGroup,

    DataTableFilterAction,
    DataTableFilterAllergen,
    DataTableFilterArticleCode,
    DataTableFilterDate,
    DataTableFilterDeadline,
    DataTableFilterInfo,
    DataTableFilterMeasurements,
    DataTableFilterText,
    DataTableFilterUnitType,
    DataTableFilterValidation,
    DataTableFilterUrl,

    DataTableSortingHeading,
    LoaderWrapper,
    PaginationView
  },
  computed: {
    getData () {
      return this.dataSet
    }
  },
  data () {
    return {
      isCollapsedGroup: false,
      columnTypeMapping: {
        Action: 'DataTableFilterAction',
        Allergen: 'DataTableFilterAllergen',
        ArticleCode: 'DataTableFilterArticleCode',
        Date: 'DataTableFilterDate',
        Deadline: 'DataTableFilterDeadline',
        Info: 'DataTableFilterInfo',
        Measurements: 'DataTableFilterMeasurements',
        Text: 'DataTableFilterText',
        UnitType: 'DataTableFilterUnitType',
        Validation: 'DataTableFilterValidation',
        Url: 'DataTableFilterUrl'
      },
      dataSet: [],
      pagination: new Pagination(this.limit),
      sorting: Sorting.createInstance(this.sortType, this.sort),
      totalCount: 0
    }
  },

  created () {
    this.isCollapsedGroup = this.isCollapsed
  },

  mounted () {
    if (!this.action) {
      this.dataSet = this.data
    }
  },

  methods: {
    mapColumnComponent (column) {
      return this.hasProperty(column, 'type')
        ? this.columnTypeMapping[column.type]
        : this.columnTypeMapping.Text
    },
    getDatasetFromAPI () {
      if (!this.isCollapsedGroup && this.action) {
        const loader = {
          id: this.$refs.loader._uid,
          timeout: 0
        }

        this.$store
          .dispatch(this.action, [
            this.pagination.currentPage,
            this.pagination.pageLimit,
            this.sorting.getSortingQueryString(),
            this.searchQuery,
            this.groupBy,
            loader
          ])
          .then((response) => {
            this.dataSet = response.payload
            /* if (!response.paging) {
              response.paging = {
                currentPage: 1,
                totalPages: 1,
                pageSize: 1,
                totalCount: response.payload.length,
                startPage: 1,
                endPage: 1,
                startIndex: 0,
                endIndex: response.payload.length,
                requestLimit: response.payload.length,
                hasHitRequestLimit: true
              }
            } */

            this.totalCount = response.paging.totalCount
            this.hasHitRequestLimit = response.paging.hasHitRequestLimit
            this.requestLimit = response.paging.requestLimit
            this.pagination.update(
              response.paging.totalPages,
              response.paging.currentPage
            )
          })
          .catch(() => {
            this.dataSet = []
            this.totalCount = 0
            this.hasHitRequestLimit = false
            this.requestLimit = null
            this.pagination.update(0, 0)
          })
      }
    }
  }
}
</script>
