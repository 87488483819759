/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'BC': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Selderij" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#289F69" cx="16" cy="16" r="15"></circle>
              <path d="M19.5780857,9.22857143 C19.6638,9.02857143 19.6923714,8.77142857 19.6352286,8.54285714 C19.5780857,8.28571429 19.4066571,8.05714286 19.1495143,7.91428571 C18.9209428,7.77142857 18.6352286,7.74285714 18.3780857,7.8 C18.1495143,7.85714286 17.9495143,8 17.8352286,8.17142857 C17.8066571,8.17142857 17.7780857,8.14285714 17.7495143,8.14285714 C17.7780857,8.05714286 17.7780857,7.97142857 17.7780857,7.85714286 C17.7780857,7.37142857 17.4352286,7 16.9780857,6.88571429 C16.9495143,6.65714286 16.8352286,6.42857143 16.6638,6.28571429 C16.4638,6.08571429 16.2066571,6 15.9209428,6 C15.6352286,6 15.3780857,6.11428571 15.2066571,6.31428571 C15.0352286,6.48571429 14.9495143,6.68571429 14.9209428,6.94285714 C14.4638,7.05714286 14.1495143,7.45714286 14.1495143,7.94285714 C14.1495143,8 14.1495143,8.08571429 14.1780857,8.14285714 C14.1495143,8.14285714 14.0923714,8.14285714 14.0638,8.17142857 C13.9209428,7.97142857 13.7209428,7.85714286 13.5209428,7.8 C13.2638,7.74285714 12.9780857,7.77142857 12.7495143,7.91428571 C12.5209428,8.05714286 12.3495143,8.28571429 12.2638,8.54285714 C12.2066571,8.77142857 12.2352286,9 12.3209428,9.22857143 C11.9780857,9.54285714 11.8923714,10.0571429 12.1495143,10.4857143 C12.2066571,10.5714286 12.2923714,10.6571429 12.3780857,10.7428571 L12.3780857,21.9714286 C12.3780857,23.9428571 13.9780857,25.5428571 15.9495143,25.5428571 C16.5209428,25.5428571 17.0923714,25.4 17.6066571,25.1428571 C17.6066571,25.1428571 17.6066571,25.1428571 17.6066571,25.1428571 C18.7780857,24.5142857 19.5209428,23.3142857 19.5209428,21.9714286 L19.5209428,10.7428571 C19.6066571,10.6571429 19.6923714,10.5714286 19.7495143,10.4857143 C19.9780857,10.0571429 19.8923714,9.54285714 19.5780857,9.22857143 Z" id="Shape" fill="#239E66" fill-rule="nonzero"></path>
          </g>`
            }
})
