<template>
  <nav v-if="value.totalPages > 1">
    <ul class="pagination">

      <!-- Previous -->
      <li class="page-item">
        <a
          :class="[
            'page-link',
            {'disabled': !value.hasPrevious() }
          ]"
          @click="value.selectPrevious()"
        ><Icon name="arrow-left-short" /></a>
      </li>

      <!-- Page numbers -->
      <li
        class="page-item"
        v-for="pageNumber in value.pageCount()"
        :key="pageNumber"
      >
        <a
          :class="[
            'page-link',
            {'active': pageNumber === value.currentPage}
          ]"
          @click="value.selectPage(pageNumber);"
        >{{ pageNumber }}</a>
      </li>

      <!-- Previous -->
      <li class="page-item">
        <a
          :class="[
            'page-link',
            {'disabled': !value.hasNext()}
          ]"
          @click="value.selectNext()"
        ><Icon name="arrow-right-short" /></a>
      </li>

    </ul>
  </nav>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    value: {
      required: true
    }
  },
  components: {
    Icon
  },
  watch: {
    'value.currentPage': function () {
      this.$emit('update', this.value.currentPage)
    }
  }
}
</script>
