var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'btn',
    'btn-' + _vm.type,
    ( _obj = {}, _obj['is-size-' + _vm.size] = _vm.size, _obj ),
    { 'icon-only': _vm.isLoading || _vm.isEmpty },
    { 'is-icon-left': !_vm.isLoading && _vm.iconLeft },
    { 'is-icon-right': !_vm.isLoading && _vm.iconRight },
    { 'text-only': !_vm.iconLeft && !_vm.iconRight },
    { 'is-full-width': _vm.isFullWidth } ],attrs:{"type":_vm.isSubmit ? 'submit' : 'button',"disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.click.apply(null, arguments)}}},[(_vm.isLoading && _vm.iconLeft)?_c('span',{staticClass:"spinner-border spinner-border-sm mr-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),(!_vm.isLoading && _vm.iconLeft)?_c('Icon',{attrs:{"name":_vm.iconLeft}}):_vm._e(),(!_vm.isEmpty)?_c('span',[_vm._t("default")],2):_vm._e(),(!_vm.isLoading && _vm.iconRight)?_c('Icon',{attrs:{"name":_vm.iconRight}}):_vm._e(),(_vm.isLoading && !_vm.iconLeft)?_c('span',{staticClass:"spinner-border spinner-border-sm mr-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }