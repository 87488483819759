<template>
  <div class="dropdown">
    <Icon v-if="this.action" name="search" class="dropdown-search-icon" />
    <v-select :clearable="clearable" :value="value" :options="options" :reduce="sort => sort.value" :placeholder="placeholder" @input="event => $emit('input', event)" @option:selected="event => $emit('selected', event)">
      <template #open-indicator="{ attributes }">
        <Icon v-bind="attributes" name="chevron-down" />
      </template>
      <div slot="no-options">{{ $t('common.dropdown.noResults') }}</div>
    </v-select>
  </div>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'
import vSelect from 'vue-select'

export default {
  props: {
    action: {
      default: null,
      type: String
    },
    clearable: {
      default: true,
      type: Boolean
    },
    options: {
      default: () => [],
      type: Array
    },
    placeholder: {
      default: '- - Selecteer - -',
      type: String
    },
    label: {
      default: null
    },
    value: {
      default: null,
      type: String
    }
  },
  data () {
    return {
      attributes: {
        ref: 'openIndicator',
        role: 'presentation',
        class: 'vs__open-indicator'
      },
      currentInput: '',
      loading: false,
      values: []
    }
  },
  components: {
    Icon,
    vSelect
  }
}
</script>
