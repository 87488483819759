<template>
  <header class="navheader">
    <MGlobalNotification v-bind="globalWarning" />
    <div class="navheader-top">
      <div class="container">
        <div class="row">
          <div class="col">
            <portal-target name="header-top" multiple />
            <div>
              <nav class="navbar navbar-expand-lg navbar-light bg-white">
                <a
                  class="navbar-brand gs1-color-orange"
                  href="#"
                  @click.prevent="$router.push({ name: 'home.index' })"
                >
                  <Logo class="logo" />
                </a>
                <a
                  class="navbar-brand gs1-color-orange"
                  href="/actions"
                  @click.prevent="$router.push({ name: 'actions.index' })"
                >
                </a>
                <portal-target
                  class="portal-header"
                  name="header-center"
                  multiple
                />

                <!-- ACCOUNTMENU -->
                <AccountMenuDesktop class="d-none d-lg-block d-xl-block" />
                <AButton
                  size="sm"
                  type="icon"
                  class="nav-item position-relative d-lg-none"
                  @click="toggleAccount"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <AIcon
                      hasBg
                      icon="times2"
                      viewBox="0 0 24 24"
                      v-if="isAccountNavActive"
                    />
                    <AIcon hasBg icon="user-fill" v-else />
                  </div>
                </AButton>

                <!-- MENU -->
                <AButton
                  size="sm"
                  type="icon"
                  class="nav-item d-lg-none"
                  @click="toggleSubnav"
                >
                  <AIcon
                    :icon="isSubNavActive ? 'times2' : 'hamburger'"
                    size="lg"
                    viewBox="0 0 24 24"
                  />
                </AButton>
                <div class="headerWrap"></div>
              </nav>
            </div>
            <portal-target name="header-bottom" multiple />
          </div>
        </div>
      </div>
    </div>

    <!-- account menu - mobile -->
    <AccountMenuMobile v-if="isAccountNavActive" />

    <!-- subnavigation -->
    <SubNavigation id="subnavigation" v-if="showAccountMenu" />
    <MGlobalNotification v-bind="globalNotice" />
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MGlobalNotification from '@/components/Molecules/MGlobalNotification'
import SubNavigation from '@/common/components/SubNavigation/SubNavigation.vue'
import AccountMenuDesktop from '@/common/components/AccountMenu/AccountMenuDesktop.vue'
import AccountMenuMobile from '@/common/components/AccountMenu/AccountMenuMobile.vue'
import AIcon from '@/components/Atoms/AIcon'
import AButton from '@/components/Atoms/AButton'

export default {
  name: 'DefaultHeader',
  components: {
    MGlobalNotification,
    SubNavigation,
    AccountMenuDesktop,
    AccountMenuMobile,
    AIcon,
    AButton,
    Logo: () => import('@/assets/logos/gs1.svg?inline')
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('subnavigation', ['isSubNavActive', 'isAccountNavActive']),
    showAccountMenu () {
      var showSubNav = true
      if (this.$route.meta.showSubNav !== undefined) {
        showSubNav = this.$route.meta.showSubNav
      }

      if (showSubNav) {
        return true
      }
      return false
    },
    globalWarning () {
      return {
        isClosable: false,
        message: this.$t('common.globalnotifications.global_message_emergency'),
        type: 'danger'
      }
    },

    globalNotice () {
      return {
        isClosable: true,
        message: this.$t('common.globalnotifications.global_message_notice'),
        type: 'info'
      }
    }
  },
  methods: {
    ...mapActions('subnavigation', ['setSubNavActive', 'setAccountNavActive']),
    toggleAccount () {
      const delay = this.isSubNavActive ? 350 : 0
      if (this.isSubNavActive) {
        this.setSubNavActive(false)
      }
      setTimeout(() => {
        this.setAccountNavActive(!this.isAccountNavActive)
      }, delay)
    },
    toggleSubnav () {
      const delay = this.isAccountNavActive ? 350 : 0
      if (this.isAccountNavActive) {
        this.setAccountNavActive(false)
      }
      setTimeout(() => {
        this.setSubNavActive(!this.isSubNavActive)
      }, delay)
    }
  }
}
</script>
<style lang="sass" scoped>
.portal-header
  display: flex
  align-items: center
  flex: 1
</style>
