<template>
  <td v-if="moment().isAfter(moment(this.data[field]), 'day')">
    <div class="data-table-deadline-expired">
      <span>{{ moment(this.data[field]).format("L") }}</span>

      <span
        class="d-inline-flex"
        v-b-tooltip.hover.bottom
        :title="tooltipText"
      >
        <Icon name="exclamation-triangle" />
      </span>
    </div>
  </td>
  <td v-else>
    {{ moment(this.data[field]).format("L") }}
  </td>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'
import moment from 'moment'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      moment: moment
    }
  },
  computed: {
    tooltipText () {
      return (
        this.$t('common.table.filter.deadlineExpired1') +
        '<br>' +
        this.$t('common.table.filter.deadlineExpired2')
      )
    }
  },
  created () {
    this.moment.locale(this.$i18n.locale)
  }
}
</script>
