/**
 * This class handles the pagination for the Pagination component. It's
 * separated from the component to allow viewing/manipulating of data from
 * outside the Pagination component.
 */
class Pagination {
  totalPages = null
  currentPage = null
  pageLimit = 5

  /**
   * Class constructor.
   *
   * @param int totalPages
   * @param int currentPage
   * @param int|null pageLimit
   */
  constructor (pageLimit = null, totalPages = 1, currentPage = 1) {
    this.pageLimit = pageLimit || this.pageLimit
    this.update(
      totalPages,
      currentPage > 1 ? currentPage : 1
    )
  }

  /**
   * Update pagination values.
   *
   * @param int totalPages
   * @param int currentPage
   */
  update (totalPages = 1, currentPage = 1) {
    this.totalPages = totalPages
    this.currentPage = currentPage > 1 ? currentPage : 1
  }

  /**
   * Return the pages visible in pagination.
   */
  pageCount () {
    const amountPrev = this.currentPage > 2 ? 2 : (this.currentPage - 1)
    const absoluteAmountNext = this.totalPages - this.currentPage
    const amountNext = absoluteAmountNext > 2
      ? Math.min((4 - amountPrev), absoluteAmountNext)
      : absoluteAmountNext

    return Array.from(
      new Array(amountPrev + amountNext + 1),
      (val, index) => index + (this.currentPage - amountPrev)
    )
  }

  /**
   * Check whether there are previous pages, before the current page
   */
  hasPrevious () {
    return this.currentPage > 1
  }

  /**
   * Check whether there are more pages, after the current page.
   */
  hasNext () {
    return this.currentPage < this.totalPages
  }

  /**
   * Set the given page number as the current page nummber.
   *
   * @param int pageNumber
   */
  selectPage (pageNumber) {
    if (pageNumber !== this.currentPage) {
      this.currentPage = pageNumber
    }
  }

  /**
   * Select the next page, if available.
   */
  selectNext () {
    if (this.hasNext()) {
      this.currentPage = this.currentPage + 1
    }
  }

  /**
   * Select the previous page, if available.
   */
  selectPrevious () {
    if (this.hasPrevious()) {
      this.currentPage = this.currentPage - 1
    }
  }
}

export default Pagination
