import settings from './settings'

export default {
  authorities: {
    login: {
      authority: 'https://' + settings.b2cDomain +
        '/' + settings.msDomain + '/' + settings.userFlows.login
    },
    register: {
      authority: 'https://' + settings.b2cDomain +
        '/' + settings.msDomain + '/' + settings.userFlows.register
    },
    passwordReset: {
      authority: 'https://' + settings.b2cDomain +
        '/' + settings.msDomain + '/' + settings.userFlows.passwordReset
    } // ,
    // editProfile: {
    //   authority: 'https://' + settings.b2cDomain +
    //     '/' + settings.msDomain + '/???'
    // }
  },
  authorityDomain: settings.b2cDomain
}
