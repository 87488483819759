export const initialState = () => {
  return {
    screenWidth: null,
    screenHeight: null,
    smallScreenSize: null
  }
}

const state = () => initialState()

const getters = {
  getScreenWidth: state => state.screenWidth,
  getScreenHeight: state => state.screenHeight,
  isScreenSmall: state => state.screenWidth < state.smallScreenSize
}

const actions = {
  setScreenWidth ({ commit }, payload) {
    commit('SET_SCREEN_WIDTH', payload)
  },
  setScreenHeight ({ commit }, payload) {
    commit('SET_SCREEN_HEIGHT', payload)
  },

  setSmallScreenSize ({ commit }, payload) {
    commit('SET_SMALL_SCREEN_SIZE', payload)
  }
}

const mutations = {
  SET_SMALL_SCREEN_SIZE (state, payload) {
    state.smallScreenSize = payload
  },
  SET_SCREEN_WIDTH (state, payload) {
    state.screenWidth = payload
  },
  SET_SCREEN_HEIGHT (state, payload) {
    state.screenHeight = payload
  }
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
