<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="modal__dialog">
        <div class="modal__header">
          <div class="d-flex justify-content-between">
            <div class="">
              <slot name="header" />
            </div>
            <div class="">
              <button type="button" class="modal__close" @click="closeModal()">
                <AIcon icon="cross" viewBox="0 0 24 24" size="md" />
              </button>
            </div>
          </div>
        </div>

        <div class="modal__body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AIcon from '@/components/Atoms/AIcon'

export default {
  name: 'Modal',
  components: {
    AIcon
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    closeModal () {
      this.show = false
      document.querySelector('body').classList.remove('overflow-hidden')
      this.$emit('closeModal')
    },
    openModal () {
      this.show = true
      document.querySelector('body').classList.add('overflow-hidden')
    }
  }
}
</script>
