import axios from '@/common/utils/axios'
import types from './types'

export default {
  [types.actions.test]: ({ commit, dispatch }, [payload, loader]) => {
    return new Promise((resolve, reject) => {
      axios.get('/Test/secure')
        .then(function ({ data }) {
          resolve(data)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
}
