/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right-short': {
    width: 16,
    height: 16,
    viewBox: '0 0 6 5',
    data: '<path pid="0" d="M0 2.5c0-.2.2-.4.4-.4h4.3L3.1.6C3 .5 3 .2 3.1.1s.4-.1.5 0l2.2 2.1c.2.2.2.4.1.6L3.6 4.9c-.1.1-.3.1-.5 0-.1-.1-.1-.4 0-.5l1.6-1.5H.4c-.2 0-.4-.2-.4-.4z"/>'
  }
})
