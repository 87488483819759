export default {
  /**
   * Store authentication token.
   */
  // [types.mutations.storeCredentials]: (state, payload) => {
  //   state.token = payload.idToken
  //   state.type = 'Bearer'
  //   state.username = payload.account.username

  //   localStorage.setItem('access_token', state.token)
  //   localStorage.setItem('token_type', state.type)
  //   localStorage.setItem('username', state.username)
  // },

  /**
   * Delete authentication token.
   */
  // [types.mutations.removeCredentials]: (state) => {
  //   localStorage.removeItem('access_token')
  //   localStorage.removeItem('token_type')
  //   localStorage.removeItem('username')
  // }
}
