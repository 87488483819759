class ModelCollection {
  // Whether or not the collection is being loaded.
  isLoading = false

  // A collection of models.
  models = []

  // The model class.
  Model

  // Constructor, handles class injection and optionally appends data.
  constructor (Model) {
    this.Model = Model
  }

  // Getter returns all models.
  get all () {
    return this.models
  }

  // Adds data to a new model, then appends the model to the collection.
  append (o) {
    this.models.push(new this.Model(o))
  }

  // Allows an array of multiple objects to be added to the collection.
  appendMultiple (a) {
    a.forEach(o => {
      this.append(o)
    })
  }

  // Delete a model from the collection, by specifying the id.
  deleteModelById (id) {
    const index = this.models.findIndex(model => model.id === id)

    if (index > -1) {
      this.models.splice(index, 1)
    }
  }

  // Get a model from the collection, by specifying the id.
  getModelById (id) {
    return this.models.find(model => model.id === id)
  }

  // Remove existing models.
  empty () {
    this.models.splice(0, this.models.length)
  }

  // Removes all models, then adds multiple new models.
  replaceMultiple (a) {
    this.empty()
    this.appendMultiple(a)
  }

  // Removes all models, then adds a single new model.
  replace (o) {
    this.empty()
    this.append(o)
  }

  startLoading () {
    this.isLoading = true
  }

  stopLoading () {
    this.isLoading = false
  }
}

export default ModelCollection
