export const initialState = () => {
  return {
    isAccountNavActive: false,
    isSubNavActive: false,
    subnavItems: []
  }
}

const state = () => initialState()

const getters = {
  getAllSubnavItems: state => state.subnavItems,
  isSubNavActive: state => state.isSubNavActive,
  isAccountNavActive: state => state.isAccountNavActive
}

const actions = {
  setSubnavItems ({ commit }, payload) {
    commit('UPDATE_SUBNAV_ITEMS', payload)
  },
  setSubNavActive ({ commit }, payload) {
    commit('SET_SUBNAV_ACTIVE', payload)
  },
  setAccountNavActive ({ commit }, payload) {
    commit('SET_ACCOUNT_NAV_ACTIVE', payload)
  }
}

const mutations = {
  UPDATE_SUBNAV_ITEMS (state, payload) {
    state.subnavItems = payload
  },
  SET_SUBNAV_ACTIVE (state, payload) {
    state.isSubNavActive = payload
  },
  SET_ACCOUNT_NAV_ACTIVE (state, payload) {
    state.isAccountNavActive = payload
  }
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
