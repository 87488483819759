const b2cDomain = process.env.VUE_APP_B2C_B2C_DOMAIN
const clientId = process.env.VUE_APP_B2C_CLIENT_ID
const logoutUrl = '/auth/logout'
const msDomain = process.env.VUE_APP_B2C_MS_DOMAIN
const userFlows = {
  login: 'B2C_1A_login_custom_flow',
  // login: 'B2C_1_Test_signinsignup',
  passwordReset: 'B2C_1_password_reset_user_flow',
  register: 'B2C_1_registration_user_flow'
}
const claim = process.env.VUE_APP_B2C_CLAIM || 'Data.Read'
const webApiClientId = process.env.VUE_APP_B2C_SCOPE_CLIENT_ID

export default {
  b2cDomain,
  claim,
  clientId,
  logoutUrl,
  msDomain,
  userFlows,
  webApiClientId
}
