<template>
  <DefaultLayout>
    <router-view />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import TranslatorMixin, {
  translationModules
} from '@/common/utils/mixins/translator'

export default {
  name: 'ModuleAuth',

  mixins: [TranslatorMixin(translationModules.AUTH)],

  components: {
    DefaultLayout
  }
}
</script>

<style lang="sass">
@import 'assets/sass/module'
</style>
