<template>
  <div class="card p-2 h-100">
    <div class="row">
      <div class="col-12">
        <h5 class="card-title m-0">Voortgang datakwaliteit</h5>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading" class=" mt-3">
      <LineChart
        :chart-options="chartOptions"
        :chart-data="chartData"
        :height="250"
      />
    </div>

    <div class="row mt-3">
      <div class="col-11">
        <h5 class="card-title m-0">Voortgang datakwaliteit met benchmark artikelen in scope</h5>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading"  class="mt-3">
      <LineChart
        :chart-options="chartOptions"
        :chart-data="benchmarkData"
        :height="250"
      />
    </div>
  </div>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs/legacy'
import types from '@/common/store/types'

import HospitalService from '../../../services/HospitalService'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'lineChart',
  components: {
    LineChart
  },

  data () {
    return {
      isLoading: false,
      benchmark: [],
      progress: [],
      chartData: {},
      benchmarkData: {},
      chartOptions: {
        spanGaps: true,
        interaction: {
          mode: 'index'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    }
  },

  mounted () {
    this.fetchData()
  },

  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    },

    selectedSupplier () {
      return this.$store.getters[types.getters.selectedSupplier]
    }
  },

  watch: {
    selectedSupplier: function () {
      this.fetchData()
    }
  },

  methods: {
    fetchData () {
      this.isLoading = true

      Promise.all([
        HospitalService.getProgress(
          this.user.gln,
          this.selectedSupplier === 'Alle Leveranciers'
            ? null
            : this.selectedSupplier
        ),
        HospitalService.getBenchmark(
          this.user.gln,
          this.selectedSupplier === 'Alle Leveranciers'
            ? null
            : this.selectedSupplier
        )
      ]).then(values => {
        this.loading = false

        const progress = values[0]
        const benchmark = values[1]

        if ((progress.error && progress.error.response.status === 401) ||
          (benchmark.error && benchmark.error.response.status === 401)) {
          this.$router.push({ name: 'auth.login' })
        } else {
          // set data
          this.progress = progress.data.payload
          this.benchmark = benchmark.data.payload
        }

        var scoreAll = []
        var scoreInScope = []
        var labels = []
        this.progress.forEach(elem => {
          var date = new Date(elem.aggregationDateTime)

          scoreAll.push(elem.meanDqScore)
          scoreInScope.push(elem.meanDqScoreInScope)
          labels.push(
            date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
          )
        })

        var scoreMarketBenchmark = []
        var scoreFacilityBenchmark = []
        var scoreSupplierBenchmark = []
        var labelsBenchmark = []
        this.benchmark.forEach(elem => {
          var date = new Date(elem.aggregationDateTime)

          scoreMarketBenchmark.push(elem.market)
          scoreFacilityBenchmark.push(elem.facility)
          scoreSupplierBenchmark.push(elem.supplier)
          labelsBenchmark.push(
            date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
          )
        })
        // this is for range count to 100
        scoreAll.push(100)
        scoreAll.push(0)

        scoreMarketBenchmark.push(100)
        scoreMarketBenchmark.push(0)

        // set chart data
        this.chartData = {
          clip: true,
          labels: labels,
          datasets: [
            {
              label: ['Percentage in scope'],
              borderColor: '#002C6C',
              backgroundColor: '#002C6C',
              data: scoreInScope
            },
            {
              label: 'Percentage alle artikelen',
              borderColor: '#F26334',
              backgroundColor: '#F26334',
              data: scoreAll
            }
          ]
        }

        // set benchmarkchart data
        if (this.selectedSupplier !== 'Alle Leveranciers' && this.selectedSupplier !== null) {
          this.benchmarkData = {
            clip: true,
            labels: labelsBenchmark,
            datasets: [
              {
                label: 'Gemiddelde markt',
                borderColor: '#F26334',
                backgroundColor: '#F26334',
                data: scoreMarketBenchmark
              },
              {
                label: 'Geselecteerde leverancier',
                borderColor: '#002C6C',
                backgroundColor: '#002C6C',
                data: scoreSupplierBenchmark
              },
              {
                label: 'Assortiment ziekenhuis',
                borderColor: '#00799E',
                backgroundColor: '#00799E',
                data: scoreFacilityBenchmark
              }
            ]
          }
        } else {
          this.benchmarkData = {
            clip: true,
            labels: labelsBenchmark,
            datasets: [
              {
                label: 'Gemiddelde markt',
                borderColor: '#F26334',
                backgroundColor: '#F26334',
                data: scoreMarketBenchmark
              },
              {
                label: 'Assortiment ziekenhuis',
                borderColor: '#002C6C',
                backgroundColor: '#002C6C',
                data: scoreFacilityBenchmark
              }
            ]
          }
        }
      })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
