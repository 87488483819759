<template>
  <div class="card p-2 h-100">
    <div class="row">
      <div class="col-12">
        <h5 class="card-title m-0">
          Zorginstelling: {{ this.user.name }} [{{ this.user.gln }}]
        </h5>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <p style="font-size: 14px;">
          Data leverancier
        </p>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <div :class="$_selectFieldClasses">
                <label :class="$_labelClasses">
                  <div class="a-selectfield__field">
                    <select
                      v-model="selectedSupplier"
                      @change="onChange"
                      defaultValue="Alle Leveranciers"
                    >
                      <option
                        v-for="(item, index) in allSuppliers"
                        :key="index"
                        :value="item.gln"
                        class="dropdown-item"
                      >
                        {{ item.gln }} {{ item.name }}</option
                      >
                    </select>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-8">
          <div>
            <table class="table-bordered">
              <thead class="table-info">
                <th colspan="4">Artikelen</th>
                <th colspan="1">Aantal</th>
              </thead>

              <tbody>
                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Aantal artikelen in scope datakwaliteit</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.totalArticlesInScope
                    }}</small>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">
                    <small class="text-muted">Overige artikelen</small>
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.totalArticlesOutOfScope
                    }}</small>
                  </td>
                </tr>
              </tbody>

              <tfoot class="table-info">
                <tr>
                  <td colSpan="4">Totaal</td>
                  <td colspan="1" class="text-right">
                    {{ this.totalArticlesCombined }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="col-4">
          <Pie
            id="Artikel-Pie-Chart"
            :chartOptions="chartOptions"
            :chartData="chartData"
          >
          </Pie>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <p
            class="tooltiptext tooltip-top"
            v-b-tooltip.hover.top="'Deze functionaliteit is niet gerealiseerd'"
          >
            <button type="button" class="btn btn-outline-warning">
              <small class="text-muted"
                >Importeer eigen artikelassortiment</small
              >
            </button>
          </p>
        </div>

        <div class="col-6">
          <p
            class="tooltiptext tooltip-top"
            v-b-tooltip.hover.top="'Deze functionaliteit is niet gerealiseerd'"
          >
            <button type="button" class="btn btn-outline-info">
              <small class="text-muted">Download template</small>
            </button>
          </p>
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-8">
          <div>
            <table class="table-bordered">
              <thead class="table-info">
                <th colspan="4">Analyse import</th>
                <th colspan="1">Aantal</th>
              </thead>

              <tbody>
                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Aantal artikelen niet gematcht</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted text-right">{{
                      this.totalImportAantalNotMatched
                    }}</small>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Aantal artikelen wel gematcht - in scope</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.totalImportInScope
                    }}</small>
                  </td>
                </tr>

                <tr>
                  <td colspan="4">
                    <small class="text-muted"
                      >Aantal artikelen wel gematcht - out scope</small
                    >
                  </td>

                  <td colspan="1" class="text-right">
                    <small class="text-muted">{{
                      this.totalImportOutOfScope
                    }}</small>
                  </td>
                </tr>
              </tbody>

              <tfoot class="table-info">
                <tr>
                  <td colSpan="4">Totaal</td>
                  <td colspan="1" class="text-right">
                    {{ this.totalImportInScopeCombined }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="col-4">
          <Pie
            id="Analyse-Pie-Chart"
            :chartOptions="chartOptions"
            :chartData="importChartData"
          >
          </Pie>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import HospitalService from '../../../services/HospitalService'
import types from '@/common/store/types'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  ArcElement,
  CategoryScale
)

export default {
  name: 'Action',
  props: {},
  components: {
    Pie
  },
  data () {
    return {
      isLoading: false,
      metrics: [],
      imports: [],
      totalArticles: null,
      totalImport: null,
      query: null,
      suppliers: null,
      selectedSupplier: 'Alle Leveranciers',
      chartData: {},
      importChartData: {},
      chartOptions: {
        plugins: {
          legend: false,

          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0
              const dataArr = ctx.chart.data.datasets[0].data

              dataArr.map(data => {
                sum += data
              })

              const percentage = ((value * 100) / sum).toFixed(0) + '%'

              return percentage
            },
            font: {
              size: 16,
              weight: 'bold'
            },
            color: '#fff'
          }
        }
      }
    }
  },

  mounted () {
    this.$store.commit(types.mutations.removeSelectedSupplier)
    this.$store.commit(types.mutations.removeSelectedSupplierName)

    this.fetchData()
  },

  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    },

    totalArticlesInScope () {
      return this.totalArticles?.aantalInScope
    },

    totalArticlesOutOfScope () {
      return this.totalArticles?.aantalOutScope
    },

    totalArticlesCombined () {
      return this.totalArticles?.aantalTotaal
    },

    totalImportInScope () {
      return this.totalImport?.aantalInScope
    },

    totalImportAantalNotMatched () {
      return this.totalImport?.aantalNotMatched
    },

    totalImportOutOfScope () {
      return this.totalImport?.aantalOutScope
    },

    totalImportInScopeCombined () {
      return this.totalImport?.aantalLoaded
    },

    totalImportPercentageNotMatched () {
      return this.totalImport?.percentageNotMatched
    },

    totalImportPercentageInScope () {
      return this.totalImport?.percentageInScope
    },

    totalImportPercentageOutScope () {
      return this.totalImport?.percentageOutScope
    },

    allSuppliers () {
      return this.suppliers
    },

    $_labelClasses () {
      const baseClass = 'a-selectfield__container'

      return {
        [baseClass]: true
      }
    },

    $_selectFieldClasses () {
      const baseClass = 'a-selectfield'
      const inputStates = {
        error: 'error',
        correct: 'correct'
      }
      const { color, size, isActive, isFullWidth } = this

      return {
        [baseClass]: true,
        [`${baseClass}--${size}`]: true,
        [`${baseClass}--${color}`]: true,
        [`${baseClass}--${inputStates[this.state]}`]: this.state,
        [`${baseClass}--active`]: isActive,
        [`${baseClass}--is-full-width`]: isFullWidth
      }
    }
  },
  methods: {
    fetchData () {
      this.isLoading = true

      Promise.all([
        HospitalService.getMetrics(
          this.user.gln,
          this.selectedSupplier === 'Alle Leveranciers'
            ? null
            : this.selectedSupplier
        ),
        HospitalService.getImports(
          this.user.gln,
          this.selectedSupplier === 'Alle Leveranciers'
            ? null
            : this.selectedSupplier
        )
      ])
        .then(values => {
          this.loading = false

          const metrics = values[0]
          const imports = values[1]

          // set data
          this.metrics = metrics.data.payload.data
          this.imports = imports.data.payload

          if (this.suppliers === null || this.selectedSupplier === null) {
            this.suppliers = metrics.data.payload.suppliers

            if (this.selectedSupplier === 'Alle Leveranciers') {
              this.suppliers.find(elem => elem.gln === null).gln =
                'Alle Leveranciers'
            }
          }

          this.totalImport = this.imports.find(
            item => !item.name && !item.supplier
          )

          this.totalArticles = this.metrics.find(
            item => !item.name && !item.supplier
          )

          // set chart data
          this.chartData = {
            clip: true,
            labels: ['In scope artikelen', 'Overige artikelen'],
            datasets: [
              {
                backgroundColor: ['#002C6C', '#F26334'],
                data: [
                  this.totalArticles?.aantalInScope,
                  this.totalArticles?.aantalOutScope
                ]
              }
            ]
          }

          this.importChartData = {
            clip: true,
            labels: [
              'Niet gematcht',
              'Gematcht - in scope',
              'Gematcht - out scope'
            ],
            datasets: [
              {
                backgroundColor: ['#CD3C0D', '#F26334', '#002C6C'],
                data: [
                  this.totalImport?.percentageNotMatched,
                  this.totalImport?.percentageInScope,
                  this.totalImport?.percentageOutScope
                ]
              }
            ]
          }
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    },

    onChange (event) {
      this.selectedSupplier = event.target.value

      this.$store.commit(
        types.mutations.setSelectedSupplier,
        this.selectedSupplier
      )

      var supplierName = this.allSuppliers.find(
        supplier => supplier.gln === event.target.value
      )

      this.$store.commit(
        types.mutations.setSelectedSupplierName,
        supplierName?.name
      )

      this.fetchData()
    }
  }
}
</script>

<style lang="sass" scoped>
@use '../../../assets/sass/colors' as colors
@use '../../../assets/sass/mixins' as mixins
@use '../../../assets/sass/variables' as vars

.a-selectfield
  $self: &

  @include mixins.base-font
  @include mixins.font-size-little
  display: block
  margin-bottom: vars.$spacer-2

  &__iconState
    color: colors.$blue

  &__iconLeft
    color: colors.$blue
    transform: translateX(-#{vars.$spacer-1})
    margin-right: vars.$spacer-1
  &__iconRight
    color: colors.$blue
    transform: translateX(vars.$spacer-1)
    margin-left: vars.$spacer-1

  &__container
    display: block
    max-width: 100%
    margin-bottom: vars.$spacer-1

  &__label
    display: block

  &__field
    @include mixins.base-font
    display: flex
    align-items: center
    width: 100%
    border-radius: vars.$border-radius-default
    border: 1px solid colors.$ui-3
    background-color: colors.$white
    padding: 0 vars.$spacer-3
    color: colors.$ui-8

    select
      flex: 1
      padding: vars.$spacer-2 0
      border: none
      outline: 0
      color: colors.$ui-8
      &::placeholder
        color: colors.$ui-5

      &::disabled
        cursor: not-allowed

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none
      &[type="number"]
        -moz-appearance: textfield

      option
        line-height: 1.5

  &__msg
    @include mixins.font-size-tiny
    margin-top: vars.$spacer-1

  // states
  &--error
    #{$self}__field,
    #{$self}__msg,
    #{$self}__input
      border-color: colors.$danger
    #{$self}__msg,
    #{$self}__iconState
      color: colors.$danger

  &--correct
    #{$self}__field,
    #{$self}__msg,
    #{$self}__input
      border-color: colors.$forest-accessible
    #{$self}__msg,
    #{$self}__iconState
      color: colors.$forest-accessible

  &--active
    #{$self}__field,
    #{$self}__msg,
    #{$self}__input
      border-color: colors.$blue
    #{$self}__iconState
      color: colors.$blue

  &--disabled
    opacity: .5

  &--vertical
    #{$self}__label
      margin-bottom: vars.$spacer-1

  &--horizontal
    #{$self}__container
      display: flex
      justify-content: flex-start
      align-items: center
      width: 100%
    #{$self}__label
      margin-right: vars.$spacer-2
</style>
