import { Rights } from '../auth/Rights'
import { RoleName } from '../auth/RoleName'
import { Actions } from '../../modules/home/Actions'

export const Role = [
  {
    Name: RoleName.Admin,
    RoleRights: [Rights.BuyCodes, Rights.UpdateCodes, Rights.UpdateAccount, Rights.GetAccount, Rights.GetContacts, Rights.DataQuality, Rights.UpdateContacts, Rights.PersonalInfo, Rights.ProductInfo, Rights.PayInvoice, Rights.GetCodes, Actions.OrderCodesAction,
      Actions.ActivateCodesAction, Actions.RemainingCodesAction, Actions.DataSourceAction, Actions.EdiAction, Actions.PayInvoiceAction, Actions.GepirAction]
  },
  { Name: RoleName.DQSReadWrite, RoleRights: [Rights.GetAccount, Rights.PersonalInfo, Rights.DataQuality, Actions.DataSourceAction, Actions.EdiAction] },
  { Name: RoleName.EDIDocRead, RoleRights: [Rights.GetAccount, Rights.PersonalInfo, Actions.EdiAction] },
  { Name: RoleName.Financial, RoleRights: [Rights.GetAccount, Rights.PersonalInfo, Rights.PayInvoice, Actions.EdiAction, Actions.PayInvoiceAction] },
  { Name: RoleName.Standard, RoleRights: [Rights.GetAccount, Rights.PersonalInfo, Rights.UpdateCodes, Rights.GetCodes, Actions.ActivateCodesAction, Actions.RemainingCodesAction, Actions.EdiAction, Actions.GepirAction] }
]
