// import types from './types'

export default {
  // Check if authenticated
  // [types.getters.isLoggedIn]: state => !!state.token,

  // Get auth headers
  // [types.getters.authHeader]: state => {
  //   return state.token ? state.type + ' ' + state.token : ''
  // }
}
