/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path d="M13.1805 6.27098C13.1805 6.44174 13.1203 6.60245 12.9997 6.72299L7.54548 12.1772C7.42494 12.2978 7.25419 12.3681 7.08343 12.3681C6.92271 12.3681 6.75195 12.2978 6.63142 12.1772L2.99526 8.54107C2.87472 8.42053 2.81445 8.25982 2.81445 8.08906C2.81445 7.9183 2.87472 7.74754 2.99526 7.627L3.90932 6.72299C4.02986 6.60245 4.19057 6.53214 4.36133 6.53214C4.53209 6.53214 4.6928 6.60245 4.81334 6.72299L7.08343 8.99308L11.1816 4.90491C11.3022 4.78437 11.4629 4.71406 11.6336 4.71406C11.8044 4.71406 11.9651 4.78437 12.0857 4.90491L12.9997 5.80892C13.1203 5.92946 13.1805 6.10022 13.1805 6.27098ZM15.7118 7.89821C15.7118 3.63928 12.2564 0.183924 7.99749 0.183924C3.73856 0.183924 0.283203 3.63928 0.283203 7.89821C0.283203 12.1571 3.73856 15.6125 7.99749 15.6125C12.2564 15.6125 15.7118 12.1571 15.7118 7.89821Z" fill="#002C6C"/>'
  }
})
