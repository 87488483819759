/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav-icon-listing': {
    width: 50.037,
    height: 47.656,
    viewBox: '0 0 13.239 12.609',
    data: '<path pid="0" d="M2.674 0C1.192 0 0 .657 0 1.474v2.568c0 .816 1.192 1.473 2.674 1.473 1.481 0 2.674-.657 2.674-1.473V1.474C5.348.657 4.155 0 2.674 0zm3.922 1.028c-.205 0-.37.312-.37.699v.321c0 .388.165.7.37.7h6.273c.205 0 .37-.312.37-.7v-.321c0-.387-.165-.699-.37-.699zm-2.626.26l.685.682-2.37 2.403-1.578-1.44.656-.752.915.867zm2.481 2.328c-.152 0-.274.174-.274.39v.18c0 .215.122.389.274.389h4.65c.152 0 .274-.174.274-.39v-.179c0-.216-.122-.39-.274-.39zM2.674 7.094C1.192 7.094 0 7.752 0 8.568v2.568c0 .816 1.192 1.473 2.674 1.473 1.481 0 2.674-.657 2.674-1.473V8.568c0-.816-1.193-1.474-2.674-1.474zm3.922 1.028c-.205 0-.37.312-.37.7v.32c0 .388.165.7.37.7h6.273c.205 0 .37-.312.37-.7v-.32c0-.388-.165-.7-.37-.7zm-2.626.26l.685.683-2.37 2.402-1.578-1.44.656-.752.915.867zm2.481 2.328c-.152 0-.274.174-.274.39v.179c0 .216.122.39.274.39h4.65c.152 0 .274-.174.274-.39v-.18c0-.215-.122-.39-.274-.39z" _fill="currentColor"/>'
  }
})
