<template>
  <td>
    <div class="data-table-info">
      <span>{{ data[field].text }}</span>
      <div class="tooltip ml-2">
        <span class="tooltiptext tooltip-bottom text-left pl-1 pr-1" v-html="data[field].tooltip">
        </span>
        <Icon name="info-circle" />
      </div>
    </div>
  </td>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  components: {
    Icon
  }
}
</script>
