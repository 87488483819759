import Module from '../Module'
import Index from '../views/Index'
import Actions from '../views/Actions'
import ActionsSupplier from '../views/ActionsSupplier'
import NotFound from '../views/NotFound'

export default [
  {
    path: '/404',
    component: NotFound,
    meta: {
      public: true
    }
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      public: true
    }
  },
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'home.index' },
    component: Module,
    children: [
      {
        path: '/home/',
        name: 'home.index',
        component: Index,
        meta: {
          public: false
        }
      }
    ]
  },
  {
    path: '/Actions',
    name: 'Actions',
    redirect: { name: 'home.actions' },
    component: Module,
    children: [
      {
        path: '/Actions/',
        name: 'home.actions',
        component: Actions,
        meta: {
          public: false
        }
      }
    ]
  },
  {
    path: '/ActionsSupplier',
    name: 'Actions Supplier',
    redirect: { name: 'home.actionssupplier' },
    component: Module,
    children: [
      {
        path: '/ActionsSupplier/',
        name: 'home.actionssupplier',
        component: ActionsSupplier,
        meta: {
          public: false
        }
      }
    ]
  }
]
