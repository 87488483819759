<template>
  <MContentGroup isBordered isFullwidth hasShadow>
    <template #body-content>
      <div>
        <AHeading class="mb-4" size="h5">{{
          $t("common.contentGroupNewsLetter.title")
        }}</AHeading>
        <div class="pb-4">
          <p>{{ $t("common.contentGroupNewsLetter.subtitle") }}</p>
        </div>
        <AButton
          hasPadding
          size="md"
          type="outline"
          :isFullWidth="true"
          href="https://www.gs1.nl/nieuws-en-events/inschrijven-nieuwsbrief/"
          >{{ $t("common.contentGroupNewsLetter.button") }}</AButton
        >
      </div>
    </template>
  </MContentGroup>
</template>
<script>
import AHeading from '@/components/Atoms/AHeading'
import AButton from '@/components/Atoms/AButton'
import MContentGroup from '@/components/Molecules/MContentGroup'

export default {
  components: {
    AButton,
    AHeading,
    MContentGroup
  },
  data () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>
