<template>
  <div class="card p-2 h-100">
    <div class="row">
      <div class="col-12">
        <h5 class="card-title m-0">Data quality progress</h5>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading">
      <div class=" mt-3">
        <LineChart
          :chart-options="chartOptions"
          :chart-data="chartData"
          :height="250"
        />
      </div>

      <div class="row mt-3">
      <div class="col-11">
        <h5 class="card-title m-0">Data quality progress with benchmark articles in scope</h5>
      </div>
    </div>

      <div v-if="isLoading" class="loader">
        <span class="spinner-border"></span>
      </div>

      <div v-if="!isLoading">
        <div class=" mt-3">
        <LineChart
          :chart-options="chartOptions"
          :chart-data="benchmarkData"
          :height="250"
        />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import types from '@/common/store/types'
import { Line as LineChart } from 'vue-chartjs/legacy'

import SupplierService from '../../../services/SupplierService'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'lineChart',
  components: {
    LineChart
  },

  data () {
    return {
      isLoading: false,
      benchmark: [],
      progress: [],
      chartData: {},
      benchmarkData: {},
      chartOptions: {
        spanGaps: true,
        interaction: {
          mode: 'index'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    }
  },

  mounted () {
    this.fetchData()
  },

  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    }
  },

  methods: {
    fetchData () {
      this.isLoading = true

      Promise.all([
        SupplierService.getProgress(this.user.gln, null),
        SupplierService.getBenchmark(this.user.gln, null)])
        .then(values => {
          this.loading = false

          const progress = values[0]
          const benchmark = values[1]

          if ((progress.error && progress.error.response.status === 401) ||
          (benchmark.error && benchmark.error.response.status === 401)) {
            this.$router.push({ name: 'auth.login' })
          } else {
          // set data
            this.progress = progress.data.payload
            this.benchmark = benchmark.data.payload
          }

          var scoreAll = []
          var scoreInScope = []
          var labels = []
          this.progress.forEach(elem => {
            var date = new Date(elem.aggregationDateTime)

            scoreAll.push(elem.meanDqScore)
            scoreInScope.push(elem.meanDqScoreInScope)
            labels.push(
              date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
            )
          })

          var scoreMarketBenchmark = []
          var scoreSupplierBenchmark = []
          var labelsBenchmark = []
          this.benchmark.forEach(elem => {
            var date = new Date(elem.aggregationDateTime)

            scoreMarketBenchmark.push(elem.market)
            scoreSupplierBenchmark.push(elem.supplier)
            labelsBenchmark.push(
              date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
            )
          })

          // this is for range count to 100
          scoreAll.push(100)
          scoreAll.push(0)

          scoreMarketBenchmark.push(100)
          scoreMarketBenchmark.push(0)

          // set chart data
          this.chartData = {
            clip: true,
            labels: labels,
            datasets: [
              {
                label: ['Percentage in scope'],
                borderColor: '#002C6C',
                backgroundColor: '#002C6C',
                data: scoreInScope
              },
              {
                label: 'Percentage all articles',
                borderColor: '#F26334',
                backgroundColor: '#F26334',
                data: scoreAll
              }
            ]
          }

          this.benchmarkData = {
            clip: true,
            labels: labelsBenchmark,
            datasets: [
              {
                label: 'Dataquality supplier',
                borderColor: '#002C6C',
                backgroundColor: '#002C6C',
                data: scoreSupplierBenchmark
              },
              {
                label: 'Benchmark of total in scope (all suppliers)',
                borderColor: '#F26334',
                backgroundColor: '#F26334',
                data: scoreMarketBenchmark
              }
            ]
          }
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
