<template>
  <MContentGroup isBordered isFullwidth hasShadow>
    <template #body-content>
      <div>
        <AHeading class="mb-4" size="h5">{{
          $t("common.ContentGroupSocials.title")
        }}</AHeading>

        <div class="d-flex flex-row">
          <ALink
            :hasPadding="false"
            class="pr-2"
            href="https://www.linkedin.com/company/gs1-nederland/"
            target="_blank"
          >
            <AIcon icon="social-linkedin" viewBox="0 0 24 24" size="xl" />
          </ALink>

          <ALink
            :hasPadding="false"
            class="pr-2"
            href="https://twitter.com/GS1nederland"
            target="_blank"
          >
            <AIcon icon="social-twitter" viewBox="0 0 24 24" size="xl" />
          </ALink>

          <ALink
            :hasPadding="false"
            class="pr-2"
            href="https://www.facebook.com/GS1Nederland"
            target="_blank"
          >
            <AIcon icon="social-facebook" viewBox="0 0 24 24" size="xl" />
          </ALink>

          <ALink
            :hasPadding="false"
            class="pr-2"
            href="https://www.youtube.com/user/GS1Nederland"
            target="_blank"
          >
            <AIcon icon="social-youtube" viewBox="0 0 24 24" size="xl" />
          </ALink>
        </div>
      </div>
    </template>
  </MContentGroup>
</template>
<script>
import AHeading from '@/components/Atoms/AHeading'
import ALink from '@/components/Atoms/ALink'
import AIcon from '@/components/Atoms/AIcon'
import MContentGroup from '@/components/Molecules/MContentGroup'

export default {
  components: {
    ALink,
    AIcon,
    AHeading,
    MContentGroup
  },
  data () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>
