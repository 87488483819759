import { render, staticRenderFns } from "./AButton.pug?vue&type=template&id=a98868ce&scoped=true&lang=pug&"
import script from "./AButton.js?vue&type=script&lang=js&"
export * from "./AButton.js?vue&type=script&lang=js&"
import style0 from "./AButton.sass?vue&type=style&index=0&id=a98868ce&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a98868ce",
  null
  
)

export default component.exports