import { render, staticRenderFns } from "./MDropdown.pug?vue&type=template&id=8a9879fa&scoped=true&lang=pug&"
import script from "./MDropdown.js?vue&type=script&lang=js&"
export * from "./MDropdown.js?vue&type=script&lang=js&"
import style0 from "./MDropdown.sass?vue&type=style&index=0&id=8a9879fa&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a9879fa",
  null
  
)

export default component.exports