/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox': {
    width: 12,
    height: 10,
    viewBox: '0 0 12 10',
    data: '<path pid="0" _fill="#FFF" fill-rule="evenodd" d="M10.3.96a1.14 1.14 0 011.6 0c.46.44.46 1.1 0 1.47L5.94 9.66c-.46.44-1.15.44-1.6.07l-4-3.83a1.11 1.11 0 01-.07-1.55 1.14 1.14 0 011.6-.08l.08.08L5.1 7.37 10.3.96z"/>'
  }
})
