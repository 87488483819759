<template>
  <td><span class="data-table-tag" v-if="data[field]">{{ data[field] }}</span></td>
</template>

<script>
export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  }
}
</script>
