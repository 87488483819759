import settings from './settings'
import policies from './policies'

export default {
  auth: {
    clientId: settings.clientId,
    authority: policies.authorities.login.authority,
    knownAuthorities: [policies.authorityDomain],
    postLogoutRedirectUri: settings.logoutUrl
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  scopes: [
    `https://${settings.msDomain}/${settings.webApiClientId}/${settings.claim}`
  ]
}
