<template>
  <footer class="account-create-module__footer">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-end">
          <p class="mb-0">
            {{$t('footer.footerText')}}
            <strong>020 511 38 88</strong>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'DefaultFooter'
}
</script>
