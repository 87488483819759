/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'BM': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Mosterd" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#E38900" cx="16" cy="16" r="15"></circle>
              <path d="M18.421046,12.5771875 L18.421046,10.9375 C18.421046,10.4715097 18.0432862,10.09375 17.577296,10.09375 L17.2496397,10.09375 L16.6871397,7 L15.0924522,7 L14.5299522,10.09375 L14.202296,10.09375 C13.7363057,10.09375 13.358546,10.4715097 13.358546,10.9375 L13.358546,12.5771875 L11.1901085,18.8125 L11.1901085,18.835 C10.7671944,20.4624669 11.0571538,22.1930182 11.9874522,23.59375 L12.9268272,25 L18.8527647,25 L19.7921397,23.59375 C20.7228234,22.195917 21.015276,20.4682995 20.5965147,18.8420312 L18.421046,12.5771875 Z M14.202296,10.65625 L17.577296,10.65625 C17.7326261,10.65625 17.858546,10.7821699 17.858546,10.9375 L17.858546,12.34375 L13.921046,12.34375 L13.921046,10.9375 C13.921046,10.7821699 14.0469659,10.65625 14.202296,10.65625 Z" id="Shape" fill="#D78C2B" fill-rule="nonzero"></path>
          </g>`
  }
})
