<template >
  <div>
    <router-view />
    <Notifications />
  </div>
</template>

<script>
import ResizeMixin from '@/common/utils/mixins/resize'
import TranslatorMixin, {
  translationModules
} from '@/common/utils/mixins/translator'
import Notifications from '@/common/components/Alert/Notifications'
import 'bootstrap'
export default {
  components: {
    Notifications
  },

  mixins: [TranslatorMixin(translationModules.COMMON), ResizeMixin]
}
</script>

<style lang="sass">
@import './common/assets/sass/bootstrap'
@import './common/assets/sass/app'
</style>
