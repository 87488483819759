<template>
  <td>
    <a v-if="detailsCanBeFetched" href="#" @click.prevent="showArticleDetail">{{
      data[field]
    }}</a>
    <template v-else>{{ data[field] }}</template>
  </td>
</template>

<script>
import { bus } from '../../../../eventBus'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true,
      type: String
    }
  },
  computed: {
    detailsCanBeFetched () {
      return !!this.data.id || this.data.gtin
    }
  },
  methods: {
    showArticleDetail () {
      // TEMP TEMP REDMAR
      /*       if (this.data.gtin) {
        this.data.id = this.data.gtin
      } */

      bus.$emit('showArticleDetail', this.data)
    }
  }
}
</script>
