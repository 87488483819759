/**
 * Return module information
 */
export default {
  /**
   * Return a list of all store file paths
   */
  stores () {
    return this.getModulePaths(require.context(
      '@/modules',
      true,
      /store\/index\.js$/
    ).keys())
  },

  /**
   * Return a list of all route file paths
   */
  routes () {
    return this.getModulePaths(require.context(
      '@/modules',
      true,
      /router\/index\.js$/
    ).keys())
  },

  /**
   * Return a list of all translation file paths
   */
  translations () {
    return this.getModulePaths(require.context(
      '@/modules',
      true,
      /translations\/(.*)\.js$/
    ).keys())
  },

  /**
   * Return a list of all module paths
   */
  getModulePaths (paths) {
    let modulePaths = []

    paths.forEach(path => {
      modulePaths = modulePaths.concat(this.getModulePath(path))
    })

    return modulePaths
  },

  /**
   * Return the path to a module
   */
  getModulePath (path) {
    const fileSeparator = '.'
    const file = path.replace(new RegExp('.*/(.*?)$'), '$1')
    const fileParts = file.split(fileSeparator)
    const moduleName = path
      .replace(new RegExp('./(.*?)/.*'), '$1')

    return {
      module: moduleName,
      path: 'modules/' + path.replace('./', ''),
      file: file,
      ext: fileParts.pop(),
      fileName: fileParts.join(fileSeparator)
    }
  }
}
