/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'AE': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Ei" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#F79429" cx="16" cy="16" r="15"></circle>
              <path d="M15.6785714,8 C19.0178571,8 22.3571429,14.041375 22.3571429,18.6250001 C22.3571429,22.1458153 19.3670445,25 15.6785714,25 C11.9900983,25 9,22.1458153 9,18.6250001 C9,14.041375 12.3392857,8 15.6785714,8 Z M15.9214286,14.6785715 C13.9095341,14.6785715 12.2785714,16.3095342 12.2785714,18.3214287 C12.2785714,20.3333231 13.9095341,21.9642858 15.9214286,21.9642858 C17.933323,21.9642858 19.5642857,20.3333231 19.5642857,18.3214287 C19.5642857,16.3095342 17.933323,14.6785715 15.9214286,14.6785715 Z" id="Combined-Shape" fill="#F8952B" fill-rule="nonzero"></path>
          </g>`
  }
})
