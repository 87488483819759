import { loadModuleTranslations, moduleTranslationsLoaded } from '@/i18n'

export const translationModules = Object.freeze({
  AUTH: 'auth',
  COMMON: 'common',
  DATAQUALITY: 'dataquality'
})

const translationModuleValues = Object
  .entries(translationModules)
  .map(([, value]) => value)

export default (module) => {
  if (!module || !translationModuleValues.includes(module)) {
    throw new Error(`Translator mixin: Invalid module '${module}'. Must be one of ${translationModuleValues.join(', ')}`)
  }

  return {
    data () {
      return {
        translationsLoading: false
      }
    },
    watch: {
      '$i18n.locale': {
        immediate: true,
        handler (locale) {
          if (!moduleTranslationsLoaded(module, locale)) {
            this.translationsLoading = true
            loadModuleTranslations(module, locale).then(
              this.translationsLoading = false
            )
          }
        }
      }
    }
  }
}
