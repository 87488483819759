export default {
  props: {
    action: {
      default: null,
      type: String
    },
    columns: {
      default: () => [],
      type: Array
    },
    data: {
      type: Array,
      default: null
    },
    hasBottomPagination: {
      type: Boolean,
      default: false
    },
    hasTopPagination: {
      type: Boolean,
      default: false
    },
    limit: {
      default: 5,
      type: Number
    },
    searchQuery: {
      default: '',
      type: String
    },
    sort: {
      default: () => [],
      type: Array,
      validator: function (value) {
        let bool = true
        value.forEach(item => {
          if (!(
            Object.prototype.hasOwnProperty.call(item, 'column') &&
            Object.prototype.hasOwnProperty.call(item, 'order')
          )) {
            bool = false
          }
        })
        return bool
      }
    },
    sortType: {
      default: 'multiple',
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ['single', 'multiple'].indexOf(value) !== -1
      }
    },
    title: {
      default: null,
      type: String
    }
  },

  computed: {
    dataLoadedLocally () {
      return this.data !== null
    }
  },

  methods: {
    mapColumnComponent (column) {
      return this.hasProperty(column, 'type')
        ? this.columnTypeMapping[column.type]
        : this.columnTypeMapping.Text
    },
    getCount (count) {
      const total = count >= this.requestLimit && this.hasHitRequestLimit
        ? this.requestLimit + '+'
        : count
      return total || null
    }
  },

  data () {
    return {
      hasHitRequestLimit: false,
      requestLimit: null
    }
  },

  watch: {
    'pagination.currentPage': {
      handler: function () {
        this.getDatasetFromAPI()
      },
      deep: true
    },
    'sorting.activeSorting': {
      handler: function () {
        this.getDatasetFromAPI()
      },
      deep: true
    },
    isCollapsedGroup: function () {
      this.getDatasetFromAPI()
    },
    sort: function () {
      this.sorting.replaceSorting(this.sort)
    },
    searchQuery: function () {
      this.getDatasetFromAPI()
    },
    dataSet: {
      handler: function () {
        this.$emit('change', {
          dataSet: this.dataSet,
          hasHitRequestLimit: this.hasHitRequestLimit,
          requestLimit: this.requestLimit,
          totalCount: this.totalCount
        })
      },
      deep: true
    },
    '$i18n.locale': function () {
      this.getDatasetFromAPI()
    },
    data: function () {
      if (!this.action) {
        this.dataSet = this.data
      }
    }
  },

  mounted () {
    if (this.action) {
      this.getDatasetFromAPI()
    }
  }
}
