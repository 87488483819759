import { render, staticRenderFns } from "./AHeading.pug?vue&type=template&id=72f87672&scoped=true&lang=pug&"
import script from "./AHeading.js?vue&type=script&lang=js&"
export * from "./AHeading.js?vue&type=script&lang=js&"
import style0 from "./AHeading.sass?vue&type=style&index=0&id=72f87672&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f87672",
  null
  
)

export default component.exports