<template>
  <!-- Desktop -->
  <div class="pageSubnav" v-if="!isScreenSmall">
    <div class="container">
      <router-link
        v-for="(item, index) in links"
        :key="`PAGE_SUBNAV_${index}`"
        class="pageSubnav-item mr-2 px-3 text-nowrap"
        :to="item.route"
        >{{ $t(item.i18nKey ? item.i18nKey : item.label) }}</router-link
      >
    </div>
  </div>
  <!-- Mobile -->
  <div v-else>
    <div class="container" v-if="activeLink">
      <b-dropdown
        id="dropdown-left"
        :text="activeLink.label"
        class="mobile-subnav-menu"
      >
        <b-dropdown-item
          v-for="(item, index) in links"
          :key="`PAGE_SUBNAV_MOBILE_${index}`"
          :class="{
            'mobile-subnav-menu-active':
              activeLink.route.path === item.route.path,
          }"
        >
          <router-link class="mobile-subnav-menu-item px-2" :to="item.route">{{
            $t(item.i18nKey ? item.i18nKey : item.label)
          }}</router-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('app', ['isScreenSmall']),
    activeLink () {
      if (this.links) {
        return this.links.find((link) => link.route.path === this.$route.path)
      }
      return null
    }
  }
}
</script>
<style src="./PageSubnav.sass" lang="sass" scoped />
