import axios from 'axios'
import store from '../../store'
import types from '../store/types'
import Vue from 'vue'
import { STORAGE_KEY_LOCALE } from '@/i18n'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

// Request interceptor
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('token'))
  config.headers['Accept-Language'] = getIsoLang()
  config.headers['X-Supplier-Scope'] = store.getters[types.getters.supplierScope]

  return config
}, function (error) {
  return Promise.reject(error)
})

// Progress
axios.interceptors.request.use(config => {
  if (
    Object.prototype.hasOwnProperty.call(config, 'loader') &&
    config.loader
  ) {
    store.commit(types.mutations.loaderStart, config.loader)
  }

  return config
})

const responseFunc = response => {
  if (
    Object.prototype.hasOwnProperty.call(response.config, 'loader') &&
    response.config.loader
  ) {
    store.commit(types.mutations.loaderStop, response.config.loader)
  }

  return Promise.resolve(response)
}

const errorFunc = error => {
  // Access denied (dataquality no access?)
  if (error.response.status === 403) {
    store.commit(types.mutations.updateHas403error, true)
    return
  }

  // No authorization (token not valid?!)
  if (error.response.status === 401) {
    // If local is stil logged in, then logout. session is exired
    if (Vue.prototype.$b2c.isLoggedIn()) {
      Vue.prototype.$b2c.logout()
    }
  }

  if (error?.config?.skipErrorNotification !== true) {
    // store.commit(types.mutations.addNotification, ['API Error: ' + error.message, 'danger', 5000])
  }

  if (error?.response?.status === 429) {
    // store.commit(types.mutations.addNotification, [i18n.t('api.error.429'), 'warning', 5000])
  }

  if (
    Object.prototype.hasOwnProperty.call(error.config, 'loader') &&
    error.config.loader
  ) {
    store.commit(types.mutations.loaderStop, error.config.loader)
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(responseFunc, errorFunc)

function getIsoLang () {
  var storeLang = localStorage.getItem(STORAGE_KEY_LOCALE)
  if (storeLang === 'en') {
    return 'en-US'
  }
  return 'nl-NL'
}

export default axios
