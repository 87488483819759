/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav-icon-dms': {
    width: 48.145,
    height: 48.145,
    viewBox: '0 0 12.738 12.738',
    data: '<path pid="0" d="M.61 0C.273 0 0 .315 0 .706v11.325c0 .392.272.707.61.707h4.133v-.795H1.55v-.834h3.193v-.75H3.212v-.835h1.53v-.712H1.55v-.835h3.193v-.77H3.23v-.834h1.512v-.731H1.569v-.835h3.174v-3.27h.834v3.27h.75v-1.6h.835v1.6h.75v-3.27h.835v3.27h.77V3.199h.835v1.608h.75v-3.27h.835v3.27h.801v-4.1c0-.392-.273-.707-.611-.707z" _fill="currentColor"/>'
  }
})
