import Vue from 'vue'

Vue.filter('toCurrency', function (value) {
  const val = (value / 1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('toCurrencyInclVat', function (value) {
  var result = parseInt(value) * 1.21

  const val = (result / 1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('toCurrencyVatAmount', function (value) {
  var inclVat = parseInt(value) * 1.21
  var result = inclVat - value

  const val = (result / 1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('toUsCurrencyInclVat', function (value) {
  var result = parseInt(value) * 1.21

  const val = (result / 1).toFixed(2)
  return val.toString()
})

Vue.filter('currencySymbol', function (value) {
  switch (value) {
    case 'EUR':
      return '€'
  }
})

Vue.filter('toVat', function (value) {
  return (value * 100)
})

Vue.filter('getVat21Amount', function (value) {
  return 0.21 * parseInt(value)
})

Vue.filter('getExclToInclVat', function (value) {
  return (0.21 * parseInt(value)) + value
})

/** ToDo: fix with webpack config!! */
/* Vue.filter('image', function (value) {
  var images = require.context('@/assets/images/', false, /\.(png|jpe?g|svg)$/)
  if (images.keys().find(item => item === './' + value)) {
    return images('./' + value)
  } else {
    return images('./image_notfound.jpg')
  }
}) */

/* Vue.filter('imagePNG', function (value) {
  var images = require.context('@/assets/', false, /\.png$/)
  return images('./consumenteneenheid.png')
}) */
/*
Vue.filter('toLowerCase', function (value) {
  return value.toLowerCase()
}) */
