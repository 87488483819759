<template>
  <th
    @click="value.changeOrder(field)"
    :class="{'is-active-column': value.isActive(field)}"
  >
    <span v-html="label"></span>
    <Icon
      v-if="value.isSortable(label)"
      :name="icon(value.getOrder(field))"
    />
  </th>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    field: {
      required: true
    },
    label: {
      required: true
    },
    value: {
      required: true
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      iconMapping: {
        asc: 'arrow-down',
        desc: 'arrow-up',
        null: 'arrow-down-up'
      }
    }
  },
  methods: {
    icon (order) {
      return this.iconMapping[order]
    }
  }
}
</script>
