<template>
  <div class="px-2 col">
    <div
      class="card py-3 d-flex justify-content-between"
      style="width: 268px; height: 380px"
    >
      <div>
        <div class="card-header border-none bg-white border-0">
          <h5 class="card-title p-0 m-0">
            {{ titleText }}
          </h5>
        </div>
        <div class="card-body pt-0">
          <p class="card-text">
            <small>{{ descriptionText }}</small>
          </p>
        </div>
      </div>
      <div>

        <div class="card-footer bg-transparent border-top-0">
          <AButton
            v-if="showButton"
            color="orange-accessible"
            type="solid"
            isFullWidth
            @click="determineClickAction()"
          >
            {{ buttonText }}
          </AButton>
          <div v-else class="bg-transparent border-top-0 mb-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthenticationService from '@/services/AuthenticationService'

import AButton from '@/components/Atoms/AButton'
import { bus } from '@/eventBus'
import { Actions } from '../../home/Actions'

export default {
  name: 'Action',
  // Infer from action name: Title, Description, Icon and Buton action
  props: {
    actionName: {
      type: String
    },

    alternateDescription: {
      default: false,
      type: Boolean
    }
  },
  components: {
    AButton
  },
  data () {
    return {
      Actions: Actions
    }
  },
  computed: {
    customStyling () {
      return 'min-height: 30px;max-height: 30px;  z-index: 1; margin-top: 25px'
    },
    titleText () {
      var key = `home.actionCards.${this.actionName}.title`
      var translation = this.$t(key)
      return translation === key ? null : translation
    },
    descriptionText () {
      var key = `home.actionCards.${this.actionName}.description`
      if (this.alternateDescription) {
        key = `home.actionCards.${this.actionName}.alternateDescription`
      }
      var translation = this.$t(key)
      return translation === key ? null : translation
    },
    buttonText () {
      var key = `home.actionCards.${this.actionName}.buttonText`
      var translation = this.$t(key)
      return translation === key ? null : translation
    },
    buttonUrl () {
      var key = `home.actionCards.${this.actionName}.buttonUrl`
      var translation = this.$t(key)
      return translation === key ? null : translation
    },
    icon () {
      var key = `home.actionCards.${this.actionName}.icon`
      var translation = this.$t(key)
      return translation === key ? null : translation
    },
    showButton () {
      return AuthenticationService.can(this.actionName).isAuthorized && this.buttonText !== null && this.buttonText !== ''
    },
    showImage () {
      return this.icon !== null && this.icon !== ''
    }
  },
  methods: {
    determineClickAction () {
      var actions = this.Actions

      Object.values(actions).forEach((action) => {
        if (action === this.actionName) {
          if (action === 'orderCodesAction') {
            // edge case
            this.openProductsSlider()
          } else if (action === 'ediAction') {
            // edge case
            window.open(this.buttonUrl)
          } else {
            this.openRouter()
          }
          return true
        }
      })
    },
    openRouter () {
      this.$router.push({ name: this.buttonUrl })
    },
    openProductsSlider () {
      bus.$emit('showProductSlider')
    }
  }
}
</script>

<style scoped>
.circle {
  height: 75px;
  width: 75px;
  background-color: #E5F0FC;
  border-radius: 50%;
  display: inline-block;
  z-index: 0;
  position:absolute;
  margin-bottom: 60px;
}

</style>
