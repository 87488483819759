import types from './types'

/** Deprecated, use /store/modules instead! */
export default {
  [types.getters.hiddenAlerts]: state => state.hiddenAlerts,
  [types.getters.loaders]: state => payload => {
    return state.loaders.find(loader => loader.id === parseInt(payload))
  },
  [types.getters.notifications]: state => state.notifications,
  [types.getters.has403error]: state => state.has403error,
  [types.getters.supplierScope]: state => state.supplierScope,
  [types.getters.accountScope]: state => state.accountScope,
  [types.getters.accountNumber]: state => {
    if (state.accountScope && state.accountScope.account) {
      return state.accountScope.account.accountNumber
    }
    return null
  },
  [types.getters.supplier]: state => state.supplier,
  [types.getters.contact]: state => state.contact,
  [types.getters.scaleCategoryId]: state => state.scaleCategoryId,
  [types.getters.selectedSupplier]: state => state.selectedSupplier,
  [types.getters.selectedSupplierName]: state => state.selectedSupplierName,
  [types.getters.loggedInUser]: state => state.loggedInUser
}
