<template>
  <div>
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-8">
          <MContentGroup isFullwidth isBordered v-if="isLoading">
            <template #body-content>
              <div class="text-center">
                <div class="spinner-border gs1-color-blue" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </template>
          </MContentGroup>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MContentGroup from '@/components/Molecules/MContentGroup'
/* import axios from '@/common/utils/axios' */

export default {
  components: { MContentGroup },

  computed: {},

  data () {
    return {
      isLoading: true
    }
  },

  created () {},

  mounted () {
    if (!this.$b2c.isLoggedIn()) {
      this.$b2c.login()
    }

    setTimeout(1000)
    var inviteId = localStorage.getItem('invite')
    if (inviteId) {
      this.$router.push({ name: 'account.invite' })
    }
  },

  methods: {},

  watch: {
    '$b2c.state.isLoggedIn': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.$b2c.isLoggedIn()) {
          if (localStorage.getItem('newUserBestelFlowInternational')) {
            this.$router.push({ name: 'account.verifyInternational' })
          } else {
            this.$router.push({ name: 'account.verify' })
          }
        }
      }
    }
  }
}
</script>
