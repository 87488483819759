<template>
  <div class="card p-2 h-100">
    <div class="row d-flex flex-row">
      <div class="col-5">
        <h5 class="card-title mt-1">
          System checks
        </h5>
      </div>

      <div class="col-3">
        <a href="/actionssupplier" class="fourteen-text ml-2">
          open actions
        </a>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
          />
        </svg>
      </div>

      <div class="col-4">
        <div class="btn-group float-right">
          <p
            class="tooltiptext tooltip-top"
            v-b-tooltip.hover.top='"You can request an export file via Healthcare@gs1.nl"'
          >
          <button
            type="button"
            class="btn btn-warning dropdown-toggle btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Export
          </button>
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <input
          type="checkbox"
          id="checkbox"
          v-model="inScope"
          @change="onCheck($event)"
        />

        <small class="text-muted ml-2">
          Show items in scope only
        </small>
      </div>
    </div>

    <div v-if="isLoading" class="loader">
      <span class="spinner-border"></span>
    </div>

    <div v-if="!isLoading" class="row mt-3 mb-3">
      <div class="col-12">
        <div>
          <b-table
            id="system-control-supplier-table"
            :items="items"
            :fields="fields"
            :per-page="searchObject.paging.pageSize"
            :current-page="searchObject.paging.currentPage"
            small
          ></b-table>

          <b-pagination
            class="mt-5 mb-2"
            id="system-control-supplier-pagination"
            v-model="searchObject.paging.currentPage"
            :total-rows="totalCount"
            :per-page="searchObject.paging.pageSize"
            aria-controls="system-control-table"
            size="sm"
            limit="10"
          ></b-pagination>
        </div>
      </div>
    </div>

    <div class="row mt-auto">
      <div class="col-12">
        <p class="small-text">
          Summary of the number of system checks for which an action is open for
          the supplier
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import types from '@/common/store/types'

import SupplierService from '../../../services/SupplierService'

export default {
  name: 'SystemControl',

  data () {
    return {
      isLoading: false,
      searchObject: {
        sorting: {
          sortBy: '',
          sortDesc: ''
        },
        filters: {
          article_code: '',
          article_description: ''
        },
        paging: {
          currentPage: 1,
          pageSize: 3
        }
      },
      totalCount: 0,

      inScope: false,
      items: [],
      fields: [
        {
          key: 'error_code',
          label: 'Code',
          thStyle: { width: '45%' }
        },
        {
          key: 'error_count',
          label: 'Number',
          thStyle: { width: '10%' }
        },
        {
          key: 'error_description',
          label: 'Description',
          thStyle: { width: '45%' }
        }
      ]
    }
  },

  computed: {
    user () {
      var response = JSON.parse(
        this.$store.getters[types.getters.loggedInUser]
      )

      return response?.data?.payload
    }
  },

  mounted () {
    this.fetchData()
  },

  methods: {
    fetchData () {
      this.isLoading = true

      SupplierService.getSystemInformation(this.user.gln, this.inScope)
        .then(response => {
          if (response.error && response.error.response.status === 401) {
            this.$router.push({ name: 'auth.login' })
          } else {
            this.data = response.data.payload
          }
          this.items = response.data.payload.map(item => {
            return {
              error_code: item.validationId,
              error_description: item.descripion,
              error_count: item.count
            }
          })

          this.totalCount = this.items.length
          this.searchObject.paging.currentPage = 1
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
        .finally(() => (this.isLoading = false))
    },

    onCheck () {
      this.fetchData()
    }
  }
}
</script>

<style>
#system-control-supplier-table th {
  color: var(--gs1-color-blue);
}

#system-control-supplier-table td {
  font-size: 9px;
}

#system-control-supplier-pagination.pagination {
  justify-content: center;
}

#system-control-supplier-pagination .page-item.active .page-link {
  background-color: black;
  border-color: black;
}

.small-text {
  font-size: 12px;
}

.fourteen-text {
  font-size: 14px;
}

.loader {
  padding: 125px;
  text-align: center;
}
</style>
