class MessageBag {
  /**
   * Create a new Message Bag instance.
   */
  constructor () {
    this.messages = {}
  }

  /**
   * Return if there are any messages.
   */
  has (key) {
    return Object.prototype.hasOwnProperty.call(this.messages, key)
  }

  /**
   * Return if there are any messages.
   */
  hasAny () {
    return Object.keys(this.messages).filter(key => {
      return !Object.prototype.hasOwnProperty.call(this.messages, key) || this.messages[key].length
    }).length
  }

  /**
   * Get a string of all messages.
   */
  getToString (key) {
    if (this.has(key) && this.messages[key].length) {
      return this.messages[key].join('. ') + '.'
    }

    return null
  }

  /**
   * Get a string of all messages.
   */
  allToString () {
    return this.messages.join('. ') + '.'
  }

  // /**
  //  * Get a string of all messages.
  //  */
  // toHtml () {
  //   let html = '<ul>'
  //   this.messages.forEach(message => {
  //     html += '<li>' + message + '.</li>'
  //   })
  //   return html + '</ul>'
  // }

  /**
   * Get all messages.
   */
  get (key) {
    return this.has(key) ? this.messages[key] : false
  }

  /**
   * Get all messages.
   */
  all () {
    return this.messages
  }

  /**
   * Add a message
   * @param {string} string
   */
  add (key, value) {
    this.messages = Object.assign({}, this.messages, { [key]: value })
  }

  /**
   * Delete a message
   * @param {string} string
   */
  delete (key) {
    delete this.messages[key]
  }

  /**
   * Set messages
   * @param {array} array
   */
  set (array) {
    this.messages = array
  }
}

export default MessageBag
