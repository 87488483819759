<template>
  <div class="container desktop">
    <nav class="subnavigation-inner">
      <ul class="list-unstyled">
        <li
          class="position-relative"
          v-for="(item, index) in this.getAllSubnavItems"
          :key="`DESKTOP_SUBNAV_${index}`"
        >
          <MDropdown
            v-if="item.children && item.children.length > 0"
            :label="$t(item.i18nKey ? item.i18nKey : item.label)"
            hideChevron
            hasMouseOver
            removeHeaderStyling
          >
            <template v-slot:head>
              <router-link
                :to="item.route"
                class="subnavigation-parent"
                :class="{ 'is-active': isParentActive(item) }"
              >
                {{ $t(item.i18nKey ? item.i18nKey : item.label) }}
                <span class="sr-only"
                  >({{ $t(item.i18nKey ? item.i18nKey : item.label) }})</span
                >
              </router-link>
            </template>
            <MDropdownItem
              v-for="(child, childIndex) in item.children"
              :key="`MOBILE_SUBNAV_${index}_${childIndex}`"
              :linkMenuItemToChild="true"
            >
              <router-link
                :to="child.route"
                class="subnavigation-child"
                :class="{ 'border-0': index === 0 }"
              >
                <Icon :name="child.icon" v-if="child.icon" class="mr-3" />
                <span>{{
                  $t(child.i18nKey ? child.i18nKey : child.label)
                }}</span>
                <span class="sr-only"
                  >({{ $t(child.i18nKey ? child.i18nKey : child.label) }})</span
                >
              </router-link>
            </MDropdownItem>
          </MDropdown>

          <router-link :to="item.route" class="subnavigation-parent" v-else>
            <Icon :name="item.icon" v-if="item.icon" />
            {{ $t(item.i18nKey ? item.i18nKey : item.label) }}
            <span class="sr-only"
              >({{ $t(item.i18nKey ? item.i18nKey : item.label) }})</span
            >
          </router-link>
        </li>
      </ul>
      <ul class="list-unstyled">
        <li>
          <a
            href="https://gs1.nl/support/"
            target="_blank"
            class="subnavigation-child gs1-color-orange"
            >Support</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import i18n from '@/i18n'

import Icon from '@/common/components/Icon/Icon'

import {
  MDropdown,
  MDropdownItem
} from '../../../components/Molecules/MDropdown'

export default {
  components: {
    Icon,
    MDropdown,
    MDropdownItem
  },

  data: () => {
    return {
      showSubNav: true,
      showDataQuality: false,
      supplierOptions: []
    }
  },
  methods: {
    ...mapActions('subnavigation', ['setSubNavActive', 'setSubnavItems']),

    isParentActive (item) {
      const isCurrentRoute = item.route.name === this.$route.name
      let isChildActive = false

      if (item.children) {
        isChildActive = item.children.some(
          (child) => child.route.name === this.$route.name
        )
      }

      return isCurrentRoute || isChildActive
    },

    handleRouteChange (route) {
      this.setSubNavActive(false)
    }
  },
  watch: {},
  mounted () {},
  created () {},
  computed: {
    ...mapGetters('subnavigation', ['isSubNavActive', 'getAllSubnavItems']),

    selectableLocales () {
      return Object.keys(i18n.messages).filter(
        (locale) => locale !== this.$i18n.locale
      )
    },

    isDataquality () {
      return this.$route.name.toLowerCase().includes('dataquality')
    }
  }
}
</script>

<style src="./SubNavigation.sass" lang="sass" scoped />
