import axios from '@/common/utils/axios'
import types from '@/common/store/types'
import store from '@/store'
import i18n, { STORAGE_KEY_LOCALE } from '@/i18n'

/**
 * @class AccountService
 */
const ContactService = {

  getAndStoreContact: async () => {
    const url = '/accounts/Accounts/contacts'
    try {
      axios.get(url).then(response => {
        var language = response.data.payload.preferredLanguage
        if (language) {
          language = language.toLowerCase()
          localStorage.setItem(STORAGE_KEY_LOCALE, language.slice(0, 2))
          i18n.locale = language.slice(0, 2)
        }
        store.commit(
          types.mutations.storeContact,
          response.data.payload
        )
      })
    } catch (error) {
      return { error }
    }
  }
}

export default ContactService
