/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left-short': {
    width: 16,
    height: 16,
    viewBox: '0 0 6 5',
    data: '<path pid="0" d="M6 2.5c0 .2-.2.4-.4.4H1.3l1.6 1.5c.1.1.1.4 0 .5-.2.1-.4.1-.5 0L.1 2.8c-.1-.2-.1-.4 0-.6L2.3.1c.2-.1.4-.1.6 0 .1.1.1.4 0 .5L1.3 2.1h4.3c.2 0 .4.2.4.4z"/>'
  }
})
