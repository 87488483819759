import types from './types'

/** Deprecated, use /store/modules instead! */
export default {
  [types.mutations.addNotification]: (state, [message, type, timeout]) => {
    state.notifications.push({
      id: Math.random()
        .toString(36)
        .substring(2),
      markedForDeletion: false,
      message: message,
      type: type,
      timeout: timeout
    })
  },

  [types.mutations.updateNotification]: (state, [id, payload]) => {
    const index = state.notifications.findIndex(item => item.id === id)
    if (index > -1) {
      Object.keys(payload).forEach(key => {
        state.notifications[index][key] = payload[key]
      })
    }
  },

  [types.mutations.removeNotification]: (state, id) => {
    const index = state.notifications.findIndex(item => item.id === id)
    if (index > -1) {
      state.notifications.splice(index, 1)
    }
  },

  [types.mutations.updateHas403error]: (state, has403error) => {
    state.has403error = has403error
  },

  // Start the loader
  [types.mutations.loaderStart]: (state, payload) => {
    let loader = state.loaders.find(loader => loader.id === payload.id)
    const timeout = Object.prototype.hasOwnProperty.call(payload, 'timeout')
      ? payload.timeout
      : 500

    if (!loader) {
      const loaderCount = state.loaders.push({
        id: payload.id,
        isActive: true,
        isLoading: false,
        isCompleted: false,
        message: payload.message,
        loaderTimeout: null
      })
      loader = state.loaders[loaderCount - 1]
    } else {
      loader.isActive = true
      loader.isLoading = false
      loader.isCompleted = false
      loader.message = payload.message
    }

    loader.loaderTimeout = setTimeout(() => {
      loader.isLoading = true
    }, timeout)
  },

  // Stop the loader
  [types.mutations.loaderStop]: (state, payload) => {
    const loader = state.loaders.find(loader => loader.id === payload.id)

    if (loader) {
      if (loader.loaderTimeout) {
        clearTimeout(loader.loaderTimeout)
      }
      loader.isActive = false
      loader.isLoading = false
      loader.isCompleted = true
      loader.message = null
      loader.loaderTimeout = null
    }
  },

  // Store the supplier scope
  [types.mutations.storeSupplierScope]: (state, payload) => {
    payload === null
      ? localStorage.removeItem('supplierScope')
      : localStorage.setItem('supplierScope', payload)

    state.supplierScope = payload
  },

  // Store the account scope
  [types.mutations.storeAccountScope]: (state, payload) => {
    state.accountScope = payload
    localStorage.setItem('accountScope', JSON.stringify(state.accountScope))
  },

  [types.mutations.storeAccountScopeAccountName]: (state, payload) => {
    state.accountScope.account.name = payload
    localStorage.setItem('accountScope', JSON.stringify(state.accountScope))
  },

  // Store contact (loggedin user)
  [types.mutations.storeContact]: (state, payload) => {
    state.contact = payload
    localStorage.setItem('contact', JSON.stringify(state.contact))
  },

  // Store the GLN data
  [types.mutations.storeSupplierData]: (state, payload) => {
    state.supplier.GLNs = payload.GLNs
    state.supplier.accountName = payload.accountName
    state.supplier.signature = payload.signature

    localStorage.setItem('supplier', JSON.stringify(state.supplier))
  },

  [types.mutations.hideAlert]: (state, payload) => {
    state.hiddenAlerts.push(payload)
    localStorage.setItem('hiddenAlerts', JSON.stringify(state.hiddenAlerts))
  },

  [types.mutations.storeScaleCategoryId]: (state, payload) => {
    state.scaleCategoryId = payload

    localStorage.setItem('scaleCategoryId', state.scaleCategoryId)
  },

  [types.mutations.setSelectedSupplier]: (state, payload) => {
    state.selectedSupplier = payload

    localStorage.setItem('selectedSupplier', state.selectedSupplier)
  },

  [types.mutations.setSelectedSupplierName]: (state, payload) => {
    state.selectedSupplierName = payload

    localStorage.setItem('selectedSupplierName', state.selectedSupplierName)
  },

  [types.mutations.setLoggedInUser]: (state, payload) => {
    state.loggedInUser = payload

    localStorage.setItem('loggedInUser', state.loggedInUser)
  },

  [types.mutations.removeSelectedSupplier]: state => {
    state.selectedSupplier = null

    localStorage.removeItem('selectedSupplier')
  },

  [types.mutations.removeSelectedSupplierName]: state => {
    state.selectedSupplierName = null

    localStorage.removeItem('selectedSupplierName')
  }
}
