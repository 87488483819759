export default [
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import('@/common/views/Styleguide'),
    meta: {
      public: true
    }
  }

]
