<template>
  <div>
    <div class="container py-4">
      <!-- VIEW: FORM -->
      <div class="row">
        <!-- Main -->
        <div class="col-md-7 col-lg-7" v-if="!isLoading">
          <AHeading class="mb-4" size="h1">{{ $t("notFound.title") }}</AHeading>
          <div class="gs1-color-blue">
            <p>
              {{ $t("notFound.description") }}
              <a @click="$router.go(-1)">{{ $t("notFound.goBack") }}</a>
            </p>
          </div>
        </div>

        <!-- A Side -->
        <div class="col-md-4 col-lg-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AHeading from '@/components/Atoms/AHeading'

export default {
  components: {
    AHeading
  },
  data () {
    return {}
  },
  watch: {},
  created () {},
  beforeDestroy () {},
  mounted () {},
  computed: {},
  methods: {}
}
</script>
