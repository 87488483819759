<template>
  <td class="table-data-actions">
    <a
      href=""
      @click.prevent="action.action(data)"
      v-for="(action, actionIndex) in column.actions"
      :key="actionIndex"
      class="table-data-action"
    >
      {{ action.label }}
      <Icon v-if="iconExists(action)" :name="action.icon" />
    </a>
  </td>
</template>

<script>
import Icon from '@/common/components/Icon/Icon'

export default {
  props: {
    column: {
      default: null
    },
    data: {
      default: null
    },
    field: {
      required: true
    }
  },
  components: {
    Icon
  },
  methods: {
    iconExists (action) {
      return this.hasProperty(action, 'icon') && action.icon
    }
  }
}
</script>
