import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import commonStore from '@/common/store'
import modules from '@/common/utils/modules'
import deviantModules from './modules'

Vue.use(Vuex)

// Create the VueX store, with common store as default.
const store = createStore(Vuex.Store, {
  state: commonStore.state,
  getters: commonStore.getters,
  mutations: commonStore.mutations,
  actions: commonStore.actions,
  modules: deviantModules
})

// If a module has a store, register it in VueX as a module.
modules.stores().forEach(moduleStore => {
  if (!store.hasModule(moduleStore.module)) {
    store.registerModule(
      moduleStore.module,
      require('@/' + moduleStore.path).default
    )
  }
})

export default store
