<template>
  <div
    class="pageHeader d-flex align-items-center gs1-background-color-ui-1 py-3"
  >
    <div class="container">
      <div class="d-lg-flex">
        <div class="flex-grow-1">
          <h4 class="m-0 mb-2" v-if="subtitletop">{{ subtitletop }}</h4>
          <h1 class="m-0">{{ title }}</h1>
        </div>
        <div class="mt-4 mt-lg-0" v-if="hasSectionRightSlot">
          <slot name="section-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitletop: {
      type: String
    }
  },

  computed: {
    hasSectionRightSlot () {
      return !!this.$slots['section-right']
    }
  }
}
</script>

<style lang="scss" scoped>
.pageHeader {
  min-height: 200px;
}
</style>
