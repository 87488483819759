<template>
  <div>
    <!-- Animate the notifications -->
    <transition-group
      name="slide-right"
      tag="div"
      class="notifications"
    >
      <!-- Loop through the notifications -->
      <div
        v-for="item in notifications"
        v-bind:key="item.id"
        class="notification-item"
      >
        <!-- The notification -->
        <div :class="['notification', 'notification-type-' + item.type]">
          <!-- Message -->
          <div class="notification-message">
            {{ item.message }}
          </div>

          <!-- Controls -->
          <div class="notification-controls">
            <a
              href=""
              class="notification-controls-confirm"
              @click.prevent="removeNotification(item.id)"
            >
              {{ $t("common.notifications.close") }}
            </a>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import types from '@/common/store/types'

export default {
  computed: {
    notifications () {
      return this.$store.getters[types.getters.notifications]
    }
  },

  mounted () {
    this.updateNotifications()
  },

  methods: {
    /**
     * Remove a notification from the store.
     */
    removeNotification (id) {
      this.$store.commit(types.mutations.removeNotification, id)
    },

    /**
     * Loop through all notifications and set removal timeouts.
     */
    updateNotifications () {
      // if (this.notifications.length && this.$b2c.isLoggedIn()) {
      if (this.notifications.length) {
        this.notifications.forEach((item) => {
          if (!item.markedForDeletion) {
            /*
             * Mark the notification for deletion, so it won't be removed
             * multiple times.
             */
            this.$store.commit(types.mutations.updateNotification, [
              item.id,
              { markedForDeletion: true }
            ])

            /*
             * Remove the notification after a given time.
             */
            setTimeout(() => {
              this.removeNotification(item.id)
            }, item.timeout)
          }
        })
      }
    }
  },

  watch: {
    notifications: function (to, from) {
      this.updateNotifications()
    }
  }
}
</script>
