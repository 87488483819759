/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'UM': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: `<g id="Weekdieren" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <circle id="Oval-Copy-3" stroke="#A29488" cx="16" cy="16" r="15"></circle>
              <path d="M24.1268939,21.3158409 C22.9822443,20.477659 21.9914773,20.0526316 21.1818182,20.0526316 L18.6292318,20.0526316 C20.6300308,19.1561793 22.030303,17.1271951 22.030303,14.7719298 C22.030303,11.5892612 19.4746686,9 16.3333333,9 C13.1919981,9 10.6363636,11.5892612 10.6363636,14.7719298 C10.6363636,17.1271951 12.0366359,19.1561793 14.0374349,20.0526316 L11.6474905,20.0526316 C11.4489672,19.6033036 10.7897875,18.3987994 9.38998875,18.2314089 L9.89654356,16.6917489 C9.93892046,16.5630654 9.87026516,16.4239481 9.74337122,16.3810136 C9.61505682,16.3379592 9.47916667,16.4077577 9.43678979,16.5363213 L8.88285692,18.2199857 C8.79890213,18.2254874 8.7184689,18.234662 8.64135006,18.2475093 L8.07836177,16.5363213 C8.03622162,16.4077577 7.89962124,16.3379591 7.77178034,16.3810136 C7.6448864,16.423948 7.5762311,16.5630654 7.618608,16.6917489 L8.17442002,18.3811249 C7.26899857,18.7683148 7,19.7313425 7,20.3013638 C7.00023675,20.3283477 7.06344698,23 9.66666667,23 L25.4242424,23 C25.5582386,23 25.6666667,22.8900254 25.6666667,22.754386 C25.6666667,22.4713542 24.1422822,21.3271142 24.1268939,21.3158409 Z M12.3333333,16 C12.3333333,13.765368 14.1278409,11.9473684 16.3333333,11.9473684 C18.5388257,11.9473684 20.3333333,13.765368 20.3333333,16 C20.3333333,17.090362 19.9040157,18.0795642 19.2102569,18.8087308 C19.4632753,18.3382654 19.6072443,17.7993057 19.6072443,17.2268709 C19.6072443,15.3978379 18.1384943,13.9097622 16.3333333,13.9097622 C14.5281724,13.9097622 13.0594224,15.3978379 13.0594224,17.2268709 C13.0594224,17.7993057 13.2033913,18.3382654 13.4564098,18.8087308 C12.7626509,18.0795642 12.3333333,17.090362 12.3333333,16 Z" id="Shape" fill="#A49489" fill-rule="nonzero"></path>
          </g>`
  }
})
