/**
 * Vue helpers that are globally accessible within the Vue application.
 */
export default {
  install (Vue, options) {
    /**
     * Check if the given property exists within the given object.
     *
     * @param object object
     * @param string parameter
     *
     * @return boolean
     */
    Vue.prototype.hasProperty = (object, parameter) => {
      /**
       * Make sure the first parameter is an object
       */
      if (object === null || typeof object !== 'object') {
        throw new Error('First parameter should be of type object.')
      }

      /**
       * Make sure the second parameter is an object
       */
      if (typeof parameter !== 'string') {
        throw new Error('Second parameter should be of type string.')
      }

      return Object.prototype.hasOwnProperty.call(object, parameter)
    }
  }
}
