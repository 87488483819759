import App from './App.vue'
import router from './router'
import store from './store'

import Vue from 'vue'
import VueAppInsights from 'vue-application-insights'
import VueGlobalHelpers from './common/utils/vue-global-helpers'
import VueScrollTo from 'vue-scrollto'
import PortalVue from 'portal-vue'
import i18n from './i18n'
import './common/utils/filters'

import VueB2c from './common/utils/vue-b2c'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import kvkButton from './components/Organisms/OFormGenerator/CustomFields/FieldKvkbutton'

/**
 * Turn of that darn console notice
 */
Vue.config.productionTip = false

Vue.component('fieldKvkbutton', kvkButton)

/**
 * Vue plugins
 */
Vue.use(VueB2c)
Vue.use(VueGlobalHelpers)
Vue.use(VueScrollTo)
Vue.use(PortalVue)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

if (process.env.VUE_APP_AZURE_INSIGHT_KEY) {
  Vue.use(VueAppInsights, {
    id: process.env.VUE_APP_AZURE_INSIGHT_KEY,
    router
  })
}

/**
 * Start the application
 */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
