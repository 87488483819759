<template>
  <div>
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-8">
          <MContentGroup isFullwidth isBordered v-if="isLoading">
            <template #body-content>
              <div class="text-center">
                <div class="spinner-border gs1-color-orange" role="status">
                  <span class="sr-only">laden...</span>
                </div>
              </div>
            </template>
          </MContentGroup>
          <AHeading class="mb-4" size="h2">{{
            $t("auth.sessionExpired.title")
          }}</AHeading>
          <MContentGroup isFullwidth hasShadow isBordered bgColor="ui-1">
            <template #body-content>
              <div class="container">
                <div class="row py-2">
                  <div class="gs1-color-orange">
                    <AIcon icon="user" size="md" />
                  </div>
                  <div class="col-sm">
                    <div class="row">
                      <div class="col-12">
                        <div class="font-weight-bold gs1-color-orange">
                          {{ $t("auth.sessionExpired.textRowTop") }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="gs1-color-orange">
                          {{ $t("auth.sessionExpired.textRowBottom") }}
                        </div>
                      </div>
                    </div>
                    <hr class="bg-ui-2" />
                    <div class="row py-2">
                      <div class="col-sm">
                        <AButton @click="login()">
                          {{ $t("auth.sessionExpired.textButton") }}
                        </AButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </MContentGroup>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import AButton from '@/components/Atoms/AButton'
import AIcon from '@/components/Atoms/AIcon'
import AHeading from '@/components/Atoms/AHeading'
import MContentGroup from '@/components/Molecules/MContentGroup'

export default {
  components: {
    AIcon,
    AButton,
    AHeading,
    MContentGroup
  },
  data () {
    return {
      isLoading: false,
      errorMsg: null
    }
  },

  computed: {},
  methods: {
    login () {
      this.$router.push({ name: 'auth.login' })
    }
  },
  mounted () {}
}
</script>
